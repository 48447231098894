var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',[_c('z-input',{attrs:{"error-messages":_vm.errors.tipo,"label":"Tipo","tipo":"select","items":_vm.payloadItemsTiposInstitucion},model:{value:(_vm.form.tipo),callback:function ($$v) {_vm.$set(_vm.form, "tipo", $$v)},expression:"form.tipo"}})],1),(_vm.form.tipo > 2)?_c('v-col',{attrs:{"cols":"6"}},[_c('z-input',{attrs:{"error-messages":_vm.errors.municipalidad_id,"label":"Comuna","tipo":"autocomplete","items":_vm.payloadItemsMunicipalidades},model:{value:(_vm.form.municipalidad_id),callback:function ($$v) {_vm.$set(_vm.form, "municipalidad_id", $$v)},expression:"form.municipalidad_id"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('z-input',{attrs:{"error-messages":_vm.errors.nombre,"label":"Nombre","minlength":"3"},model:{value:(_vm.form.nombre),callback:function ($$v) {_vm.$set(_vm.form, "nombre", $$v)},expression:"form.nombre"}})],1),(_vm.form.tipo === 3)?_c('v-col',{attrs:{"cols":"3"}},[_c('z-input',{attrs:{"error-messages":_vm.errors.numero_jpl,"tipo":"select","items":[
          { text: '1º', value: 1 },
          { text: '2º', value: 2 },
          { text: '3º', value: 3 },
          { text: '4º', value: 4 },
          { text: '5º', value: 5 },
        ],"label":"Número JPL"},model:{value:(_vm.form.numero_jpl),callback:function ($$v) {_vm.$set(_vm.form, "numero_jpl", $$v)},expression:"form.numero_jpl"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('z-input',{attrs:{"error-messages":_vm.errors.id,"label":"Rut institución o id JPL (código territorial + nro. JPL)","required":""},model:{value:(_vm.form.id),callback:function ($$v) {_vm.$set(_vm.form, "id", $$v)},expression:"form.id"}})],1)],1),_c('v-row',[_c('v-col',[_c('z-input',{attrs:{"error-messages":_vm.errors.direccion,"label":"Dirección"},model:{value:(_vm.form.direccion),callback:function ($$v) {_vm.$set(_vm.form, "direccion", $$v)},expression:"form.direccion"}})],1)],1),_c('v-row',[_c('v-col',[_c('z-input',{attrs:{"error-messages":_vm.errors.codigo_subdere,"label":"Código Subdere"},model:{value:(_vm.form.codigo_subdere),callback:function ($$v) {_vm.$set(_vm.form, "codigo_subdere", $$v)},expression:"form.codigo_subdere"}})],1),_c('v-col',[_c('z-input',{attrs:{"error-messages":_vm.errors.codigo_esign,"label":"Código E-sign"},model:{value:(_vm.form.codigo_esign),callback:function ($$v) {_vm.$set(_vm.form, "codigo_esign", $$v)},expression:"form.codigo_esign"}})],1),_c('v-col',[_c('z-input',{attrs:{"error-messages":_vm.errors.codigo_tgr,"label":"Código TGR"},model:{value:(_vm.form.codigo_tgr),callback:function ($$v) {_vm.$set(_vm.form, "codigo_tgr", $$v)},expression:"form.codigo_tgr"}})],1)],1),_c('v-row',[_c('v-col',[_c('z-input',{attrs:{"error-messages":_vm.errors.email,"label":"Email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)],1),_c('v-row',[(_vm.form.tipo === 1)?_c('v-col',{attrs:{"cols":"6"}},[_c('z-input',{attrs:{"error-messages":_vm.errors.telefono,"label":"Teléfono"},model:{value:(_vm.form.telefono),callback:function ($$v) {_vm.$set(_vm.form, "telefono", $$v)},expression:"form.telefono"}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }