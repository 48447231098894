<template>
  <component
    :is="is"
    v-model="local"
    v-bind="$attrs"
    :auto-select-first="autoSelectFirst"
    :dense="dense"
    :items="items"
    :false-value="false"
    :maxlength="maxlengthComputed"
    :minlength="minlength"
    :no-data-text="noDataText"
    :outlined="outlined"
    :rules="rulesComputed"
    :min="min"
    :max="max"
    :true-value="true"
    v-on="$listeners"
    @input="clearError"
    @keydown="clearError"
  />
</template>

<script>
import ZInputRut from '@/components/Input/ZInputRut'
import ZInputFecha from '@/components/Input/ZInputFecha'
import ZInputMes from '@/components/Input/ZInputMes'
import { VAutocomplete, VCheckbox, VSelect, VTextarea, VTextField } from 'vuetify/lib/components'

/**
 * Componnete genérico para campos de formulario.
 * En general es un proxy a los componentes proporcionados por Vuetify,
 * por lo que se recomienda leer la documentación de estos,
 * especialmente la del componente [`v-text-field`](https://vuetifyjs.com/en/components/text-fields).
 *
 * Recibe un prop `tipo` en base al cual determina el componente a generar.
 *
 *
 */
export default {
  components: {
    VAutocomplete,
    VCheckbox,
    VSelect,
    VTextarea,
    VTextField,
    ZInputFecha,
    ZInputMes,
    ZInputRut,
  },
  props: {
    /**
     * Define el componente a utilizar.
     *
     * @values text, autocomplete, checkbox, fecha, rut, etc.
     */
    tipo: {
      type: String,
      default: undefined,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    required: [Boolean],
    maxlength: [String, Number],
    minlength: [String, Number],
    min: [String, Number],
    max: [String, Number],
    noDataText: {
      type: String,
      default: 'No hay datos disponibles',
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    /**
     * Selecciona el primer elemento de autocomplete. true por defecto.
     */
    autoSelectFirst: {
      type: Boolean,
      default: true,
    },
    rules: Array,
    value: {
      type: [String, Number, Array, Boolean],
      default: null,
    },
  },
  data() {
    return {
      definicion: [],
      definicionPadre: [],
    }
  },
  computed: {
    local: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },
    is: function () {
      const componentes = {
        rut: 'z-input-rut',
        fecha: 'z-input-fecha',
        mes: 'z-input-mes',
        'fecha-hora': 'z-input-fecha-hora',
        textarea: 'v-textarea',
        checkbox: 'v-checkbox',
        text: 'v-text-field',
        autocomplete: 'v-autocomplete',
        select: 'v-select',
      }
      if (this.tipo) {
        return componentes[this.tipo]
      }
      if (this.items.length > 0) {
        return 'v-select'
      }
      return 'v-text-field'
    },
    maxlengthComputed() {
      if (this.maxlength === '' || this.maxlength === 0 || this.maxlength === '0') {
        return ''
      }
      return this.maxlength || 100
    },
    rulesComputed() {
      let rules = []
      if (this.required) {
        rules.push((v) => !!v || 'Campo requerido.')
      }
      if (this.tipo === 'rut') {
        return rules
      }
      if (this.minlength) {
        rules.push(
          (v) =>
            (!!v && v.length >= this.minlength) ||
            `Debes ingresar al menos ${this.minlength} caracteres.`
        )
      }
      // if (this.maxlength) {
      //   rules.push(v => (v.length <= this.maxlength) || `Debes ingresar un máximo de ${this.maxlength} caracteres.`)
      // }
      if (this.rules) {
        rules = rules.concat(this.rules)
      }
      return rules
    },
  },
  methods: {
    clearError() {
      // this.field
    },
  },
}
</script>
