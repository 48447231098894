import Vue from 'vue'
import tramites from '@/datos/tramites.js'
/*
import { mdiBank, mdiCarSide, mdiBusSide, mdiTruck, mdiHandBackRight } from '@mdi/js'
 */

class Tramite {
  constructor(tramite) {
    this.tramite = tramite
  }

  tramite() {
    return this.tramite
  }

  /**
   * ID del trámite
   * @returns {number}
   */
  get id() {
    return this.tramite.id
  }

  get habilitado() {
    return this.tramite.habilitado
  }

  /**
   * Nombre del trámite en plural, por ejemplo: "Permisos de circulación"
   * @returns {string}
   */
  get nombre() {
    return this.tramite.nombre
  }

  /**
   * Nombre del trámite corto
   * @returns {string}
   */
  get nombreCorto() {
    return this.tramite.nombreCorto || this.tramite.nombre
  }

  get icono() {
    return this.tramite.icono
  }

  /**
   * Texto de resumen del trámite para mostrarlo en la homepage. No debiera ser superior a 15 palabras.
   * @returns {string}
   */
  get resumen() {
    return this.tramite.resumen
  }

  /**
   * Nombre del slug (coon el que se construyen las url), por ejemplo: "permisos-de-circulacion"
   * @returns {string}
   */
  get slug() {
    return this.tramite.slug
  }

  /**
   * Nombre de la cuenta en singular, por ejemplo: "Patente"
   * @returns {string}
   */
  get nombreSingular() {
    return this.tramite.nombreSingular
  }

  /**
   * Nombre de la cuenta en plural, por ejemplo: "Patentes"
   * @returns {string}
   */
  get nombrePlural() {
    return this.tramite.nombrePlural
  }

  /**
   * Indica si el trámite permite el pago en cuotas
   * @returns {boolean}
   */
  get pagoEnCuotas() {
    return !!this.tramite.pagoEnCuotas
  }

  /**
   * Indica si el proceso de pago se realiza en pasos (true) o todo en una única página
   * @returns {boolean}
   */
  get pagoEnPasos() {
    return !!this.tramite.pagoEnPasos
  }

  /**
   * Texto que se antepondrá al botón para continuar al siguiente paso
   * @returns {String}
   */
  get pagoEnPasosTexto() {
    return this.tramite.pagoEnPasosTexto || ''
  }

  /**
   * Texto adicional en aso que no se encuentren resultados de búsqueda
   * @returns {String}
   */
   textoSinResultados(vue) {
    try {
      return this.tramite.textoSinResultados ? this.tramite.textoSinResultados(vue) : ''
    } catch (error) {
      console.warn(error)
      return ''
    }
  }

  /**
   * Texto de ayuda a usar como label en el campo de búsqueda, por ejemplo: "RUT o placa patente"
   * @returns {string}
   */
  get label() {
    return this.tramite.label
  }

  /**
   *
   * @returns {string}
   */
  get labelComprobantePago() {
    return this.tramite.labelComprobantePago
  }

  get requiereInstitucion() {
    return this.tramite.requiereInstitucion
  }

  get requiereJpl() {
    return this.tramite.requiereJpl
  }

  /**
   * Indica si el nombre es masculino o femenino. Por ejemplo para "Patente" es {true}
   * @returns {boolean}
   */
  get nombreEsMasculino() {
    return !!this.tramite.nombreEsMasculino
  }

  txtGenero(masculino, femenino) {
    return this.tramite.nombreEsMasculino ? masculino : femenino
  }

  txtCantidad(cantidad) {
    let txtNombre = ''

    if (cantidad === 1) {
      cantidad = this.tramite.nombreEsMasculino ? 'Un' : 'Una'
      txtNombre = this.tramite.nombreSingular.toLowerCase()
    } else {
      txtNombre = this.tramite.nombrePlural.toLowerCase()
    }

    return `${cantidad} ${txtNombre}`
  }

  /**
   * Indica si el trámite se muestra en la homepage
   * @returns {boolean}
   */
  get visibleEnHomePage() {
    return this.tramite.visibleEnHomePage === true
  }

  /**
   * Indica si se muestra el formulario de búsqueda
   * @returns {boolean}
   */
  get formularioDeBusqueda() {
    return this.tramite.formularioDeBusqueda !== false
  }

  /**
   * Tipos de institución compatibles con el trámite
   * - 2: Municipalidades
   * - 3: JPL
   */
  get tiposInstitucion() {
    return this.tramite.tiposInstitucion
  }

  /**
   * Genera un párrafo a partir del string, aplicando los siguientes reemplazos dependiendo
   * del género, cantidad, etc.
   *
   * - {el} el, la, los, las  (género, cantidad)
   * - {un} un, una (género)
   * - {nombre} patente, patentes (género)
   * - {cantidad} un, una, (valor numérico para >1)  (género, cantidad)
   * - {o} Se reemplazará por 'o' o 'a' de acuerdo al género (género)
   * - {s} Se reemplazará por '' o 's' de acuerdo a la cantidad (cantidad)
   * - {n} Se reemplazará por '' o 'n' de acuerdo a la cantidad (cantidad)
   * - {n:texto-singular:texto-plural} Se reemplazará por 'texto-singular' o 'texto-plural'
   * - {g:texto-masculino:texto-femenino} Se reemplazará por 'texto-masculino' o 'texto-femenino'
   *
   * @param parrafo
   * @param cantidad
   * @returns {*|string}
   */
  parrafo(parrafo, cantidad = 1) {
    const matches = [...parrafo.toString().matchAll(/{[^}]+}/g)]

    for (const i in matches) {
      const match = matches[i][0]
      let reemplazo = ''
      if (match === '{el}') {
        // "el", "la", "los", "las"
        reemplazo =
          cantidad === 1
            ? this.nombreEsMasculino
              ? 'el'
              : 'la'
            : this.nombreEsMasculino
            ? 'los'
            : 'las'
      } else if (match === '{un}') {
        // "un", "una"
        reemplazo = this.nombreEsMasculino ? 'un' : 'una'
      } else if (match === '{nombre}') {
        reemplazo = (cantidad === 1 ? this.nombreSingular : this.nombrePlural).toLowerCase()
      } else if (match === '{cantidad}') {
        reemplazo = cantidad === 1 ? (this.nombreEsMasculino ? 'un' : 'una') : cantidad
      } else if (match === '{o}') {
        reemplazo = this.nombreEsMasculino ? 'o' : 'a'
      } else if (match === '{n}') {
        // "vence" o "venceN"
        reemplazo = cantidad === 1 ? '' : 'n'
      } else if (match === '{s}') {
        // "pago" o "pagoS"
        reemplazo = cantidad === 1 ? '' : 's'
      } else if (match.match(/^{n:(.*):(.*)}$/)) {
        // "n:texto-singular:texto-plural"
        const partes = match.match(/^{n:(.*):(.*)}$/)
        reemplazo = cantidad === 1 ? partes[1] : partes[2]
      } else if (match.match(/^{g:(.*):(.*)}$/)) {
        // "g:masculino:femenino"
        const partes = match.match(/^{g:(.*):(.*)}$/)
        reemplazo = this.nombreEsMasculino ? partes[1] : partes[2]
      } else if (match.match(/^{[a-zA-Z]+\$}$/)) {
        // En prototipo, queda por definir el comportamiento final
        reemplazo = cantidad === 1 ? '' : match.replace('$', 's')
      }

      parrafo = parrafo.replaceAll(match, reemplazo)
    }

    return parrafo
  }
}

/**
 * Definiciones comunes para trámites permisos de circulación
 *
 const permisosDeCirculacionBase = {
  resumen: 'Ingresa tu rut o placa patente y sigue las instrucciones de pago',
  icono: mdiCarSide,
  nombreSingular: 'Patente',
  nombrePlural: 'Patentes',
  nombreEsMasculino: false,
  requiereInstitucion: false,
  nombrePk: 'Patente',
  label: 'RUT o placa patente',
  labelComprobantePago: 'El permiso de circulación',
  pagoEnCuotas: true,
  pagoEnPasos: true,
  tiposInstitucion: [2],
  pagoEnPasosTexto: `
            En el siguiente paso, antes de pagar, se consultará en línea el estado actual de
            la Revisión Técnica y de Gases, el Seguro Obligatorio de Accidentes Personales (SOAP)
            y el Registro de Pasajeros Infractores (RPI).
    `,
  visibleEnHomePage: true,
  textoSinResultados(vue) {
    const institucion = vue.$store.getters['app/institucion']
    const municipalidad = institucion?.nombre || 'la municipalidad'
    const telefono = institucion?.telefono ? ` al teléfono ${institucion.telefono}` : ''

    return `
        <p>
        Esto puede ocurrir porque su permiso anterior no fue pagado en ${municipalidad} o
        la municipalidad no incluyó su placa patente en este listado de pago.
        </p>
        <p>
        En caso de dudas, le recomendamos llamar a la municipalidad ${telefono} o acercarse a
        las oficinas de pago para aclarar su situación.
        </p>
     `
  },
}

/**
 * Definiciones comunes para trámites permisos de circulación
 *
const infraccionesBase = {
  microservicio: 'tramitesgenerales',
  resumen: 'Ingresa el rol de la causa y sigue las instrucciones de pago',
  nombreSingular: 'Infracción',
  nombrePlural: 'Infracciones',
  nombreEsMasculino: false,
  requiereInstitucion: true,
  requiereJpl: true, // TODO
  tiposInstitucion: [3],
  nombrePk: 'Causa',
  label: 'Número y año de causa',
  labelComprobantePago: 'La causa',
  pagoEnCuotas: false,
  pagoEnPasos: false,
  visibleEnHomePage: true,
  textoSinResultados: null,
  icono: mdiHandBackRight,
}

let tramites = [
  {
    id: 2,
    habilitado: true,
    slug: 'patentes-municipales',
    microservicio: 'tramitesgenerales',
    nombre: 'Patentes municipales',
    resumen: 'Ingresa tu rut o rol de avalúo y sigue las instrucciones de pago',
    nombreSingular: 'Patente',
    nombrePlural: 'Patentes',
    nombreEsMasculino: false,
    requiereInstitucion: true,
    nombrePk: 'Rol de avalúo',
    label: 'RUT o rol de avalúo',
    labelComprobantePago: 'El comprobante de pago',
    pagoEnCuotas: false,
    pagoEnPasos: false,
    visibleEnHomePage: true,
    tiposInstitucion: [2],
    icono: mdiBank,
  },
  {
    ...permisosDeCirculacionBase,
    id: 3,
    habilitado: true,
    slug: 'permisos-de-circulacion',
    microservicio: 'permisosdecirculacion',
    nombre: 'Permisos de circulación vehículos livianos',
    nombreCorto: 'Vehículos livianos',
    resumen: 'Ingresa tu rut o placa patente y sigue las instrucciones de pago',
  },
  {
    ...permisosDeCirculacionBase,
    id: 4,
    habilitado: true,
    slug: 'permisos-de-circulacion-2c',
    microservicio: 'permisosdecirculacion2c',
    nombre: 'Segunda cuota de permisos de circulación',
    nombreCorto: 'Segunda cuota',
    pagoEnCuotas: false,
    pagoEnPasos: false,
    textoSinResultados: null,
  },
  {
    ...permisosDeCirculacionBase,
    id: 5,
    habilitado: true,
    slug: 'permisos-de-circulacion-pesados',
    microservicio: 'permisosdecirculacion',
    nombre: 'Permisos de circulación vehículos pesados',
    nombreCorto: 'Vehículos pesados',
    icono: mdiTruck,
  },
  {
    ...permisosDeCirculacionBase,
    id: 6,
    habilitado: true,
    slug: 'permisos-de-circulacion-locomocion-colectiva',
    microservicio: 'permisosdecirculacion',
    nombre: 'Permisos de circulación locomoción colectiva',
    nombreCorto: 'Locomoción colectiva',
    resumen: 'Ingresa tu rut o placa patente y sigue las instrucciones de pago',
    icono: mdiBusSide,
  },
  {
    id: 7,
    habilitado: true,
    slug: 'permisos-provisorios',
    microservicio: 'tramitesgenerales',
    nombre: 'Permisos provisorios',
    resumen: 'Ingresa tu RUT o número de puesto y sigue las instrucciones de pago',
    nombreSingular: 'Puesto',
    nombrePlural: 'Puestos',
    nombreEsMasculino: true,
    requiereInstitucion: true,
    nombrePk: 'Puesto',
    label: 'RUT o nro. puesto',
    labelComprobantePago: 'El comprobante de pago',
    pagoEnCuotas: false,
    pagoEnPasos: false,
    visibleEnHomePage: true,
    tiposInstitucion: [2],
    icono: mdiBank,
  },
  {
    id: 8,
    habilitado: true,
    slug: 'derechos-de-aseo',
    microservicio: 'tramitesgenerales',
    nombre: 'Derechos de aseo',
    resumen: 'Ingresa tu rut o rol de avalúo y sigue las instrucciones de pago',
    nombreSingular: 'Derecho de aseo',
    nombrePlural: 'Derechos de aseo',
    nombreEsMasculino: true,
    requiereInstitucion: true,
    nombrePk: 'Rol de avalúo',
    label: 'Rut o rol de avalúo',
    labelComprobantePago: 'El comprobante de pago',
    pagoEnCuotas: false,
    pagoEnPasos: false,
    visibleEnHomePage: true,
    tiposInstitucion: [2],
    icono: mdiBank,
  },
  {
    id: 9,
    habilitado: true,
    slug: 'multas',
    microservicio: 'permisos-de-circulacion',
    nombre: 'Multas',
    resumen: '',
    nombreSingular: 'Multa',
    nombrePlural: 'Multas',
    nombreEsMasculino: false,
    requiereInstitucion: true,
    nombrePk: 'Identificador',
    label: '',
    labelComprobantePago: 'El comprobante de pago',
    pagoEnCuotas: false,
    pagoEnPasos: false,
    visibleEnHomePage: false,
    tiposInstitucion: [2],
    icono: mdiBank,
  },
  {
    id: 10,
    habilitado: true,
    slug: 'pagos-genericos',
    microservicio: 'tramitesgenerales',
    nombre: 'Pagos genéricos',
    resumen: '',
    nombreSingular: 'Pago genérico',
    nombrePlural: 'Pagos genéricos',
    nombreEsMasculino: true,
    requiereInstitucion: true,
    nombrePk: 'Tag',
    label: 'Tag',
    labelComprobantePago: 'El comprobante de pago',
    pagoEnCuotas: false,
    pagoEnPasos: false,
    visibleEnHomePage: false,
    formularioDeBusqueda: false,
    tiposInstitucion: [2],
    icono: mdiBank,
  },
  {
    ...infraccionesBase,
    id: 11,
    habilitado: true,
    slug: 'infracciones-presenciales',
    nombre: 'Infracciones presenciales',
  },
  {
    ...infraccionesBase,
    id: 12,
    habilitado: true,
    slug: 'infracciones-empadronadas',
    nombre: 'Infracciones empadronadas',
  },
  {
    ...infraccionesBase,
    id: 13,
    habilitado: true,
    slug: 'infracciones-transantiago',
    nombre: 'Infracciones Transantiago',
  },

  {
    id: 14,
    habilitado: true,
    slug: 'multas-tag',
    microservicio: 'tramitesgenerales',
    nombre: 'Multas TAG',
    resumen: 'Ingresa tu placa patente o rut y sigue las instrucciones de pago',
    nombreSingular: 'Multa TAG',
    nombrePlural: 'Multas TAG',
    nombreEsMasculino: false,
    requiereInstitucion: false,
    nombrePk: 'Placa patente',
    label: 'Placa patente o RUT',
    labelComprobantePago: 'El comprobante de pago',
    pagoEnCuotas: false,
    pagoEnPasos: false,
    visibleEnHomePage: true,
    tiposInstitucion: [2],
    icono: mdiBank,
  },
  {
    ...permisosDeCirculacionBase,
    id: 15,
    habilitado: true,
    slug: 'permisos-de-circulacion-primer-permiso',
    microservicio: 'permisosdecirculacion',
    nombre: 'Primer permiso de circulación vehículos nuevos',
    nombreCorto: 'Vehículos nuevos',
    resumen: 'Ingresa tu rut o placa patente y sigue las instrucciones de pago',
    pagoEnCuotas: false,
    pagoEnPasos: true,
    icono: mdiCarSide,
  },
]
*/

const convertArrayToObject = (array, key) => {
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    }
  }, {})
}

/**
 * Retorna el trámite basado en su slug
 */
export function tramiteBySlug(slug) {
  return tramites.find((t) => t.slug === slug)
}

/**
 * Retorna el trámite basado en su id
 */
export function tramiteById(id) {
  id = parseInt(id)
  return tramites.find((t) => t.id === id)
}

const objTramites = tramites.map((t) => new Tramite(t))

export const mixinTramites = {
  computed: {
    /**
     * Retorna la lista de trámites
     */
    $tramites() {
      return objTramites
    },

    /**
     * Arreglo de trámites
     *
     * @returns {Tramite[]}
     */
    payloadListaTramitesStatic() {
      return convertArrayToObject(
        tramites.filter((t) => t.habilitado),
        'slug'
      )
    },
    payloadListaTramitesByIdStatic() {
      return convertArrayToObject(
        tramites.filter((t) => t.habilitado),
        'id'
      )
    },
    // payloadListaTramitesStaticDeshabilitados () {
    //   return convertArrayToObject(tramites.filter(t => !t.habilitado).map(t => new Tramite(t)), 'slug')
    // },
  },
}

if (!Vue.__mixin_tramites__) {
  Vue.__mixin_tramites__ = true
  Vue.mixin(mixinTramites)
}
