<template>
  <div
    style="height: calc(100vh - 200px); min-height: 360px"
    class="d-flex align-center justify-center"
  >
    <v-card
      :elevation="$vuetify.breakpoint.xsOnly ? 0 : $vuetify.theme.currentTheme.elevation"
      class="mx-auto pb-6"
      :class="{
        'mt-16': $vuetify.breakpoint.mdAndUp,
        'mt-8': $vuetify.breakpoint.sm,
        'mt-2': $vuetify.breakpoint.xs,
      }"
      outlined
      width="400"
    >
      <barra-superior-gobierno-de-chile class="ml-4" />

      <v-card-text class="text-center">
        <logo-sem-color :style="{ width: $vuetify.breakpoint.xsOnly ? '150px' : '200px' }" />

        <div class="px-sm-16 mt-8 mb-8 text-subtitle-1">
          Para continuar debes ingresar con tu ClaveÚnica:
        </div>

        <btn-clave-unica :href="urlClaveUnica" />



        <div v-if="linkLoginLocal" class="mt-2 text-caption">
          <router-link to="/login/form">login local</router-link>
        </div>
        <v-card-text v-if="$route.params && $route.params.mensajeDeErrorLogin" class="px-12">
          <flash-message :error="$route.params.mensajeDeErrorLogin" />
        </v-card-text>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import BarraSuperiorGobiernoDeChile from '@/components/Icons/BarraSuperiorGobiernoDeChile'
import { mapGetters } from 'vuex'
import LogoSemColor from '@/components/Icons/LogoSemColor'
import BtnClaveUnica from '@/components/BtnClaveUnica'
import FlashMessage from '@/components/FlashMessage'

export default {
  name: 'Login',
  components: { BtnClaveUnica, LogoSemColor, BarraSuperiorGobiernoDeChile, FlashMessage},
  data() {
    return {
      error: null,
      valid: true,
      showPassword: false,
      form: {
        login: '',
        password: '',
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['loggedIn']),
    ...mapGetters('app', ['loading']),
    linkLoginLocal() {
      return process.env.VUE_APP_LINK_LOGIN_LOCAL === 'true'
    },
    urlClaveUnica() {
      return process.env.VUE_APP_API_URL + '/usuarios/auth/claveunica/redirect?admin=true'
    },
    ambiente() {
      return process.env.VUE_APP_AMBIENTE
    },
  },
  beforeCreate() {
    if (this.loggedIn) {
      this.$router.push({ path: '/dashboard' })
    }
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('auth/login', this.form)
          .then(() => this.$router.push('/dashboard'))
          .catch((error) => {
            this.error = error
          })
      }
    },
  },
}
</script>

<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}
</style>
