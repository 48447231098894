<template>
  <div>
    <z-mantenedor
      ref="mantenedor"
      v-model="items"
      :botones-listar="[
        {
          title: 'Usuarios',
          click: (v) => $router.push(`/admin/users?institucion_id=${v.id}`),
          posicion: 'menu',
        },
        {
          title: 'Firmantes',
          click: (v) => $router.push(`/admin/firmantes?institucion_id=${v.id}`),
          posicion: 'menu',
        },
        { title: 'Ver historial', icon: 'mdi-history', click: mostrarHistorial, posicion: 'menu' },
      ]"
      :botones-ver="[{ title: 'Ver historial', icon: 'mdi-history', click: mostrarHistorial }]"
      :headers="headers"
      :query="query"
      url="/usuarios/instituciones"
      search-url="/usuarios/instituciones/search"
      search-method="post"
      campo-titulo-dialog-crear="Crear institución"
      search-inicial
      texto-filtrar="Filtrar"
      texto-hits="%s instituciones."
      texto-no-hit="No se encontraron instituciones."
      texto-un-hit="Una institución."
      :on-after-search="onAfterSearch"
      @created="$emit('notificar', 'Institución creada.')"
      @deleted="$emit('notificar', 'Institución eliminada.')"
      @updated="$emit('notificar', 'Institución guardada.')"
      @created:error="$emit('notificar:error', 'Error al intentar crear.')"
      @update:error="$emit('notificar:error', 'Error al intentar guardar.')"
      @deleted:error="$emit('notificar:error', 'Error al intentar eliminar.')"
    >
      <template #buscador="{ loading, search, create }">
        <v-card class="d-flex justify-space-between" flat>
          <v-select
            v-model="query.filter.tipo"
            :items="payloadItemsTiposInstitucion"
            clearable
            dense
            label="Tipo"
            outlined
          />
          <v-spacer />
          <z-btn :loading="loading" color="primary" outlined @click="search"> Actualizar </z-btn>
          <z-btn class="ml-1" color="primary" outlined @click="create({ tipo: 3 })"> Crear </z-btn>
        </v-card>
      </template>

      <template #item.nombre="{ item }">
        {{ item.nombre }}
        <div v-if="item.tipo !== 1 && item.tipo !== 2" class="font-weight-light">
          {{ $payloadStatic.municipalidad(item.municipalidad_id).nombre }}
        </div>
      </template>

      <template #view="{ item }">
        <instituciones-view :item="item" />
      </template>

      <template #form="{ accion, form, errors }">
        <instituciones-form
          :accion="accion"
          :errors="errors"
          :value="form"
          @input="form = $event"
        />
      </template>
    </z-mantenedor>

    <dialog-historial
      v-if="historialVisible"
      :id="itemActivo.id"
      :titulo="itemActivo.nombre"
      microservicio="usuarios"
      clase="institucion"
      @cerrar="historialVisible = false"
    />
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import InstitucionesForm from '@/admin/views/Admin/InstitucionesForm'
import InstitucionesView from '@/admin/views/Admin/InstitucionesView'
import ZMantenedor from '@/components/ZMantenedor'
import DialogHistorial from '@/admin/components/DialogHistorial'

export default {
  components: { DialogHistorial, ZMantenedor, InstitucionesView, InstitucionesForm, ZBtn },
  data() {
    return {
      items: [],
      itemActivo: {},
      historialVisible: false,
      headers: [
        {
          text: 'Nombre',
          value: 'nombre',
          class: 'text-no-wrap',
        },
        {
          text: 'ID',
          value: 'id',
        },
        // { text: 'E-Sign', value: 'codigo_esign' },
        // { text: 'TGR', value: 'codigo_tgr' },
        // { text: 'Subdere', value: 'codigo_subdere' },
      ],
      query: {
        paginationDisabled: true,
        filter: {
          tipo: null,
        },
        sort: {
          0: { field: 'nombre', direction: 'asc' },
        },
      },
    }
  },
  methods: {
    mostrarHistorial(item) {
      this.itemActivo = item
      this.historialVisible = true
    },
    onAfterSearch(response) {
      if (this.$route.query.institucion_id) {
        this.verInstitucion(response, parseInt(this.$route.query.institucion_id))
        this.$route.query.institucion_id = 0
      }
    },
    verInstitucion(response, institucionId) {
      const item = response.data?.data?.find((i) => i.id === institucionId)
      if (item) {
        this.$refs.mantenedor.viewItem(item)
      }
    },
  },
}
</script>
