var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"calc(100vh - 200px)","min-height":"360px"}},[_c('v-card',{staticClass:"mx-auto pb-6",class:{
      'mt-16': _vm.$vuetify.breakpoint.mdAndUp,
      'mt-8': _vm.$vuetify.breakpoint.sm,
      'mt-2': _vm.$vuetify.breakpoint.xs,
    },attrs:{"elevation":_vm.$vuetify.breakpoint.xsOnly ? 0 : _vm.$vuetify.theme.currentTheme.elevation,"outlined":"","width":"400"}},[_c('barra-superior-gobierno-de-chile',{staticClass:"ml-4"}),_c('v-card-text',[_c('div',{staticClass:"text-center"},[_c('logo-sem-color',{style:({ width: _vm.$vuetify.breakpoint.xsOnly ? '150px' : '250px' })})],1)]),_c('v-form',{ref:"form",staticClass:"mx-auto",staticStyle:{"max-width":"330px"},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',{staticClass:"px-12 py-0"},[_c('v-text-field',{staticClass:"autofillWhite",attrs:{"error-messages":_vm.error && _vm.error.login,"rules":[
            (v) => !!v || 'Debes ingresar tu RUT',
            (v) => (v && v.length >= 3) || 'Debes ingresar un RUT válido',
          ],"autocomplete":"username","autocorrect":"off","name":"username","label":"Ingresa tu RUT","maxlength":"50","minlength":"3","outlined":"","required":"","data-testid":"login"},model:{value:(_vm.form.login),callback:function ($$v) {_vm.$set(_vm.form, "login", $$v)},expression:"form.login"}}),_c('v-text-field',{attrs:{"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"error-messages":_vm.error && _vm.error.password,"rules":[
            (v) => !!v || 'Debes ingresar tu clave',
            (v) => (v && v.length >= 3) || 'Clave demasiada corta',
          ],"type":_vm.showPassword ? 'text' : 'password',"autocomplete":"current-password","label":"Ingresa tu clave","maxlength":"50","minlength":"3","outlined":"","required":"","data-testid":"password"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),(typeof _vm.error === 'string')?_c('v-card-text',{staticClass:"px-12 my-n4"},[_c('flash-message',{attrs:{"error":_vm.error}})],1):_vm._e(),_c('v-card-actions',{staticClass:"d-flex flex-column justify-space-between"},[_c('v-btn',{attrs:{"large":"","type":"submit","color":"primary","loading":_vm.loading,"width":"160"}},[_vm._v(" iniciar sesión ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }