import { mdiBank, mdiCarSide, mdiBusSide, mdiTruck, mdiHandBackRight } from '@mdi/js'

/**
 * Definiciones comunes para trámites permisos de circulación
 */
const permisosDeCirculacionBase = {
  resumen: 'Ingresa tu rut o placa patente y sigue las instrucciones de pago',
  icono: mdiCarSide,
  nombreSingular: 'Patente',
  nombrePlural: 'Patentes',
  nombreEsMasculino: false,
  requiereInstitucion: false,
  nombrePk: 'Patente',
  label: 'RUT o placa patente',
  labelComprobantePago: 'El permiso de circulación',
  pagoEnCuotas: true,
  pagoEnPasos: true,
  tiposInstitucion: [2],
  pagoEnPasosTexto: `
            En el siguiente paso, antes de pagar, se consultará en línea el estado actual de
            la Revisión Técnica y de Gases, el Seguro Obligatorio de Accidentes Personales (SOAP)
            y el Registro de Pasajeros Infractores (RPI).
    `,
  visibleEnHomePage: true,
  textoSinResultados(vue) {
    const institucion = vue.$store.getters['app/institucion']
    const municipalidad = institucion?.nombre || 'la municipalidad'
    const telefono = institucion?.telefono ? ` al teléfono ${institucion.telefono}` : ''

    return `
        <p>
        Esto puede ocurrir porque su permiso anterior no fue pagado en ${municipalidad} o
        la municipalidad no incluyó su placa patente en este listado de pago.
        </p>
        <p>
        En caso de dudas, le recomendamos llamar a la municipalidad ${telefono} o acercarse a
        las oficinas de pago para aclarar su situación.
        </p>
     `
  },
}

/**
 * Definiciones comunes para trámites permisos de circulación
 */
const infraccionesBase = {
  microservicio: 'tramitesgenerales',
  resumen: 'Ingresa el rol de la causa y sigue las instrucciones de pago',
  nombreSingular: 'Infracción',
  nombrePlural: 'Infracciones',
  nombreEsMasculino: false,
  requiereInstitucion: true,
  requiereJpl: true, // TODO
  tiposInstitucion: [3],
  nombrePk: 'Causa',
  label: 'Número y año de causa',
  labelComprobantePago: 'La causa',
  pagoEnCuotas: false,
  pagoEnPasos: false,
  visibleEnHomePage: true,
  textoSinResultados: null,
  icono: mdiHandBackRight,
}

const tramites = [
  {
    id: 2,
    habilitado: true,
    slug: 'patentes-municipales',
    microservicio: 'tramitesgenerales',
    nombre: 'Patentes municipales',
    resumen: 'Ingresa tu rut o rol de avalúo y sigue las instrucciones de pago',
    nombreSingular: 'Patente',
    nombrePlural: 'Patentes',
    nombreEsMasculino: false,
    requiereInstitucion: true,
    nombrePk: 'Rol de avalúo',
    label: 'RUT o rol de avalúo',
    labelComprobantePago: 'El comprobante de pago',
    pagoEnCuotas: false,
    pagoEnPasos: false,
    visibleEnHomePage: true,
    tiposInstitucion: [2],
    icono: mdiBank,
  },
  {
    ...permisosDeCirculacionBase,
    id: 3,
    habilitado: true,
    slug: 'permisos-de-circulacion',
    microservicio: 'permisosdecirculacion',
    nombre: 'Permisos de circulación vehículos livianos',
    nombreCorto: 'Vehículos livianos',
    resumen: 'Ingresa tu rut o placa patente y sigue las instrucciones de pago',
  },
  {
    ...permisosDeCirculacionBase,
    id: 4,
    habilitado: true,
    slug: 'permisos-de-circulacion-2c',
    microservicio: 'permisosdecirculacion2c',
    nombre: 'Segunda cuota de permisos de circulación',
    nombreCorto: 'Segunda cuota',
    pagoEnCuotas: false,
    pagoEnPasos: false,
    textoSinResultados: null,
  },
  {
    ...permisosDeCirculacionBase,
    id: 5,
    habilitado: true,
    slug: 'permisos-de-circulacion-pesados',
    microservicio: 'permisosdecirculacion',
    nombre: 'Permisos de circulación vehículos pesados',
    nombreCorto: 'Vehículos pesados',
    icono: mdiTruck,
  },
  {
    ...permisosDeCirculacionBase,
    id: 6,
    habilitado: true,
    slug: 'permisos-de-circulacion-locomocion-colectiva',
    microservicio: 'permisosdecirculacion',
    nombre: 'Permisos de circulación locomoción colectiva',
    nombreCorto: 'Locomoción colectiva',
    resumen: 'Ingresa tu rut o placa patente y sigue las instrucciones de pago',
    icono: mdiBusSide,
  },
  {
    id: 7,
    habilitado: true,
    slug: 'permisos-provisorios',
    microservicio: 'tramitesgenerales',
    nombre: 'Permisos provisorios',
    resumen: 'Ingresa tu RUT o número de puesto y sigue las instrucciones de pago',
    nombreSingular: 'Puesto',
    nombrePlural: 'Puestos',
    nombreEsMasculino: true,
    requiereInstitucion: true,
    nombrePk: 'Puesto',
    label: 'RUT o nro. puesto',
    labelComprobantePago: 'El comprobante de pago',
    pagoEnCuotas: false,
    pagoEnPasos: false,
    visibleEnHomePage: true,
    tiposInstitucion: [2],
    icono: mdiBank,
  },
  {
    id: 8,
    habilitado: true,
    slug: 'derechos-de-aseo',
    microservicio: 'tramitesgenerales',
    nombre: 'Derechos de aseo',
    resumen: 'Ingresa tu rut o rol de avalúo y sigue las instrucciones de pago',
    nombreSingular: 'Derecho de aseo',
    nombrePlural: 'Derechos de aseo',
    nombreEsMasculino: true,
    requiereInstitucion: true,
    nombrePk: 'Rol de avalúo',
    label: 'Rut o rol de avalúo',
    labelComprobantePago: 'El comprobante de pago',
    pagoEnCuotas: false,
    pagoEnPasos: false,
    visibleEnHomePage: true,
    tiposInstitucion: [2],
    icono: mdiBank,
  },
  {
    id: 9,
    habilitado: true,
    slug: 'multas',
    microservicio: 'permisos-de-circulacion',
    nombre: 'Multas de tránsito no pagadas',
    nombreCorto: 'Multas',
    resumen: '',
    nombreSingular: 'Multa',
    nombrePlural: 'Multas',
    nombreEsMasculino: false,
    requiereInstitucion: true,
    nombrePk: 'Identificador',
    label: '',
    labelComprobantePago: 'El comprobante de pago',
    pagoEnCuotas: false,
    pagoEnPasos: false,
    visibleEnHomePage: false,
    tiposInstitucion: [2],
    icono: mdiBank,
  },
  {
    id: 10,
    habilitado: true,
    slug: 'pagos-genericos',
    microservicio: 'tramitesgenerales',
    nombre: 'Pagos genéricos',
    resumen: '',
    nombreSingular: 'Pago genérico',
    nombrePlural: 'Pagos genéricos',
    nombreEsMasculino: true,
    requiereInstitucion: true,
    nombrePk: 'Tag',
    label: 'Tag',
    labelComprobantePago: 'El comprobante de pago',
    pagoEnCuotas: false,
    pagoEnPasos: false,
    visibleEnHomePage: false,
    formularioDeBusqueda: false,
    tiposInstitucion: [2],
    icono: mdiBank,
  },
  {
    ...infraccionesBase,
    id: 11,
    habilitado: true,
    slug: 'infracciones-presenciales',
    nombre: 'Infracciones presenciales',
  },
  {
    ...infraccionesBase,
    id: 12,
    habilitado: true,
    slug: 'infracciones-empadronadas',
    nombre: 'Infracciones empadronadas',
  },
  {
    ...infraccionesBase,
    id: 13,
    habilitado: true,
    slug: 'infracciones-transantiago',
    nombre: 'Infracciones Transantiago',
  },

  {
    id: 14,
    habilitado: true,
    slug: 'multas-tag',
    microservicio: 'tramitesgenerales',
    nombre: 'Multas TAG',
    resumen: 'Ingresa tu placa patente o rut y sigue las instrucciones de pago',
    nombreSingular: 'Multa TAG',
    nombrePlural: 'Multas TAG',
    nombreEsMasculino: false,
    requiereInstitucion: false,
    nombrePk: 'Placa patente',
    label: 'Placa patente o RUT',
    labelComprobantePago: 'El comprobante de pago',
    pagoEnCuotas: false,
    pagoEnPasos: false,
    visibleEnHomePage: true,
    tiposInstitucion: [2],
    icono: mdiBank,
  },
  {
    ...permisosDeCirculacionBase,
    id: 15,
    habilitado: true,
    slug: 'permisos-de-circulacion-primer-permiso',
    microservicio: 'permisosdecirculacion',
    nombre: 'Primer permiso de circulación vehículos nuevos',
    nombreCorto: 'Vehículos nuevos',
    resumen: 'Ingresa tu rut o placa patente y sigue las instrucciones de pago',
    pagoEnCuotas: false,
    pagoEnPasos: true,
    icono: mdiCarSide,
  },
]

console.log('importado')
export default tramites
