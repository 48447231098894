<template>
  <div>
    <!--
    @slot Formulario de edición. Debe invocar la función `save` para ejecutar el guardado.
      @binding {Object} errors Objeto con los eventuales mensajes de error al intentar guardar.
      @binding {Object} form Objeto con el registro a editar.
      @binding {Boolean} loading Indica que se está realizando una operación de guardado o lectura.
       Sirve por ejemplo para bloquear los botones durante el guardado.
      @binding {Function} save Función para ejecutar el guardado del formulario.
    -->
    <slot :errors="errors" :form="value" :loading="loading" :save="save" name="default" />
  </div>
</template>
<script>
import CrudService from '@/admin/services/CrudService'
import { getError } from '@/utils/errors'

/**
 * Componente para generar un formulario de edición
 * conectado a un endpoint REST.
 */
export default {
  props: {
    /**
     * Objeto con los datos del formulario
     */
    value: {
      type: Object,
      required: true,
    },

    /**
     * URL de la API REST.
     *
     * Por ejemplo:
     *
     * ```
     * http://example.com/api/v1/usuarios
     * ```
     */
    url: {
      type: String,
      required: true,
    },

    /**
     * URL opcional donde guardar los datos
     */
    urlSave: {
      type: String,
      default: null,
    },

    id: {
      type: [String, Number],
      required: true,
    },

    /**
     * Función llamada antes de guardar un registro.
     * Recibe el objeto a guardar, el cual puede ser modificado.
     *
     * Para abortar la operación debes retornar `false`.
     */
    beforeSave: {
      type: Function,
      default: (v) => v,
    },

    /**
     * Función llamada después de guardar un registro.
     * Recibe el objeto retornado por el servidor.
     * Es posible modificar el contenido del objeto.
     */
    afterSave: {
      type: Function,
      default: (v) => v,
    },

    /**
     * Función llamada después de leer un registro desde la API.
     * Recibe el objeto retornado por el servidor, * es posible modificar el contenido del objeto.
     */
    afterRead: {
      type: Function,
      default: (v) => v,
    },

    /**
     * Texto con mensaje de error en caso de no poder leer el registro
     */
  },
  data: () => ({
    valid: true,
    loading: true,
    errors: {},
  }),
  watch: {
    id: function (value) {
      this.$emit('input', {})
      this.read()
    },
  },
  created() {
    this.read()
  },
  methods: {
    read() {
      this.loading = true
      CrudService.get(this.url, { id: this.id })
        .then((response) => {
          const form = response.data.data || response.data
          this.afterRead(form)
          this.$emit('input', form)
          this.$emit('readed', form)
        })
        .catch((error) => {
          this.$emit('read:error', error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    save() {
      // if (this.$refs.form.validate()) {
      const form = { ...this.value }
      if (this.beforeSave(form) === false) {
        return
      }
      this.loading = true
      CrudService.patch(this.urlSave || this.url, form, !this.urlSave)
        .then((response) => {
          const data = response.data.data || response.data
          this.afterSave(data)
          this.$emit('input', data)
          this.$emit('updated', data)
        })
        .catch((error) => {
          this.errors = getError(error)
          this.$emit('update:error', error)
        })
        .finally(() => {
          this.loading = false
        })
      // }
    },
  },
}
</script>
