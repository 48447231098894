import Vue from 'vue'
import store from '@/admin/store/index'

export const helpers = {
  /**
   * Función genérica para buscar elementos dentro del payload.
   *
   * Generalmente es llamada internamente por otras funciones
   * para así brindar una función más expresiva.
   *
   * Ejemplo de uso:
   * $payload.get('instituciones', 365775)
   *
   * Ejemplo para retornar el listado completo:
   * $payload.get('instituciones')
   */
  get(elemento, value, emptyValue = [], key = 'id') {
    if (value === null || !store.getters['app/payload'][elemento]) {
      return emptyValue
    }
    const object = store.getters['app/payload'][elemento]
    if (typeof value === 'undefined') {
      return object
    }
    if (Array.isArray(object)) {
      // eslint-disable-next-line eqeqeq
      return object.find((i) => i[key] == value) || emptyValue
    }

    return object[value] || emptyValue
  },

  /**
   * Convierte un Array u Object en un Array de elementos { text, value} para ser
   * usados en componentes de listas de selección Vuetify.
   *
   * @returns {{text: string, value: string}[]}
   */
  items(el, text = 'nombre', value = 'id') {
    // Convierte el objeto/array a un array de elementos {key, text}
    if (Array.isArray(el)) {
      el = el.map((v) => ({ value: helpers.toIntIfPossible(v[value]), text: text }))
    } else {
      el = Object.entries(el).map(([key, text]) => ({
        value: helpers.toIntIfPossible(key),
        text: text,
      }))
    }

    // Ordena por text
    return el.sort((a, b) => a.text.localeCompare(b.text))
  },

  /**
   * Convierte el valor a Int en caso que sea un valor numérico
   */
  toIntIfPossible(value) {
    const intvalue = parseInt(value)
    return Number.isNaN(intvalue) ? value : intvalue
  },
}

const payload = {
  get: helpers.get,
  banco(value, emptyValue = '') {
    return helpers.get('bancos', value, emptyValue)
  },
  bancos() {
    return helpers.get('bancos')
  },
  estadoCuenta(value, emptyValue = '') {
    return helpers.get('estados_cuenta', value, emptyValue)
  },
  estadosCuenta() {
    return helpers.get('estados_cuenta')
  },
  estadoNomina(value, emptyValue = '') {
    return helpers.get('estados_nomina', value, emptyValue)
  },
  estadosNomina() {
    return helpers.get('estados_nomina')
  },
  estadoPago(value, emptyValue = '') {
    return helpers.get('estados_pago', value, emptyValue)
  },
  estadosPago() {
    return helpers.get('estados_pago')
  },
  firmantes(label = 'nombre') {
    return Object.fromEntries(
      helpers
        .get('firmantes')
        .filter((f) => f.activo)
        .map((i) => [i.proveedor + ':' + i.codigo, i[label]])
    )
  },
  institucion(value, emptyValue = '', campo = 'nombre') {
    return helpers.get('instituciones', value)[campo] || emptyValue
  },
  instituciones(label = 'nombre', key = 'id') {
    return Object.fromEntries(helpers.get('instituciones').map((i) => [i[key], i[label]]))
  },
  ira(value, emptyValue = '') {
    return helpers.get('iras', value, emptyValue)
  },
  iras() {
    return helpers.get('iras')
  },
  proveedorFirma(value, emptyValue = '') {
    return this.proveedoresFirma().find((p) => p.value === value)?.text ?? emptyValue
  },
  proveedoresFirma() {
    return [
      { text: 'ESign', value: 'esign' },
      { text: 'e-certchile', value: 'ecert' },
    ]
  },
  roles() {
    return helpers.get('roles')
  },
  tipoInstitucion(value, emptyValue = '') {
    return helpers.get('tipos_institucion', value, emptyValue)
  },
  tiposInstitucion() {
    return helpers.get('tipos_institucion')
  },
  tramite(value, campo = 'nombre', key = 'slug') {
    return helpers.get('tramites', value, [], key)[campo] || ''
  },
  tramites(label = 'nombre', key = 'slug') {
    return Object.fromEntries(helpers.get('tramites').map((t) => [t[key], t[label]]))
  },
  users() {
    return helpers.get('users')
  },

  grupo(value, campo = 'nombre', key = 'id') {
    return helpers.get('grupos', value, [], key)[campo] || ''
  },

  grupos(label = 'nombre') {
    return Object.fromEntries(helpers.get('grupos').map((t) => [t.id, t[label]]))
  },

  gruposSeccion(seccion, label = 'nombre') {
    return Object.fromEntries(
      helpers
        .get('grupos')
        .filter((g) => g.seccion === seccion)
        .map((t) => [t.id, t[label]])
    )
  },

  gruposCapacitacion() {
    return helpers.get('capacitacion')
  },

  seccion(value, emptyValue = '') {
    return helpers.get('secciones', value, emptyValue)
  },

  secciones() {
    return helpers.get('secciones')
  },

  estadoOirs(value, emptyValue = '') {
    return helpers.get('estadosOirs', value, emptyValue)
  },

  estadosOirs() {
    return helpers.get('estadosOirs')
  },

  tipoOirs(value, emptyValue = '') {
    return helpers.get('tiposOirs', value, emptyValue)
  },

  tiposOirs() {
    return helpers.get('tiposOirs')
  },

  categoriaOirs(value, emptyValue = '') {
    return helpers.get('categoriasOirs', value, emptyValue)
  },

  categoriasOirs() {
    return helpers.get('categoriasOirs')
  },

  tipoTramiteOirs(value, emptyValue = '') {
    return helpers.get('tiposTramiteOirs', value, emptyValue)
  },

  tiposTramiteOirs() {
    return helpers.get('tiposTramiteOirs')
  },
}

Vue.use({
  install(app) {
    app.prototype.$payload = payload
    app.mixin({
      computed: {
        payloadItemsBancos() {
          return helpers.items(payload.bancos())
        },
        payloadListaBancos() {
          return payload.bancos()
        },
        payloadItemsIras() {
          return helpers.items(payload.iras())
        },
        payloadListaIras() {
          return payload.iras()
        },
        payloadItemsEstadosCuenta() {
          return helpers.items(payload.estadosCuenta())
        },
        payloadListaEstadosCuenta() {
          return payload.estadosCuenta()
        },
        payloadItemsEstadosNomina() {
          return helpers.items(payload.estadosNomina())
        },
        payloadListaEstadosNomina() {
          return payload.estadosNomina()
        },
        payloadItemsEstadosPago() {
          return helpers.items(payload.estadosPago())
        },
        payloadListaEstadosPago() {
          return payload.estadosPago()
        },
        payloadItemsFirmantes() {
          return helpers.items(payload.firmantes())
        },
        payloadListaFirmantes() {
          return payload.firmantes()
        },
        payloadItemsInstituciones() {
          return helpers.items(payload.instituciones())
        },
        payloadListaInstituciones() {
          return payload.instituciones()
        },
        payloadItemsProveedoresFirma() {
          return payload.proveedoresFirma()
        },
        payloadListaRoles() {
          return payload.roles()
        },
        payloadItemsTiposInstitucion() {
          return helpers.items(payload.tiposInstitucion())
        },
        payloadListaTiposInstitucion() {
          return payload.tiposInstitucion()
        },
        payloadItemsTramites() {
          return helpers.items(payload.tramites())
        },
        payloadItemsTramitesId() {
          return helpers.items(payload.tramites('nombre', 'id'))
        },
        payloadListaTramites() {
          return payload.tramites()
        },
        payloadListaUsers() {
          return payload.users()
        },

        payloadItemsGrupos() {
          return helpers.items(payload.grupos())
        },
        payloadListaGrupos() {
          return payload.grupos()
        },

        payloadItemsEstadoOirs() {
          return helpers.items(payload.estadosOirs())
        },
        payloadItemsTipoOirs() {
          return helpers.items(payload.tiposOirs())
        },

        // Movidos a "payload static"
        // payloadItemsTipoTramiteOirs () { return helpers.items(payload.tiposTramiteOirs()) },
        // payloadItemsCategoriaOirs () { return helpers.items(payload.categoriaOirs()) },
      },
    })
  },
})

export default payload
