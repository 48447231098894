import Vue from 'vue'
import Vuex from 'vuex'

import * as app from './modules/App'
import * as auth from './modules/Auth'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app,
    auth,
  },
})
