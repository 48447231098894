<template>
  <div>
    <z-card
      v-if="!!$scopedSlots.buscador"
      v-model="buscadorVisibleComputed"
      :colapsable="buscadorColapsable"
      :title="titulo"
    >
      <v-card-text>
        <!--
          @slot Formulario de búsqueda
            @binding {Boolean} loading Indica que se está haciendo un request
            @binding {Function} search Función que gatilla el request
        -->
        <slot :loading="loading" :search="search" name="buscador" />
      </v-card-text>
    </z-card>

    <div
      v-if="textoHitsComputed && (loading || !ocultarTextosHits)"
      class="text-caption text--secondary mt-n2 mb-2"
    >
      {{ textoHitsComputed }}
      {{ textoHitsTotal }}
    </div>

    <!--
    @slot Contenido inicial antes de realizar la primera búsqueda
    -->
    <slot v-if="!loading && items.length === 0 && contadorBusquedas === 0" name="inicial" />

    <!--
    @slot Contenido cuando no se hayan encontrado resultados de búsqueda
    -->
    <slot
      v-else-if="!loading && items.length === 0 && contadorBusquedas > 0"
      name="sinResultados"
    />

    <!--
    @slot Contenido anterior a resultados de búsqueda y después de formulario
       @binding {Boolean} loading Indica que se está haciendo un request
       @binding {Function} search Función que gatilla el request
       @binding {Array} Arreglo con los resultados de búsqueda
    -->
    <slot name="cabecera" :loading="loading" :search="search" :items="items" />

    <z-card
      v-bind="cardDataTableProps"
      :class="[
        'mx-auto',
        items.length && mostrarResultados ? 'z-form-buscador-fade-in' : 'z-form-buscador-fade-out',
      ]"
    >
      <!--

          Filtrado del data-table

      -->

      <v-card-text v-if="textoFiltrar || $scopedSlots.cabeceraInterior">
        <v-row>
          <v-col v-if="$scopedSlots.cabeceraInterior">
            <!--
            @slot Contenido superior izquierdo. Usualmente usado para un botón "crear"
               @binding {Function} search Función que gatilla el request
               @binding {Array} Arreglo con los resultados de búsqueda
            -->
            <slot name="cabeceraInterior" :search="search" :items="items" />
          </v-col>
          <v-spacer v-else />
          <v-col v-if="textoFiltrar" cols="12" md="4" sm="6">
            <v-text-field
              v-model="filtro"
              :label="textoFiltrar"
              prepend-inner-icon="mdi-magnify"
              dense
              hide-details
              single-line
              data-testid="z-form-searh-filtrar"
            />
          </v-col>
        </v-row>
        <v-spacer />
      </v-card-text>

      <!--

          data-table

      -->

      <v-data-table
        v-bind="dataTablePropsComputed"
        :custom-filter="filterDataTable"
        :footer-props="dataTableFooterProps"
        :headers="headersComputed"
        :hide-default-footer="items.length <= (dataTablePropsComputed.itemsPerPage || 10)"
        :items="items"
        :search="normalizar(filtro)"
      >
        <template v-if="$scopedSlots.item" #item="{ item }">
          <slot :item="item" name="item" />
        </template>
        <template
          v-for="header in headersComputed"
          #[`item.${header.value}`]="{ item, value: val }"
        >
          <!--
          @slot Definición de celda del datatable
            @binding {Object} header Definicicón del header
            @binding {Object} item Objeto de la fila actual
            @binding value Valor de la celda
          -->
          <slot :header="header" :item="item" :name="`item.${header.value}`" :value="val">
            {{ formatDataTableField(header, item) }}
          </slot>
        </template>
      </v-data-table>
    </z-card>
  </div>
</template>

<script>
import qs from 'qs'
// import deepmerge from 'deepmerge'
import ZCard from '@/components/ZCard'
import axios from 'axios'

export default {
  name: 'ZFormSearch',
  components: { ZCard },
  props: {
    /**
     * Título del buscador.
     */
    titulo: {
      type: String,
      default: undefined,
    },

    /**
     * Función invocada antes de una búsqueda.
     * Recibe el objeto con los parámetros de búsqueda.
     * Si retorna un Boolean `false`, se abortará el request.
     */
    onBeforeSearch: Function,
    /**
     * Función invocada después de una búsqueda.
     * Recibe el objeto response.
     * Debe retornar un array de items o `null` para no modificar el response.
     */
    onAfterSearch: {
      type: Function,
      default: null,
    },

    /**
     * **[En construcción]** Función invocada antes de una búsqueda automática.
     * Retorna un `Object` con datos o un `Boolean` que permite abortar la operación usando `false`.
     */
    onBeforeRefresh: Function,
    /**
     * **[En construcción]** Función invocada después de una búsqueda automática.
     */
    onAfterRefresh: Function,

    /**
     * **[En construcción]** `Date` para repetir la última búsqueda automáticamente.
     */
    refreshDate: {
      type: Date,
    },

    /**
     * Indica si el formulario de búsqueda se puede colapsar,
     * en cuyo caso se agregará un icono en el extremo superior derecho.
     */
    buscadorColapsable: {
      type: Boolean,
      default: false,
    },

    /**
     * Formulario de búsqueda visible.
     * Requiere habilitar `buscadorColapsable = true`.
     */
    buscadorVisible: {
      type: Boolean,
      default: true,
    },

    /**
     * Colapsa automáticamente el buscador al encontrar resultados de búsqueda.
     * Esto es útil para dispositivos móviles con pantalla pequeña.
     * Requiere habilitar `buscadorColapsable = true`.
     */
    buscadorAutoColapsar: {
      type: Boolean,
      default: false,
    },

    /**
     * Arreglo de objetos con definición de columnas para `<v-data-table>`.
     * Se debe definir al menos `text` y `value` por cada columna, ej:
     *
     * ```
     * [
     *   { text: 'Nombre', value: 'name' },
     *   { text: 'Correo electrónico', value: 'email' }
     * ]
     * ```
     *
     * #### Parámetros adicionales
     * Para más opciones, puedes ver la documentación de data-table de Vuetify.
     *
     * `formatter` permite definir una función
     * de formateo de los datos, por ejemplo:
     *
     * ```
     * formatter: (v) => this.$formato.rut(v)
     * ```
     *
     * `visible` Define si la columna será visible. `true` por defecto.
     *
     * ```
     * visible: false
     * ```
     *
     * `cellClass` String|Array Define las clases para la celda.
     *
     * ```
     * cellClass: 'text-no-wrap'
     * ```
     *
     */
    headers: {
      type: Array,
      required: true,
    },

    value: {
      type: Array,
      required: false,
      default: () => [],
    },

    /**
     * Objeto con los parámetros de búsqueda que se enviarán en el query string.
     */
    query: {
      type: Object,
      default: () => {},
    },

    /**
     * Indica si la búsqueda se realizará por GET o POST.
     */
    method: {
      type: String,
      default: 'get',
    },

    /**
     * Indica segundos antes de dar timeout de la busqueda.
     */
    timeout: {
      type: [Number, String],
      default: 30,
    },

    /**
     * URL de la API a consultar.
     *
     * Por ejemplo:
     *
     * ```
     * http://example.com/api/v1/usuarios
     * ```
     */
    url: {
      type: String,
      required: true,
    },

    /**
     * Realiza una búsqueda inicial antes de montar el componente
     */
    searchInicial: {
      type: Boolean,
      default: false,
    },

    /**
     * Filtrado adicional de filas, será llamado por cada campo y registro,
     * retornando `true` para incluir el item. Recibe los parámetros:
     *
     * - value
     * - search
     * - item
     */
    customFilter: {
      type: Function,
    },

    /**
     * Texto cuando se realiza la búsqueda
     */
    textoBuscando: {
      type: String,
      default: 'Buscando...',
    },
    /**
     * Texto que habilita el filtrado de los elementos del data-table
     */
    textoFiltrar: {
      type: String,
      default: undefined,
    },
    /**
     * Texto cuando la búsqueda no arrojó resultados.
     */
    textoNoHit: {
      type: String,
      default: 'No se encontraron resultados.',
    },
    /**
     * Texto cuando hay un resultado encontrado.
     */
    textoUnHit: {
      type: String,
      default: 'Un resultado.',
    },
    /**
     * Texto indicando la cantidad de resultados encontrados.
     */
    textoHits: {
      type: String,
      default: '%s resultados.',
    },

    textoHitsTotalResultados: {
      type: String,
      default: ' (de un total de %s)',
    },

    /**
     * Deshabilita los textos de resultados de búsqueda.
     */
    ocultarTextosHits: {
      type: Boolean,
      default: false,
    },

    /**
     * Parámetros que se pasaran al `v-data-table`, por ejemplo:
     *
     * @example
     * ```js
     * {
     *   itemsPerPage: 20,
     *   itemKey: 'id',
     *   showSelect: true,
     *   disableSort: true,
     * }
     * ```
     *
     * @see https://vuetifyjs.com/en/components/data-tables/
     */
    dataTableProps: {
      type: Object,
      default: () => ({}),
    },

    cardDataTableProps: {
      type: Object,
      default: () => ({}),
    },

    /**
     * Parámetros que se pasaran al `v-data-footer`, por ejemplo:
     *
     * @example
     * ```js
     * {
     *   itemsPerPageOptions: [
     *    10,
     *    15,
     *    50,
     *  ],
     * }
     * ```
     *
     * @see https://vuetifyjs.com/en/api/v-data-footer
     */
    dataTableFooterProps: {
      type: Object,
      default: () => ({
        itemsPerPageOptions: [10, 15, 50],
      }),
    },
  },
  data: () => ({
    $http: null,
    mostrarResultados: true,
    contadorBusquedas: 0,
    loading: false,
    refreshTimeout: null,
    // lastSearchData: null,
    // lastRefereshDate: new Date(),
    total: 0,
    metadata: {},
    filtro: '',
  }),
  computed: {
    headersComputed() {
      return this.headers.filter(
        (header) => typeof header.visible === 'undefined' || header.visible
      )
    },
    items: {
      get: function () {
        return this.value
      },
      set: function (value) {
        /**
         * Resultado de búsqueda actualizado
         * @property {Array} value Nuevos valores
         */
        this.$emit('input', value)
      },
    },
    dataTablePropsComputed() {
      const defaults = {
        itemsPerPage: 15,
        disableSort: this.$vuetify.breakpoint.xsOnly,
        mustSort: true,
      }
      return Object.assign(defaults, this.dataTableProps)
    },
    buscadorVisibleComputed: {
      get: function () {
        return this.buscadorVisible
      },
      set: function (value) {
        /**
         * Cambio de estado de visibilidad del formulario de búsqueda.
         *
         * @property {Boolean}
         */
        this.$emit('buscadorVisible', value)
      },
    },
    textoHitsComputed() {
      if (this.loading) {
        return this.textoBuscando
      }
      if (this.contadorBusquedas === 0) {
        return ''
      }
      switch (this.items.length) {
        case 0:
          return this.textoNoHit
        case 1:
          return this.textoUnHit
        default:
          return this.textoHits.replace(
            '%s',
            '' + Intl.NumberFormat('de').format(this.items.length)
          )
      }
    },
    textoHitsTotal() {
      if (
        this.loading ||
        this.contadorBusquedas === 0 ||
        this.items.length === 0 ||
        !this.metadata.total ||
        this.items.length === this.metadata.total
      ) {
        return ''
      }
      return this.textoHitsTotalResultados.replace(
        '%s',
        '' + Intl.NumberFormat('de').format(this.metadata.total)
      )
    },
  },
  watch: {
    loading: function (value) {
      if (value) {
        setTimeout(() => {
          if (this.loading) {
            this.mostrarResultados = false
          }
        }, 80)
      } else {
        this.mostrarResultados = true
      }
      /**
       * Cambio de estado de búsuqeda.
       *
       * @property {Boolean} `true` buscando, `false` búsqueda finalizada.
       */
      this.$emit('loading', value)
    },
    refreshDate(dateNextRefresh) {
      /*
      clearTimeout(this.refreshTimeout)
      if (dateNextRefresh) {
        const milisegundos = dateNextRefresh - Date.now()
        if (milisegundos > 0) {
          console.log('Próximo refresh en ' + Math.round(milisegundos / 1000) + 's')
          this.refreshTimeout = setTimeout(() => this.refresh(), milisegundos)
          this.$once('hook:destroyed', () => clearTimeout(this.refreshTimeout))
        }
      }
      */
    },
  },
  beforeMount() {
    // Instancio Axios en caso que no esté disponible en Vue.axios
    this.$http =
      this.$axios ||
      this.axios ||
      axios.create({
        timeout: 15 * 1000,
        headers: {
          Accept: 'application/json',
        },
      })
    // this.lastSearchData = JSON.parse(JSON.stringify(this.query || {}))
    if (this.searchInicial) {
      this.search()
    }
  },

  mounted() {},

  methods: {
    formatDataTableField(header, item) {
      let value = item[header.value] || header.default || ''
      if (header.formatter) {
        value = header.formatter(value, item, header)
      }
      return value
    },
    search() {
      // console.log('%c search() ', 'background: #444; color: #bada55; padding: 2px; border-radius:2px')
      const query = JSON.parse(JSON.stringify(this.query || {}))
      // console.clear()
      if (this.onBeforeSearch && this.onBeforeSearch(query) === false) {
        return
      }
      this.loading = true
      // this.lastSearchData = JSON.parse(JSON.stringify(query || {}))
      // this.lastRefereshDate = new Date()
      this.contadorBusquedas++

      // const querySting = qs.stringify(this.query, { arrayFormat: 'brackets', encodeValuesOnly: true })
      // console.log(querySting)
      // const url = new URL(this.url)
      // console.log(url)
      //, { timeout: `${this.timeout}` * 1000 }

      const request =
        this.method === 'post'
          ? this.$axios.post(`${this.url}`, query, { timeout: `${this.timeout}` * 1000 })
          : this.$http.get(this.url, {
              params: query,
              paramsSerializer: (params) =>
                qs.stringify(params, { arrayFormat: 'brackets', encodeValuesOnly: true }),
            })

      request
        .then((response) => {
          this.loading = false
          if (typeof response.data !== 'object') {
            console.error('Respuesta de `%s` inválida', this.url, response.data)
            response.data = []
          }

          let data = response.data.data || response.data.results || response.data
          this.metadata = response.data.meta || {}

          if (this.onAfterSearch) {
            const items = this.onAfterSearch(response)
            if (typeof items === 'object' && items !== null) {
              data = items
              // data = [...items]
            }
          }
          this.items = data

          if (this.buscadorColapsable && this.buscadorAutoColapsar && data.length > 0) {
            this.buscadorVisibleComputed = false
          }

          this.total = response.data.meta?.total || this.items.length
        })
        .catch((e) => {
          this.items = []
          this.metadata = {}
          this.loading = false
          console.error('Request a `%s` fallida', this.url, e)
        })
    },
    refresh() {
      // Obtengo parámetros para query string
      // Hago request
      /*
      const data = this.getRefreshData()
      this.lastRefereshDate = new Date()
      axios.get(this.mergeDataToQueryString(this.url, data))
        .then(response => {
          if (response.status === 200 && response.data.props.items) {
            response.data.props.items.forEach((newItem) => {
              const index = this.items.findIndex((item) => item.id === newItem.id)
              if (index !== -1) {
                this.items[index] = Object.assign(this.items[index], newItem)
              }
            })
            if (this.onAfterRefresh) {
              this.onAfterRefresh(response.data.props.items)
            }
          }
        })
       */
    },
    getRefreshData() {
      /*
      if (!this.onBeforeRefresh) {
        return this.lastSearchData
      }
      const refreshData = this.onBeforeRefresh(this.lastSearchData, this.lastRefereshDate)
      if (refreshData === false) {
        return this.lastSearchData
      }
      return (typeof refreshData === 'object') ? refreshData : this.lastSearchData
    },
    mergeDataToQueryString (url, data) {
      url = new URL(url)
      if (Object.keys(data).length) {
        url.search = qs.stringify(deepmerge(qs.parse(url.search, { ignoreQueryPrefix: true }), data), { encode: false })
      }
      return '' + url
    */
    },

    /**
     * Búsqueda personalizada que ignora acentos, mayúsculas/minúsculas y otros.
     * Considera todos los elementos del item
     */
    filterDataTable(value, search, item) {
      if (!value || !search) {
        return false
      }

      if (this.customFilter) {
        return this.customFilter(value, search, item)
      }

      if (typeof value === 'object') {
        value = value.nombre || null
      } else {
        value = Object.values(item).join(' ')
      }

      return this.normalizar(value).includes(search)
    },
    normalizar(value) {
      return !value
        ? ''
        : value
            .toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/[.,-]/g, '')
            .toLocaleLowerCase()
    },
  },
}
</script>

<style>
.z-form-buscador-fade-out {
  visibility: hidden;
  opacity: 0;
  transition: 150ms ease;
}

.z-form-buscador-fade-in {
  visibility: visible;
  opacity: 1;
  transition: 150ms ease;
}
</style>
