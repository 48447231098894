import Vue from 'vue'
import store from '@/admin/store/index'

const auth = {
  can(permissions) {
    return store.getters['auth/can'](permissions)
  },
  loggedIn() {
    return store.getters['auth/loggedIn']
  },
  user() {
    return store.getters['auth/authUser']
  },
  personificando() {
    return !!store.getters['app/payload']?.user_original
  },
  userOriginal() {
    return store.getters['app/payload']?.user_original || null
  },
  institucion() {
    return store.getters['auth/authUser']?.institucion
  },
}

Vue.use({
  install(app) {
    app.prototype.$auth = auth
  },
})
