<template>
  <div>
    <!--
    Al hacer click en el botón guardar.
    @event guardar
    -->
    <v-btn
      :loading="loading"
      :data-testid="dataTestIdGuardar"
      :disabled="disabled || disabledGuardar"
      color="primary"
      :type="type"
      v-bind="$attrs"
      v-on="$listeners"
      @click="$emit('guardar')"
    >
      {{ textoGuardar }}
    </v-btn>
    <!--
    Al hacer click en el botón cancelar.
    @event cancelar
    -->
    <v-btn
      v-if="!!textoCancelar"
      :disabled="disabled || loading"
      :data-testid="dataTestIdCancelar"
      color="grey"
      text
      @click="$emit('cancelar')"
    >
      {{ textoCancelar }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    textoCancelar: {
      type: String,
      default: 'Cancelar',
    },
    type: {
      type: String,
      default: 'submit',
    },
    textoGuardar: {
      type: String,
      default: 'Guardar',
    },
    /**
     * Deshabilita los botones guardar y cancelar.
     * Desde una perspectiva de usabilidad, en general es mejor
     * deshabilitar solo el botón guardar.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Deshabilita solo el botón guardar.
     */
    disabledGuardar: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Prefijo para los tag `data-testid` usandos en testing.
     * Usar string vacío para deshabilitarlo
     */
    dataTestid: {
      type: String,
      default: 'btn',
    },
  },
  computed: {
    dataTestIdGuardar() {
      return this.dataTestid ? `${this.dataTestid}-guardar` : ''
    },
    dataTestIdCancelar() {
      return this.dataTestid ? `${this.dataTestid}-cancelar` : ''
    },
  },
}
</script>
