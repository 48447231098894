<template>
  <v-btn
    :color="color"
    :disabled="disabled"
    :fab="fab || !!icon"
    :loading="loading"
    :outlined="outlined"
    :small="!icon ? small : !small"
    :text="text || !!icon"
    :to="to"
    :x-small="!!icon && small"
    v-on="$listeners"
  >
    <!-- @slot Contenido del botón -->
    <v-icon v-if="icon">
      {{ icon }}
    </v-icon>
    <slot />
  </v-btn>
</template>

<script>
export default {
  props: {
    /**
     * Color del botón
     * @values (vacío), primary, secondary, success, warning, error
     */
    color: String,

    /**
     * Link
     */
    to: String,

    /**
     * Deshabilita el botón
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Botón con aspecto redondo para posicionar únicamente un icono
     */
    fab: {
      type: Boolean,
      default: false,
    },

    /**
     * Convierte el botón en el icono indicado,
     * por ejemplo: `mdi-delete`
     */
    icon: {
      type: String,
      default: undefined,
    },

    /**
     * Deshabilita el botón e incluye una animación de "cargando"
     */
    loading: {
      type: Boolean,
      default: false,
    },

    /**
     * Cambia el aspecto a un botón outlined
     */
    outlined: {
      type: Boolean,
      default: false,
    },

    /**
     * Genera un botón pequeño
     */
    small: {
      type: Boolean,
      default: false,
    },

    /**
     * Botón con aspecto de texto
     */
    text: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
}
</script>
