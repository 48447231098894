<template>
  <div v-if="vigente()">
    <v-system-bar
      app
      color="amber"
      class="text-caption text-center py-4 text--primary"
      xxlights-out
      @click="dialogVisible = !!payload.aviso.mensaje"
    >
      <v-icon small>
        {{ svg.mdiMessage }}
      </v-icon>

      <span class="text-truncate d-inline-block">{{ payload.aviso.resumen }}</span>

      <v-btn v-if="payload.aviso.mensaje" text x-small @click="dialogVisible = true">
        Más información...
      </v-btn>
      <v-spacer />
      <v-btn icon>
        <v-icon class="mx-0" small @click.stop="ocultar">
          {{ svg.mdiClose }}
        </v-icon>
      </v-btn>
    </v-system-bar>

    <v-dialog v-model="dialogVisible" width="500">
      <v-card>
        <v-card-title>
          {{ payload.aviso.titulo }}
        </v-card-title>
        <v-card-text class="py-4 text-pre-line">
          {{ payload.aviso.mensaje }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text class="mb-2" @click="dialogVisible = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mdiClose, mdiMessage } from '@mdi/js'

export default {
  data() {
    return {
      svg: {
        mdiClose,
        mdiMessage,
      },
      dialogVisible: false,
    }
  },
  computed: {
    ...mapGetters('app', ['payload']),
  },
  methods: {
    ocultar() {
      this.$store.dispatch('app/mergePayload', {
        aviso: {
          resumen: '',
          titulo: '',
          mensaje: '',
          fecha_desde: '2020-01-01T00:00:00',
          fecha_hasta: '2020-01-01T00:00:00',
        },
      })
    },
    vigente() {
      const desde = this.payload.aviso.fecha_desde
      const hasta = this.payload.aviso.fecha_hasta
      return (
        this.payload.aviso.resumen &&
        this.$dayjs(desde).diff(this.$dayjs(), 'second') < 0 &&
        this.$dayjs(hasta).diff(this.$dayjs(), 'second') > 0
      )
    },
  },
}
</script>
