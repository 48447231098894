<template>
  <a class="btn-cu btn-color-estandar" :class="[{ 'btn-disabled': disabled }, size]" href="#">
    <icono-clave-unica
      :style="{
        fill: disabled ? '#9E9E9E' : 'white',
      }"
      class="cl-claveunica"
    />
    <span class="texto">
      <!--
      @slot Texto, por defecto "Iniciar sesión".
      -->
      <slot>Iniciar sesión</slot>
    </span>
  </a>
</template>

<script>
import IconoClaveUnica from '@/components/Icons/IconoClaveUnica'

/**
 * Botón de ClaveÚnica que sigue las especificaciones de la División Gobierno Digital.
 */
export default {
  components: { IconoClaveUnica },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    size() {
      return this.small ? 'btn-s' : this.large ? 'btn-l' : 'btn-m'
    },
  },
}
</script>

<style scoped>
/* Boton estilo de base */
.btn-cu {
  display: inline-block;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 0;
}

.btn-cu .texto {
  padding-left: 3px;
  text-decoration: underline;
}

.btn-cu.btn-color-estandar {
  color: #fff;
  background-color: #0f69c4;
}

.btn-cu.btn-color-estandar:hover {
  color: #fff;
  background-color: #0c549c;
}

.btn-cu.btn-color-estandar:active {
  color: #fff;
  background-color: #093f75;
}

.btn-cu.btn-color-estandar:focus {
  color: #fff;
  background-color: #0c549c;
  outline: 1px dashed #000;
}

/* Disabled */

.btn-cu.btn-disabled {
  color: #9e9e9e !important;
  pointer-events: none;
  cursor: default;
  background-color: #e0e0e0 !important;
}

/* Tamaño L */
.btn-cu.btn-l {
  width: 180px;
  min-width: 180px;
  max-height: 48px;
  padding: 11px 18px !important;
  font-size: 18px;
}

.btn-cu.btn-l .cl-claveunica {
  margin: 0 2px -5px 0;
  width: 24px;
}

/* Tamaño M */
.btn-cu.btn-m {
  width: 160px;
  min-width: 160px;
  max-height: 42px;
  padding: 8px 18px 8px 15px !important;
  font-size: 16px;
  line-height: 1.6em;
}

.btn-cu.btn-m .cl-claveunica {
  margin: 0 2px -5px 0;
  width: 20px;
}

/* Tamaño S */

.btn-cu.btn-s {
  width: 130px;
  min-width: 130px;
  max-height: 36px;
  padding: 8px 10px !important;
  font-size: 14px;
}

.btn-cu.btn-s .cl-claveunica {
  margin: 0 2px -3px 0;
  width: 14px;
}
</style>
