<template>
  <div>
    <v-sheet
      class="text--secondary font-weight-medium text-slab text-subtitle-2 mx-3 text-decoration-none"
      style="font-size: 0.8rem !important"
    >
      <v-row>
        <v-col>
          <barrita-gob />
          <div class="hidden-xs-only mt-n4">
            <router-link to="/" class="text-decoration-none text--secondary">
              Subsecretaría de Desarrollo Regional y Administrativo
            </router-link>
          </div>
        </v-col>
        <v-col class="text-right">
          <div
            v-if="$store.getters['app/institucion'] && $store.getters['app/institucion'].nombre"
            class="hidden-xs-only mt-2"
          >
            Municipalidad de
            <span class="text-capitalize">
              {{ $store.getters['app/institucion'].nombre.toString().toLowerCase() }}
            </span>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import BarritaGob from '@/components/Icons/BarritaGob'

export default {
  components: { BarritaGob },
}
</script>
