<template>
  <v-navigation-drawer
    v-if="$auth.loggedIn()"
    v-model="navigationDrawer"
    app
    color="fondo2"
    temporary
    width="290"
  >
    <template #prepend>
      <logo-sem-color class="mx-3 mt-3 mb-n3" style="width: 30%" />
    </template>

    <v-list dense nav style="user-select: none">
      <v-list-group v-model="listMenuUsuario" no-action>
        <template #activator>
          <v-list-item-content v-if="$auth.loggedIn()">
            <v-list-item-title class="text-h6 pb-1" :title="roles">
              {{ $auth.user().name }}
              {{ $auth.user().apellido }}
            </v-list-item-title>
            <v-list-item-subtitle class="text-caption text-uppercase">
              {{ roles }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>

        <v-divider />

        <v-subheader>ACCESIBILIDAD</v-subheader>

        <v-list-item class="pl-2">
          <v-list-item-content>
            <v-list-item-title> Tamaño de textos </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn-toggle rounded>
              <v-btn :disabled="fontSize <= 14" fab x-small @click="fontSize -= 2">
                <v-icon> mdi-format-font-size-decrease </v-icon>
              </v-btn>
              <v-btn :disabled="fontSize >= 24" fab x-small @click="fontSize += 2">
                <v-icon> mdi-format-font-size-increase </v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-list-item-action>
        </v-list-item>
        <v-list-item class="pl-2">
          <v-list-item-content>
            <v-list-item-title>
              {{ $vuetify.theme.dark ? 'Activar modo claro' : 'Activar modo oscuro' }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              icon
              @click="
                $vuetify.theme.dark = !$vuetify.theme.dark
                storeDarkModeInLocalStore()
              "
            >
              <v-icon>
                {{ $vuetify.theme.dark ? 'mdi-white-balance-sunny' : 'mdi-weather-night' }}
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider />

        <v-subheader>CONFIGURACIÓN</v-subheader>

        <v-list-item
          class="pl-2"
          to="/perfil"
          @click="
            navigationDrawer = false
            $nextTick(function () {
              listMenuUsuario = false
            })
          "
        >
          <v-list-item-content>
            <v-list-item-title>Editar mis datos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!--
        <v-list-item
          to="#0"
          @click="navigationDrawer=false; $nextTick(function () { listMenuUsuario=false} )"
        >
          <v-list-item-content>
            <v-list-item-title>Cambiar password</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        -->
        <template v-if="listaUsers.length > 1">
          <v-divider />
          <v-subheader> CAMBIAR DE PERFIL </v-subheader>
          <template v-for="user in listaUsers">
            <v-list-item
              v-if="user.id !== $auth.user().id"
              :key="user.id"
              dense
              class="pl-2"
              @click="loginAs(user.id)"
            >
              <v-list-item-icon class="mr-2">
                <v-icon> mdi-account-circle-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content
                :title="
                  user.roles
                    .map((r) => r.name)
                    .join(', ')
                    .replaceAll('-', ' ')
                "
              >
                <v-list-item-title class="text-caption text-uppercase">
                  {{
                    user.roles
                      .map((r) => r.name)
                      .join(', ')
                      .replaceAll('-', ' ')
                  }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-caption">
                  {{ user.institucion.nombre }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
      </v-list-group>
    </v-list>

    <v-list v-if="!listMenuUsuario" dense nav>
      <list-item icon="mdi-view-dashboard-outline" label="Principal" to="/dashboard" />
      <list-item
        :icon="payload.alertas_sin_leer ? 'mdi-bell-ring-outline' : 'mdi-bell-outline'"
        :badge="payload.alertas_sin_leer"
        label="Alertas"
        to="/alertas"
      />
      <list-item
        v-if="!$auth.can(['mesa-de-ayuda'])"
        icon="mdi-help-circle-outline"
        label="Mis tickets de asistencia"
        to="/tickets/mis-tickets"
      />
      <list-item
        v-if="
          $auth.can(['editar-cuentas', 'gestionar-nominas']) && $auth.user().institucion.tipo !== 3
        "
        icon="mdi-file-search-outline"
        label="Buscar cuentas"
        to="/cuentas"
      />
      <list-item
        v-if="$auth.can(['cajero'])"
        icon="mdi-currency-usd"
        label="Pagar cuentas"
        to="/pago-presencial/cuentas"
      />
      <list-item
        v-if="$auth.can(['tramitar-solicitudes'])"
        icon="mdi-currency-usd"
        label="Pago masivo de nóminas"
        to="/pago-masivo-nominas/pago-masivo-nominas"
      />

      <v-divider />

      <v-list>
        <template v-for="item in itemsMenu">
          <v-list-group
            v-if="tienePermission(item)"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
          >
            <template #activator>
              <v-list-item-content>
                <v-list-item-title :title="item.title" v-text="item.title" />
              </v-list-item-content>
            </template>

            <template v-for="child in item.items">
              <template v-if="tienePermission(child)">
                <v-divider v-if="child.divider" :key="'divider-' + child.title + child.to" />

                <v-subheader v-if="child.subheader" :key="'subheader-' + child.title + child.to">
                  {{ child.subheader }}
                </v-subheader>

                <v-list-item
                  v-if="tienePermission(child)"
                  :key="'item-' + child.title + child.to"
                  :to="child.to"
                  link
                >
                  <v-list-item-content>
                    <v-list-item-title :title="child.title" v-text="child.title" />
                  </v-list-item-content>
                </v-list-item>
              </template>
            </template>
          </v-list-group>
        </template>
      </v-list>
    </v-list>
    <template #append>
      <div class="pa-2">
        <v-btn block color="secondary" @click="logout"> Cerrar sesión </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ListItem from '@/admin/components/App/TheMenuPrincipalListItem'
import LogoSemColor from '@/components/Icons/LogoSemColor'
import { getError } from '@/utils/errors'

export default {
  components: { LogoSemColor, ListItem },
  props: {},
  data: () => ({
    fontSize: 16,
    listMenuUsuario: false,
  }),
  computed: {
    ...mapGetters('app', ['menuPrincipalVisible', 'payload']),
    navigationDrawer: {
      get: function () {
        return this.menuPrincipalVisible
      },
      set: function (value) {
        this.setMenuPrincipalVisible(value)
      },
    },
    itemsMenu() {
      return [
        {
          title: 'Avisos globales',
          action: 'mdi-message-outline',
          permissions: ['operador'],
          items: [
            { title: 'Aviso interno', to: '/alertas/aviso/1' },
            { title: 'Aviso público', to: '/alertas/aviso/2' },
          ],
        },
        {
          title: 'Administración general',
          action: 'mdi-cogs',
          permissions: ['operador'],
          items: [
            { title: 'Feriados', to: '/admin/feriados' },
            { title: 'Firmantes', to: '/admin/firmantes' },
            { title: 'Validador de firma', to: '/admin/validador-firma' },
            { title: 'Instituciones', to: '/admin/instituciones' },
            { title: 'Usuarios', to: '/admin/users' },
          ],
        },
        {
          title: 'Indicadores',
          action: 'mdi-currency-usd',
          permissions: ['operador'],
          items: [
            { title: 'Cargar depósitos', to: '/indicadores-economicos/cargar-depositos' },
            { title: 'IPC de segundas cuotas', to: '/indicadores-economicos/factor2c' },
            { title: 'Reajustes', to: '/indicadores-economicos/reajustes' },
            { title: 'Utm', to: '/indicadores-economicos/utm' },
          ],
        },
        {
          title: 'Configuración',
          action: 'mdi-cog-outline',
          permissions: ['administrador'],
          items: [
            { title: 'Institución', to: '/institucion/editar' },
            { title: 'Usuarios', to: '/admin/users' },
            { title: 'Personalizar logo', to: '/institucion/logo' },
            // { title: 'Firmantes', to: '/admin/firmantes' },
          ],
        },
        {
          title: 'Solicitudes',
          action: 'mdi-car',
          permissions: ['tramitar-solicitudes'],
          items: [{ title: 'Seguimiento', to: '/solicitud/seguimiento' }],
        },

        {
          title: 'Tickets de asistencia',
          action: 'mdi-help-circle-outline',
          permissions: ['soporte', 'mesa-de-ayuda'],
          items: [{ title: 'Tickets', to: '/tickets' }],
        },

        {
          title: 'Trámites',
          action: 'mdi-bank',
          permissions: ['operador', 'administrador', 'editar-cuentas', 'gestionar-nominas'],
          items: [
            {
              title: 'Habilitación de instituciones',
              to: '/admin/habilitaciones',
              permissions: ['operador'],
            },
            { title: 'Configurar trámite', to: '/habilitacion', permissions: ['administrador'] },
            { title: 'Personalizar PDF', to: '/habilitacion/pdf', permissions: ['administrador'] },
            {
              title: 'Configurar número de serie',
              to: '/secuencia',
              permissions: ['administrador'],
            },
            { title: 'Enlazar al portal de pagos', to: '/enlazar', permissions: ['administrador'] },
            { title: 'Cuentas', to: '/cuentas', tipoInstitucion: [1, 2] },
            { title: 'Pago presencial', to: '/pago-presencial/cuentas', permissions: ['cajero'] },
          ],
        },
        {
          title: 'Nóminas',
          action: 'mdi-format-list-bulleted',
          permissions: ['administrador', 'editar-cuentas', 'gestionar-nominas'],
          items: [
            {
              title: 'Cargar',
              to: '/nomina',
              permissions: ['gestionar-nominas'],
              tipoInstitucion: [2, 3],
            },
            {
              title: 'Cargar cuentas pagadas',
              to: '/nomina/cargar-cuentas-pagadas?cuentasPagadas=true',
              permissions: ['gestionar-nominas'],
              tipoInstitucion: [2, 3],
            },
            { title: 'Nóminas', to: '/nominas' },
          ],
        },
        {
          title: 'Infracciones',
          action: 'mdi-hand-back-left-outline',
          tipoInstitucion: [3],
          permissions: [
            'administrador',
            'editar-cuentas',
            'gestionar-nominas',
            'confirmar-infraccion-jpl',
          ],
          items: [
            { title: 'Seguimiento', to: '/infracciones/seguimiento' },
            {
              title: 'Agregar',
              to: '/infracciones/crear',
              permissions: ['administrador', 'editar-cuentas', 'gestionar-nominas'],
            },
          ],
        },
        {
          title: 'Base Nacional PCIRC',
          action: 'mdi-car-multiple',
          tipoInstitucion: [1, 2],
          permissions: ['tramitar-solicitudes', 'operador', 'administrador'],
          items: [
            {
              title: 'Cargar',
              to: '/bum/cargar',
              permissions: ['tramitar-solicitudes', 'administrador'],
            },
            {
              title: 'Buscar',
              to: '/bum/buscar-vehiculo',
              permissions: ['tramitar-solicitudes', 'operador', 'administrador'],
            },
            {
              title: 'Nóminas',
              to: '/bum/bum-nominas',
              permissions: ['tramitar-solicitudes', 'administrador'],
            },
            {
              title: 'Reporte detalle municipal',
              to: '/bum/reporte-permisos',
              permissions: ['operador'],
            },
            {
              title: 'Reporte resumen general',
              to: '/bum/reporte-resumen-general',
              permissions: ['operador'],
            },
          ],
        },
        {
          title: 'Reportes',
          action: 'mdi-finance',
          permissions: ['reportes', 'operador', 'administrador'],
          items: [
            { title: 'Simulación de monto a pagar', to: '/reportes/simular-monto-a-pagar' },
            { title: 'Últimos pagos por día', to: '/reportes/ultimos-pagos-por-dia' },
            { title: 'Seguimiento de pagos', to: '/pagos' },
            { title: 'Cuentas pagadas del día', to: '/reportes/pagos-del-dia' },
            { title: 'Cuentas pagadas', to: '/reportes/cuentas-pagadas' },
            {
              title: 'Resumen de cuentas y pagos',
              to: '/reportes/resumen-cuentas-pagos',
              tipoInstitucion: [1],
            },
            { title: 'Volumen de transacciones', to: '/reportes/volumen-transacciones' },
            { title: 'Depósitos pagados', to: '/reportes/depositos-pagados' },
          ],
        },
        {
          title: 'Doc. municipal',
          action: 'mdi-frequently-asked-questions',
          permissions: ['documentador'],
          items: [
            { title: 'Grupos', to: '/documentacion/1/grupos' },
            { title: 'Temas', to: '/documentacion/1/temas' },
          ],
        },
        {
          title: 'Doc. ciudadano',
          action: 'mdi-frequently-asked-questions',
          permissions: ['documentador'],
          items: [
            { title: 'Grupos', to: '/documentacion/2/grupos' },
            { title: 'Temas', to: '/documentacion/2/temas' },
          ],
        },
        {
          title: 'Doc. capacitación',
          action: 'mdi-frequently-asked-questions',
          permissions: ['documentador'],
          items: [
            { title: 'Grupos', to: '/documentacion/3/grupos' },
            { title: 'Temas', to: '/documentacion/3/temas' },
          ],
        },
        {
          title: 'Capacitación',
          action: 'mdi-human-male-board',
          permissions: this.$payload.gruposCapacitacion()?.length > 0 ? undefined : ['nadie'],
          items: this.$payload
            .gruposCapacitacion()
            .map((g) => ({ title: g.nombre, to: `/capacitacion/${g.id}` })),
        },
        {
          title: 'Actividad',
          action: 'mdi-history',
          permissions: ['operador', 'administrador'],
          items: [{ title: 'Actividad de usuarios', to: '/logs/user' }],
        },
        {
          title: 'Herramientas para desarrolladores',
          action: 'mdi-dev-to',
          permissions: ['operador'],
          items: [
            { title: 'Registros', to: '/logs/model' },
            { title: 'Logs generales', to: '/admin/logs' },
            { title: 'Colas', to: '/admin/queues' },
          ],
        },
      ]
    },
    listaUsers() {
      return this.payloadListaUsers.filter((u) => u.institucion.tipo !== 4) || []
    },
    roles() {
      return this.$auth
        .user()
        .roles.map((r) => r.name)
        .join(', ')
        .replaceAll('-', ' ')
    },
  },
  watch: {
    fontSize: function (fontSize) {
      fontSize = Math.min(Math.max(14, parseInt(fontSize)), 24)
      document.documentElement.style.setProperty('--font-size', fontSize + 'px')
      this.fontSize = localStorage.fontSize = fontSize
    },
  },
  beforeMount() {
    this.$vuetify.theme.dark = localStorage.dark === 'true'
    this.fontSize = parseInt(localStorage.fontSize) || this.fontSize
  },
  methods: {
    ...mapActions('app', ['setMenuPrincipalVisible']),
    logout() {
      this.$store.dispatch('auth/logout')
    },
    loginAs(id) {
      console.log('loginAs', id)
      this.$store
        .dispatch('auth/login', { id })
        .then(() => {
          if (this.$route.path !== '/dashboard') {
            this.$router.push('/dashboard')
          }
        })
        .catch((error) => {
          console.log(getError(error))
          this.logout()
        })
        .finally(() => {
          this.listMenuUsuario = false
        })
    },

    tienePermission(item) {
      return (
        (!item.permissions || this.$auth.can(item.permissions)) &&
        (!item.tipoInstitucion || item.tipoInstitucion.includes(this.$auth.user().institucion.tipo))
      )
    },

    storeDarkModeInLocalStore() {
      localStorage.dark = this.$vuetify.theme.dark
    },
  },
}
</script>

<style lang="scss">
/** Quita indentación de menú del usuario */
.v-navigation-drawer__prepend .v-list-group__items > .v-list-item {
  padding-left: 8px !important;
}

/** Tamaño global de fuente */
:root {
  --font-size: 16px;
}

html {
  font-size: var(--font-size);
}
</style>
