<template>
  <v-simple-table v-bind="$props" :dense="dense">
    <thead v-if="head">
      <tr>
        <th>Campo</th>
        <th>Valor</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(value, campo) in attributes">
        <tr
          v-if="
            mostrarTodo ||
            (typeof getOldValue(campo) !== 'undefined' && value !== getOldValue(campo))
          "
          :key="campo"
        >
          <th style="max-width: 130px">
            {{ formatCampo(campo) }}
          </th>
          <td v-if="typeof value === 'object' && JSON.stringify(value) !== '{}'">
            <tabla-campos-editados
              :attributes="{ ...value }"
              :old="{ ...getOldValue(campo) }"
              dense
              :mostrar-todo="mostrarTodo"
            />
          </td>
          <td v-else>
            {{ formatValue(campo, value, model) }}
            <div
              v-if="value !== getOldValue(campo)"
              class="text--disabled text-caption text-decoration-line-through"
            >
              {{ formatValue(campo, getOldValue(campo), model) }}
            </div>
          </td>
        </tr>
      </template>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: 'TablaCamposEditados',
  components: {},
  props: {
    /**
     * Valores actuales
     */
    attributes: {
      type: Object,
      required: true,
    },
    /**
     * Valores antiguos modificados
     */
    old: {
      type: [Object, undefined],
      default: () => ({}),
    },
    model: {
      type: String,
      default: '',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '',
    },
    fixedHeader: {
      type: Boolean,
      default: false,
    },
    head: {
      type: Boolean,
      default: false,
    },
    mostrarTodo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  methods: {
    valueVacio(valor) {
      return valor === null || valor === '' || JSON.stringify(valor) === '[]'
    },
    formatCampo(campo) {
      return (
        {
          created_at: 'Fecha de creación',
          name: 'Nombre',
          updated_at: 'Fecha de edición',
        }[campo] || this.$formato.capitalizar(campo.replaceAll('_', ' '))
      )
    },
    formatValue(campo, valor, model) {
      if (valor === null || typeof valor === 'undefined') {
        return ''
      }
      if (typeof valor === 'boolean') {
        return valor ? 'Si' : 'No'
      }

      if (model && campo === 'estado' && typeof valor === 'number') {
        if (model === 'cuenta') {
          return this.$payload.estadoCuenta(valor) + ` (${valor})`
        }
        if (model === 'nomina') {
          return this.$payload.estadoNomina(valor) + ` (${valor})`
        }
        if (model === 'pago') {
          return this.$payload.estadoPago(valor) + ` (${valor})`
        }
      }

      valor = valor.toString()
      if (campo.indexOf('password') !== -1) {
        return '********'
      }
      if (campo.indexOf('fecha') !== -1 || valor.match(/^\d{4}-\d{2}-\d{2}$/g)) {
        return this.$dayjs(valor).format('LL')
      }
      if (campo.indexOf('fecha') !== -1 || valor.match(/^\d{4}-\d{2}-\d{2}T.*Z$/g)) {
        return this.$dayjs(valor).format('LLLL')
      }
      return valor
    },
    getOldValue(campo) {
      return this.old ? this.old[campo] : undefined
    },
  },
}
</script>
