import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/admin/store/index'
import auth from '@/admin/middleware/auth'
import guest from '@/admin/middleware/guest'
import middlewarePipeline from './middlewarePipeline'
import Dashboard from '../views/Dashboard.vue'
import Alertas from '@/admin/views/Alertas'
import Login from '@/admin/views/Login/Login'
import Form from '@/admin/views/Login/Form'
import Perfil from '@/admin/views/Perfil'
import Habilitaciones from '@/admin/views/Admin/Habilitaciones'
import NotFound from '@/admin/views/NotFound'
import AdminInstituciones from '@/admin/views/Admin/Instituciones'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    meta: { middleware: [guest] },
    component: Login,
  },
  {
    path: '/login/form',
    meta: { middleware: [guest] },
    component: Form,
  },
  {
    path: '/auth/login-clave-unica',
    meta: { middleware: [guest] },
    component: () => import('../views/Auth/LoginClaveUnica.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      middleware: [auth],
    },
    component: Dashboard,
  },
  {
    path: '/perfil',
    meta: {
      titulo: 'Mis datos',
      middleware: [auth],
    },
    component: Perfil,
  },
  {
    path: '/alertas',
    meta: {
      titulo: 'Alertas',
      middleware: [auth],
    },
    component: Alertas,
  },
  {
    path: '/admin/instituciones',
    meta: {
      titulo: 'Instituciones',
      middleware: [auth],
      permissions: ['operador'],
    },
    component: AdminInstituciones,
  },
  {
    path: '/admin/habilitaciones',
    meta: {
      titulo: 'Instituciones habilitadas para pago',
      middleware: [auth],
      permissions: ['operador'],
    },
    component: Habilitaciones,
  },
  {
    path: '/admin/users',
    meta: {
      titulo: 'Usuarios',
      middleware: [auth],
      permissions: ['operador', 'administrador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Users'),
  },
  {
    path: '/admin/firmantes',
    meta: {
      titulo: 'Firmantes',
      middleware: [auth],
      permissions: ['operador', 'administrador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Firmantes'),
  },
  {
    path: '/admin/validador-firma',
    meta: {
      titulo: 'Validador de firma',
      middleware: [auth],
      permissions: ['operador', 'administrador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/ValidadorFirma'),
  },
  {
    path: '/tickets',
    meta: {
      titulo: 'Tickets de asistencia',
      middleware: [auth],
      permissions: ['soporte', 'mesa-de-ayuda'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Tickets/Index'),
  },
  {
    path: '/tickets/crear',
    meta: {
      titulo: 'Tickets de asistencia',
      middleware: [auth],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Tickets/Crear'),
  },
  {
    path: '/tickets/mis-tickets',
    meta: {
      titulo: 'Mis tickets de asistencia',
      middleware: [auth],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Tickets/MisTickets'),
  },

  {
    path: '/documentacion/:seccion/grupos',
    meta: {
      titulo: 'Grupos',
      middleware: [auth],
      permissions: ['documentador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Documentacion/Grupos'),
  },
  {
    path: '/documentacion/:seccion/temas/:grupo?',
    meta: {
      titulo: 'Temas',
      middleware: [auth],
      permissions: ['documentador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Documentacion/Temas'),
  },

  {
    path: '/indicadores-economicos/utm',
    meta: {
      titulo: 'UTM',
      permissions: ['operador'],
      middleware: [auth],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/IndicadoresEconomicos/Utm'),
  },
  {
    path: '/indicadores-economicos/factor2c',
    meta: {
      titulo: 'IPC de segundas cuotas',
      permissions: ['operador'],
      middleware: [auth],
    },
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/IndicadoresEconomicos/Factor2c'),
  },
  {
    path: '/indicadores-economicos/reajustes',
    meta: {
      titulo: 'Reajustes',
      middleware: [auth],
      permissions: ['operador'],
    },
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/IndicadoresEconomicos/Reajustes'),
  },
  {
    path: '/admin/feriados',
    meta: {
      titulo: 'Feriados',
      middleware: [auth],
      permissions: ['operador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Feriados'),
  },
  {
    path: '/admin/logs',
    meta: {
      titulo: 'Logs generales',
      middleware: [auth],
      permissions: ['operador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Logs'),
  },
  {
    path: '/admin/queues',
    meta: {
      titulo: 'Colas',
      middleware: [auth],
      permissions: ['operador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Queues'),
  },
  {
    path: '/logs/user',
    meta: {
      titulo: 'Actividad de usuarios',
      middleware: [auth],
      permissions: ['operador', 'administrador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Logs/User'),
  },
  {
    path: '/logs/model',
    meta: {
      titulo: 'Registros',
      middleware: [auth],
      permissions: ['operador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Logs/Model'),
  },
  {
    path: '/institucion/editar',
    meta: {
      titulo: 'Institución',
      middleware: [auth],
      permissions: ['administrador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Institucion/Editar'),
  },
  {
    path: '/institucion/logs',
    meta: {
      titulo: 'Logs',
      middleware: [auth],
      permissions: ['administrador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/LogsInstitucion'),
  },
  {
    path: '/institucion/logo',
    meta: {
      titulo: 'Personalizar logo',
      middleware: [auth],
      permissions: ['administrador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Institucion/Logo'),
  },

  {
    path: '/habilitacion/pdf',
    meta: {
      titulo: 'Personalizar PDF',
      middleware: [auth],
      permissions: ['administrador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Habilitacion/Pdf'),
  },

  {
    path: '/habilitacion',
    meta: {
      titulo: 'Configurar trámite',
      middleware: [auth],
      permissions: ['administrador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Habilitacion'),
  },

  {
    path: '/enlazar',
    meta: {
      titulo: 'Enlazar al portal de pagos',
      middleware: [auth],
      permissions: ['administrador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Enlazar'),
  },

  {
    path: '/secuencia',
    meta: {
      titulo: 'Configurar número de serie',
      middleware: [auth],
      permissions: ['administrador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Secuencia'),
  },

  {
    path: '/nomina',
    meta: {
      titulo: 'Cargar nómina de pago',
      middleware: [auth],
      permissions: ['gestionar-nominas'],
    },
    component: () => import(/* webpackChunkName: "nomina" */ '../views/Nomina/Cargar'),
  },
  {
    path: '/nomina/cargar-cuentas-pagadas',
    meta: {
      titulo: 'Cargar nómina de cuentas pagadas',
      middleware: [auth],
      permissions: ['gestionar-nominas'],
    },
    component: () =>
      import(/* webpackChunkName: "nomina/cargar-cuentas-pagadas" */ '../views/Nomina/Cargar'),
  },
  {
    path: '/nominas',
    meta: {
      titulo: 'Nóminas',
      middleware: [auth],
      permissions: ['operador', 'administrador', 'gestionar-nominas'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Nomina/Nominas'),
  },
  {
    path: '/bum/cargar',
    meta: {
      titulo: 'Cargar pagos Base Nacional PCIRC',
      middleware: [auth],
      permissions: ['tramitar-solicitudes', 'administrador'],
    },
    component: () => import(/* webpackChunkName: "nomina" */ '../views/Bum/Cargar'),
  },
  {
    path: '/bum/buscar-vehiculo',
    meta: {
      titulo: 'Buscar vehículo',
      middleware: [auth],
      permissions: ['tramitar-solicitudes', 'operador', 'administrador'],
    },
    component: () => import(/* webpackChunkName: "nomina" */ '../views/Bum/BuscarVehiculo'),
  },
  {
    path: '/bum/bum-nominas',
    meta: {
      titulo: 'Nóminas',
      middleware: [auth],
      permissions: ['tramitar-solicitudes', 'administrador'],
    },
    component: () => import(/* webpackChunkName: "nomina" */ '../views/Bum/BumNominas'),
  },
  {
    path: '/bum/reporte-permisos',
    meta: {
      titulo: 'Reporte de permisos',
      middleware: [auth],
      permissions: ['operador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Bum/Reportes/ReportePermisos'),
  },
  {
    path: '/bum/reporte-resumen-general',
    meta: {
      titulo: 'Reporte resumen general',
      middleware: [auth],
      permissions: ['operador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Bum/Reportes/ReporteResumenGeneral'),
  },
  {
    path: '/cuentas',
    meta: {
      titulo: 'Cuentas',
      middleware: [auth],
      permissions: ['operador', 'administrador', 'editar-cuentas', 'gestionar-nominas'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Cuentas/Cuentas'),
  },
  {
    path: '/pago-presencial/cuentas',
    meta: {
      titulo: 'Pago en caja municipal',
      middleware: [auth],
      permissions: ['cajero'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/PagoPresencial/Cuentas'),
  },
  {
    path: '/pago-masivo-nominas/pago-masivo-nominas',
    meta: {
      titulo: 'Pago masivo nóminas',
      middleware: [auth],
      permissions: ['tramitar-solicitudes'],
    },
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/PagoMasivoNominas/PagoMasivoNominas'),
  },
  {
    path: '/alertas/aviso/:id',
    meta: {
      titulo: 'Aviso global',
      middleware: [auth],
      permissions: ['operador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Alertas/Aviso'),
  },

  /**
   * Infracciones
   */
  {
    path: '/infracciones/seguimiento',
    meta: {
      titulo: 'Seguimiento de infracciones',
      middleware: [auth],
      permissions: [
        'administrador',
        'editar-cuentas',
        'gestionar-nominas',
        'confirmar-infraccion-jpl',
      ],
      tipoInstitucion: 3,
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Infracciones/Seguimiento'),
  },
  {
    path: '/infracciones/crear',
    meta: {
      titulo: 'Crear infracción',
      middleware: [auth],
      permissions: ['administrador', 'editar-cuentas', 'gestionar-nominas'],
      tipoInstitucion: 3,
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Infracciones/Crear'),
  },

  /**
   * Reportes
   */
  {
    path: '/reportes/simular-monto-a-pagar',
    meta: {
      titulo: 'Simulación de monto a pagar',
      middleware: [auth],
      permissions: ['reportes', 'operador', 'administrador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Reportes/SimularMontoAPagar'),
  },
  {
    path: '/reportes/ultimos-pagos-por-dia',
    meta: {
      titulo: 'Últimos pagos por día',
      middleware: [auth],
      permissions: ['reportes', 'operador', 'administrador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Reportes/UltimosPagosPorDia'),
  },
  {
    path: '/pagos',
    meta: {
      titulo: 'Seguimiento de pagos',
      middleware: [auth],
      permissions: ['operador', 'administrador', 'editar-cuentas', 'gestionar-nominas', 'reportes'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Pagos'),
  },
  {
    path: '/reportes/pagos-del-dia/:fecha?',
    meta: {
      titulo: 'Cuentas pagadas del día',
      middleware: [auth],
      permissions: ['reportes', 'operador', 'administrador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Reportes/PagosDelDia'),
  },
  {
    path: '/reportes/cuentas-pagadas',
    meta: {
      titulo: 'Cuentas pagadas',
      middleware: [auth],
      permissions: ['reportes', 'operador', 'administrador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Reportes/CuentasPagadas'),
  },

  {
    path: '/reportes/depositos-pagados',
    meta: {
      titulo: 'Depositos pagados',
      middleware: [auth],
      permissions: ['reportes', 'operador', 'administrador'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Reportes/Deposito'),
  },

  {
    path: '/reportes/resumen-cuentas-pagos',
    meta: {
      titulo: 'Resumen cuentas pagos',
      middleware: [auth],
      permissions: ['reportes', 'operador', 'administrador'],
    },
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/Reportes/ResumenCuentasPagos'),
  },

  {
    path: '/reportes/volumen-transacciones',
    meta: {
      titulo: 'Volumen de transacciones',
      middleware: [auth],
      permissions: ['reportes', 'operador', 'administrador'],
    },
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/Reportes/VolumenTransacciones'),
  },

  {
    path: '/indicadores-economicos/cargar-depositos',
    meta: {
      titulo: 'Cargar depósitos',
      middleware: [auth],
      permissions: ['operador'],
    },
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/IndicadoresEconomicos/CargarDeposito'),
  },

  {
    path: '/solicitud/seguimiento',
    meta: {
      titulo: 'Seguimiento de solicitud',
      middleware: [auth],
      permissions: ['tramitar-solicitudes'],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Solicitud/Seguimiento'),
  },

  {
    path: '/ayuda/:grupoId?/:temaId?',
    name: 'ayuda',
    meta: {
      titulo: 'Preguntas frecuentes',
      middleware: [auth],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Ayuda'),
  },

  {
    path: '/capacitacion/:grupo_id',
    meta: {
      titulo: 'Capacitación',
      middleware: [auth],
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Capacitacion'),
  },

  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

/**
 * Título de páginas
 */
router.beforeEach((to, from, next) => {
  const titulo = to.meta.titulo ?? 'Portal de Servicios Municipales'
  store.dispatch('app/setTitulo', titulo)
  return next()
})

/**
 * Middlewares
 */
router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware
  const context = { to, from, next, store }

  if (!middleware) {
    return next()
  }

  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  })
})

export default router
