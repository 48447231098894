<template>
  <div>
    <v-simple-table>
      <tbody>
        <tr>
          <th style="width: 160px">RUT</th>
          <td>{{ $formato.rut(item.rut) }}</td>
        </tr>
        <tr>
          <th>Dirección</th>
          <td>{{ item.direccion }}</td>
        </tr>
        <tr>
          <th>Correo electrónico</th>
          <td>
            <a :href="`mailto:${item.email}`">{{ item.email }}</a>
          </td>
        </tr>
        <tr v-if="!!item.telefono">
          <th>Teléfono</th>
          <td>{{ item.telefono }}</td>
        </tr>
        <tr>
          <th>Código SUBDERE</th>
          <td>{{ item.codigo_subdere }}</td>
        </tr>
        <tr>
          <th>Código TGR</th>
          <td>{{ item.codigo_tgr }}</td>
        </tr>
        <tr>
          <th>Activo?</th>
          <td>{{ item.activo == true ? 'Activo' : 'Deshabilitado' }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
}
</script>
