import Vue from 'vue'
import municipalidades from '@/plugins/municipalidades'
import regiones from '@/plugins/regiones'
import estadosInfraccion from '@/plugins/estadosInfraccion'
import estadosPagoMasivo from '@/plugins/estadosPagoMasivo'
import categoriasOirs from '@/plugins/categoriasOirs'
import tramitesOirs from '@/plugins/tramitesOirs'
import tipoPagoExterno from '@/plugins/tipoPagoExterno'
import tipoPago from '@/plugins/tipoPago'

export const payload = {
  municipalidad(value, emptyValue = {}) {
    return municipalidades[value] || emptyValue
  },
  municipalidades() {
    return municipalidades
  },
  region(value, emptyValue = '') {
    return regiones[value] || emptyValue
  },

  regiones() {
    return regiones
  },
  estadosInfraccion() {
    return estadosInfraccion
  },
  estadosPagoMasivo() {
    return estadosPagoMasivo
  },
  tipoPagoExterno() {
    return tipoPagoExterno
  },
  tipoPago() {
    return tipoPago
  },
}

export const mixinPayload = {
  computed: {
    payloadListaMunicipalidades() {
      return Object.values(municipalidades).reduce((obj, item) => {
        return { ...obj, [item.rut]: item.nombre }
      }, {})
    },
    payloadItemsMunicipalidades() {
      return Object.values(municipalidades)
        .sort((a, b) => a.nombre.localeCompare(b.nombre))
        .map((m) => ({ value: m.rut, text: m.nombre }))
    },
    payloadListaEstadosInfraccion() {
      return Object.entries(estadosInfraccion).map(([value, text]) => ({
        value: parseInt(value),
        text: text,
      }))
    },

    $itemsCategoriasOirs() {
      return categoriasOirs.map((m) => ({ value: m.id, text: m.nombre }))
    },

    $itemsTramitesOirs() {
      return tramitesOirs.map((m) => ({ value: m.id, text: m.nombre }))
    },

    payloadListatipoPagoExterno() {
      return Object.entries(tipoPagoExterno).map(([value, text]) => ({
        value: parseInt(value),
        text: text,
      }))
    },

    $itemsTipoPago() {
      return Object.entries(tipoPago).map(([value, text]) => ({
        value: value,
        text: text,
      }))
    },
  },
}

Vue.use({
  install(app) {
    app.prototype.$payloadStatic = payload
    app.mixin(mixinPayload)
  },
})

export default payload
