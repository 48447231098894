import * as API from '@/admin/services/API'

function addToPathname(url, extra = null) {
  if (extra === null) {
    return url
  }

  // Ejemplo: http://api/users/{id}/photo
  if (url.includes('{id}')) {
    return url.replace('{id}', extra)
  }

  return url.indexOf('?') === -1 ? `${url}/${extra}` : url.replace('?', `/${extra}?`)
}

export default {
  async get(url, payload, addIdToPathname = true) {
    url = addIdToPathname ? addToPathname(url, payload?.id) : url
    return API.apiClient.get(url)
  },
  async store(url, payload) {
    return API.apiClient.post(url, payload)
  },
  async patch(url, payload, addIdToPathname = true) {
    url = addIdToPathname ? addToPathname(url, payload?.id) : url
    return API.apiClient.patch(url, payload)
  },
  async post(url, payload) {
    return API.apiClient.post(url, payload)
  },
  async delete(url, payload, addIdToPathname = true) {
    url = addIdToPathname ? addToPathname(url, payload?.id) : url
    return API.apiClient.delete(url)
  },
}
