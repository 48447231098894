<template>
  <v-select
    v-model="local"
    v-bind="$attrs"
    :items="items"
    :label="label"
    :dense="dense"
    outlined
    :hint="hint"
    v-on="$listeners"
  />
</template>

<script>
/**
 * Campo para selección de (slug de) trámite.
 * Almacenará en el state el último trámite seleccionado.
 *
 */
export default {
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    campo: {
      type: String,
      default: 'slug',
    },
    label: {
      type: String,
      default: 'Trámite',
    },
    tipoInstitucion: {
      type: Number,
      default: 0,
    },
    hint: {
      type: String,
    },
    /**
     * Función de filtrado de trámites a listar.
     * Por ejemplo para evitar incluir el trámite: t.slug = 'pagos-de-prueba'
     */
    filter: {
      type: [Function, Boolean],
      default: false,
    },
  },
  computed: {
    local: {
      get: function () {
        if (!this.value) {
          const tramite = this.$store?.getters['app/tramite'] ?? ''
          this.$emit('input', tramite)
          return tramite
        }
        return this.value
      },
      set: function (value) {
        this.$store.dispatch('app/setTramite', value ?? '')
        this.$emit('input', value)
      },
    },
    items() {
      let tramites = this.$store.getters['app/payload'].tramites
      if (!tramites) {
        return []
      }

      if (typeof this.filter === 'function') {
        tramites = tramites.filter(this.filter)
      }

      // Filtra los trámites de acuerdo al tipo de institución actual
      // Instituciones de tipo 1 (operador) pueden ver todas
      const tipoInstitucion = this.tipoInstitucion || this.$auth?.institucion()?.tipo
      if (tipoInstitucion && tipoInstitucion !== 1) {
        tramites = tramites.filter((t) => {
          return t.tipos_institucion.includes(tipoInstitucion)
        })
      }
      return tramites.map((t) => {
        return { text: t.nombre, value: t[this.campo] }
      })
    },
  },
}
</script>
