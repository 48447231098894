export default [
  {
    id: 8,
    nombre: 'Derechos de aseo',
  },
  {
    id: 11,
    nombre: 'Infracciones',
  },
  {
    id: 2,
    nombre: 'Patentes municipales',
  },
  {
    id: 3,
    nombre: 'Permisos de circulación',
  },
  {
    id: 4,
    nombre: 'Permisos de circulación - segunda cuota',
  },
  {
    id: 7,
    nombre: 'Permisos provisorios',
  },
  {
    id: 1,
    nombre: 'Otros',
  },
]
