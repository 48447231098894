<template>
  <v-list-item :to="to" link>
    <v-list-item-icon v-if="icon">
      <v-badge v-if="badge" :content="badge" :value="badge" color="terciario" overlap dot>
        <v-icon>
          {{ icon }}
        </v-icon>
      </v-badge>
      <v-icon v-else>
        {{ icon }}
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        {{ label }}
        <slot />
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    badge: {
      type: [Number, String],
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      required: false,
    },
  },
}
</script>
