<template>
  <z-dialog
    :campo-titulo="() => titulo"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :value="{}"
    width="700"
    @cerrar="$emit('cerrar')"
  >
    <historial-edicion
      :query="{
        filter: {
          model_id: id,
          microservicio: microservicio,
          clase: clase,
          tipo: 'model',
          tramite_id: tramiteId,
        },
        sort: {
          0: { field: 'fecha', direction: 'desc' },
        },
      }"
      dense
      table-dense
    />
  </z-dialog>
</template>

<script>
import HistorialEdicion from '@/admin/components/HistorialEdicion'
import ZDialog from '@/components/ZDialog'

/**
 * Ventana de diálogo que muestra el componente `historial-edicion`,
 * el cual muestra un timeline con el
 * historial de modificaciones del registro.
 */
export default {
  components: { ZDialog, HistorialEdicion },
  props: {
    /**
     * ID del registro a consultar.
     * Ej: `1`.
     */
    id: {
      type: [String, Number],
      required: true,
    },
    /**
     * Microservicio.
     */
    microservicio: {
      type: String,
      default: null,
    },
    /**
     * tramite_id. Permite evitar conflicto de ID, por ejemplo para cuentas
     */
    tramiteId: {
      type: String,
      default: null,
    },

    /**
     * Model del registro a consultar.
     * Ej: `user`.
     */
    clase: {
      type: String,
      required: true,
    },

    /**
     * Título de la ventana
     */
    titulo: {
      type: String,
      default: 'Historial',
    },
  },
}
</script>
