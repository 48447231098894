<template>
  <v-app-bar
    :collapse="false"
    :collapse-on-scroll="false"
    :color="$vuetify.theme.dark ? 'secondary' : 'primary'"
    :hide-on-scroll="false"
    :scroll-off-screen="false"
    absolute
    app
    clipped-left
    class="pr-2"
    dark
    elevation="1"
    style="
      background: linear-gradient(
        45deg,
        rgba(27, 133, 238, 0.4) 0%,
        rgba(27, 133, 238, 0.3) 15%,
        rgba(27, 133, 238, 0.1) 100%
      );
    "
  >
    <v-app-bar-nav-icon @click="setMenuPrincipalVisible(!menuPrincipalVisible)" />
    <logo-gob-monocromo class="mx-2 hidden-xs-only" color="white" />

    <v-toolbar-title class="ext-overline ml-4">
      {{ titulo }}
    </v-toolbar-title>

    <v-spacer />

    <div
      v-if="!$auth.personificando() && $auth.institucion().nombre"
      style="opacity: 0.8"
      class="text-overline"
    >
      {{ $auth.institucion().nombre }}
    </div>

    <the-app-bar-personificar />

    <v-btn
      v-if="payload.alertas_sin_leer > 0"
      icon
      to="/alertas"
      :title="`Tienes ${payload.alertas_sin_leer} alertas por leer`"
    >
      <v-badge
        :content="payload.alertas_sin_leer > 99 ? '99+' : payload.alertas_sin_leer"
        :value="payload.alertas_sin_leer"
        color="terciario"
        overlap
      >
        <v-icon v-html="svg.mdiBellRingOutline" />
      </v-badge>
    </v-btn>
  </v-app-bar>
</template>

<script>
import LogoGobMonocromo from '@/components/Icons/LogoGobMonocromo'
import { mapActions, mapGetters } from 'vuex'
import { mdiBellRingOutline } from '@mdi/js'
import TheAppBarPersonificar from '@/admin/components/App/TheAppBarPersonificar'

export default {
  components: { TheAppBarPersonificar, LogoGobMonocromo },
  props: {},
  data: () => ({
    navigationDrawer: false,
    listMenuUsuario: false,
    svg: {
      mdiBellRingOutline,
    },
  }),
  computed: {
    ...mapGetters('app', ['menuPrincipalVisible', 'titulo', 'payload']),
  },
  methods: {
    ...mapActions('app', ['setMenuPrincipalVisible']),
  },
}
</script>
