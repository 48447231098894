import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import router from './router'
import '../plugins/svg'
import '../plugins/tramites'
import '../plugins/payloadStatic'
import './plugins/auth'
import './plugins/axios'
import './plugins/formato'
import './plugins/payload'
import '@/plugins/dayjs'
import store from './store'
import './plugins/util'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'

Vue.config.productionTip = process.env.NODE_ENV === 'production'

// If running inside Cypress...
// Ensure tests fail when Vue emits an error.
// if (process.env.VUE_APP_TEST === 'e2e') {
//   Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
// }

const app = new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi',
})

// Disponibiliza Vue para testing en Cypress
window.app = app
