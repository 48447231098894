<template>
  <div>
    <h2 class="text-h3 mx-auto" style="max-width: 500px">
      {{ $auth.user().name }}
      {{ $auth.user().apellido }}
    </h2>

    <z-form-editar
      :id="$auth.user().id"
      v-model="user"
      :after-save="afterSave"
      url="usuarios/users"
      url-save="usuarios/users/update-perfil"
      @updated="notificar('Los datos de usuario fueron actualizados.')"
      @read:error="notificarErrorResponse('No se pudieron leer los datos de usuario.')"
      @update:error="notificarErrorResponse"
    >
      <template #default="{ form, errors, loading, save }">
        <v-card :loading="loading" class="mx-auto" max-width="500" tile>
          <!--
        <v-card-title>
          Edición de institución
        </v-card-title>
        -->
          <v-form ref="form" :readonly="loading" lazy-validation @submit.prevent="save">
            <v-card-text>
              <v-row>
                <v-col>
                  <z-input v-model="form.name" :error-messages="errors.name" label="Nombre" />
                </v-col>
                <v-col>
                  <z-input
                    v-model="form.apellido"
                    :error-messages="errors.apellido"
                    label="Apellido"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <z-input
                    v-model="form.email"
                    :error-messages="errors.email"
                    label="Correo electrónico"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <z-input
                    v-model="form.telefono"
                    :error-messages="errors.telefono"
                    label="Teléfono"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <z-input
                    v-model="form.cargo"
                    :error-messages="errors.cargo"
                    label="Cargo"
                    maxlength="50"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <btn-guardar-cancelar :loading="loading" @cancelar="$router.push('/dashboard')" />
            </v-card-actions>
          </v-form>
        </v-card>
      </template>
    </z-form-editar>
  </div>
</template>
<script>
import { notificar, notificarErrorResponse } from '@/admin/utils/notificar'
import BtnGuardarCancelar from '@/components/BtnGuardarCancelar'
import ZFormEditar from '@/components/Input/ZFormEditar'
import ZInput from '@/components/Input/ZInput'

export default {
  components: { ZInput, BtnGuardarCancelar, ZFormEditar },
  data: () => ({
    user: {},
  }),
  computed: {},
  mounted() {},
  methods: {
    afterSave(user) {
      this.$store.dispatch('auth/updateAuthUser', user)
      this.$router.push('/dashboard')
    },
    notificarErrorResponse(event) {
      notificarErrorResponse(event)
    },
    notificar(event) {
      notificar(event)
    },
    // save () {
    //   if (this.$refs.form.validate()) {
    //     this.$emit('notificar', `Los datos de <b>${this.$auth.user().name} ${this.$auth.user().apellido}</b> han sido guardados.`)
    //     this.$router.push('/dashboard')
    //   }
    // },
  },
}
</script>
