<template>
  <div class="d-flex align-center justify-center flex-column mx-auto" style="height: 80vh">
    <p class="text-h2">Página no encontrada</p>
    <p>
      <v-btn text :to="loggedIn ? '/dashboard' : '/'">
        Continuar
        <v-icon right> mdi-arrow-right-circle-outline </v-icon>
      </v-btn>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  computed: {
    ...mapGetters('auth', ['loggedIn']),
  },
}
</script>
