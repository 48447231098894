import {
  mdiAccountCircleOutline,
  mdiAlertOutline,
  mdiArrowLeft,
  mdiArrowRightCircleOutline,
  mdiBank,
  mdiBankOutline,
  mdiBusSide,
  mdiCarSide,
  mdiCheckCircleOutline,
  mdiChevronLeft,
  mdiChevronRight,
  mdiClose,
  mdiCloudDownloadOutline,
  mdiCloudUploadOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiFormatFontSizeDecrease,
  mdiFormatFontSizeIncrease,
  mdiHandBackRight,
  mdiHelpCircleOutline,
  mdiHomeOutline,
  mdiInformationOutline,
  mdiMagnify,
  mdiMessage,
  mdiSquareEditOutline,
  mdiTestTube,
  mdiTextBoxOutline,
  mdiThumbDownOutline,
  mdiThumbUpOutline,
  mdiTrashCanOutline,
  mdiTruck,
  mdiViewDashboardOutline,
  mdiWeatherNight,
  mdiWhiteBalanceSunny,
} from '@mdi/js'
import Vue from 'vue'

export const svg = {
  mdiAccountCircleOutline,
  mdiAlertOutline,
  mdiArrowLeft,
  mdiArrowRightCircleOutline,
  mdiBank,
  mdiBankOutline,
  mdiBusSide,
  mdiCarSide,
  mdiCheckCircleOutline,
  mdiChevronLeft,
  mdiChevronRight,
  mdiClose,
  mdiCloudDownloadOutline,
  mdiCloudUploadOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiFormatFontSizeDecrease,
  mdiFormatFontSizeIncrease,
  mdiHandBackRight,
  mdiHelpCircleOutline,
  mdiHomeOutline,
  mdiInformationOutline,
  mdiMagnify,
  mdiMessage,
  mdiSquareEditOutline,
  mdiTestTube,
  mdiTextBoxOutline,
  mdiThumbDownOutline,
  mdiThumbUpOutline,
  mdiTrashCanOutline,
  mdiTruck,
  mdiViewDashboardOutline,
  mdiWeatherNight,
  mdiWhiteBalanceSunny,
}

export const mixinSvg = {
  computed: {
    $svg() {
      return svg
    },
  },
}

if (!Vue.__mixin_sgv__) {
  Vue.__mixin_sgv__ = true
  Vue.mixin(mixinSvg)
}

export default svg
