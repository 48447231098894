// noinspection EqualityComparisonWithCoercionJS

import Vue from 'vue'
import { getError } from '@/utils/errors'
import { notificar } from '@/admin/utils/notificar'
import { saveAs } from 'file-saver'
import { tramiteById } from '@/plugins/tramites'
import { apiClient } from '@/admin/services/API'

const util = {
  descargarPdf(cuenta) {
    const slugTramite = tramiteById(cuenta.tramite_id)?.slug
    if (!slugTramite) {
      notificar({
        message: `Trámite ${cuenta.tramite_id} no disponible.`,
        severity: 'error',
      })
      return
    }

    const nombre = cuenta.pk_label || cuenta.id

    apiClient
      .get(`tramites/${slugTramite}/cuentas/${cuenta.id}/pdf`, { responseType: 'blob' })
      .then((response) => {
        // const filename = response.headers['content-disposition'].toString().match(/filename=["']?([^'"]+)["']?/)[1]
        saveAs(response.data, `${slugTramite} ${nombre}.pdf`)
      })
      .catch((error) => {
        error = getError(error)
        notificar({
          message: `Documento PDF ${nombre} no disponible.`,
          severity: 'error',
        })
      })
  },
  descargarXml(cuenta) {
    const slugTramite = tramiteById(cuenta.tramite_id)?.slug
    if (!slugTramite) {
      notificar({
        message: `Trámite ${cuenta.tramite_id} no disponible.`,
        severity: 'error',
      })
      return
    }

    const nombre = cuenta.pk_label || cuenta.id

    apiClient
      .get(`tramites/${slugTramite}/cuentas/${cuenta.id}/xml`, { responseType: 'blob' })
      .then((response) => {
        saveAs(response.data, `${slugTramite} ${nombre}.xml`)
      })
      .catch((error) => {
        error = getError(error)
        notificar({
          message: `Documento XML ${nombre} no disponible.`,
          severity: 'error',
        })
      })
  },
}

Vue.use({
  install(Vue) {
    Vue.prototype.$util = util
  },
})

export default util
