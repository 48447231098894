<template>
  <v-dialog
    v-model="visible"
    persistent
    :fullscreen="fullscreen || $vuetify.breakpoint.xsOnly"
    :max-width="maxWidth"
    :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''"
    :width="width"
    scrollable
    @update:return-value="cerrar"
  >
    <v-card
      :height="fullscreen || $vuetify.breakpoint.xsOnly ? null : height"
      min-height="250"
      tile
    >
      <v-toolbar flat max-height="56" xxxstyle="border-bottom: 1px solid lightgrey">
        <v-btn :disabled="loading" icon @click.stop="cerrar">
          <v-icon v-if="fullscreen || $vuetify.breakpoint.xsOnly">
            {{ svg.mdiArrowLeft }}
          </v-icon>
          <v-icon v-else>
            {{ svg.mdiClose }}
          </v-icon>
        </v-btn>

        <!--

        Título

        -->

        <v-toolbar-title
          :class="$vuetify.breakpoint.xsOnly ? 'text-subtitle-1' : ''"
          class="font-weight-medium"
          style="user-select: none"
        >
          <!-- @slot Título. En caso de no especificarlo, se utilizará el prop `campo-titulo` -->
          <slot :item="value" name="titulo">
            {{ getValoresItem(value, campoTitulo) }}
          </slot>

          <!--

          Subtítulo

          -->

          <span class="font-weight-light text-subtitle-2">
            <!-- @slot Subtítulo. En caso de no especificarlo, se utilizará el prop `campo-subtitulo` -->
            <slot :item="value" name="subtitulo">
              {{ getValoresItem(value, campoSubtitulo) }}
            </slot>
          </span>
        </v-toolbar-title>

        <!--

        Botones de navegación entre items

        -->

        <v-spacer />

        <template v-if="navegacion && items.length > 1">
          <div
            v-if="$vuetify.breakpoint.smAndUp"
            class="text-overline mx-1 text-no-wrap"
            style="user-select: none"
          >
            {{ itemIndex + 1 }} / {{ items.length }}
          </div>
          <v-btn
            :disabled="itemIndex === 0 || loading"
            icon
            small
            title="Anterior"
            @click.stop="prevItem"
          >
            <v-icon>{{ svg.mdiChevronLeft }}</v-icon>
          </v-btn>
          <v-btn
            :disabled="itemIndex === items.length - 1 || loading"
            icon
            small
            title="Siguiente"
            @click.stop="nextItem"
          >
            <v-icon>{{ svg.mdiChevronRight }}</v-icon>
          </v-btn>
        </template>
      </v-toolbar>

      <!--

      Contenido

      -->

      <v-card-text
        v-touch.stop="{
          right: prevItem,
          left: nextItem,
        }"
        class="pt-4"
      >
        <slot :item="value" name="default" @cerrar="cerrar" />
      </v-card-text>

      <!--

      Botones con acciones

      -->

      <v-card-actions v-if="$vuetify.breakpoint.smAndUp" class="mb-2">
        <template v-for="(boton, key) in botones">
          <v-btn
            v-if="!boton.hasOwnProperty('visible') || boton.visible(value)"
            :key="'botones-' + key"
            :disabled="loading || (!!boton.disabled && boton.disabled(value))"
            :title="boton.title"
            :color="boton.color || 'grey darken-1'"
            fab
            small
            text
            @click.stop="boton.click(value)"
          >
            <v-icon v-text="boton.icon" />
          </v-btn>
        </template>
        <v-spacer />

        <!--
                Al hacer click en el botón cerrar
                @event boton-cerrar
        -->
        <v-btn
          :disabled="loading"
          color="primary"
          outlined
          @click="
            visible = false
            $emit('boton-cerrar')
          "
        >
          {{ botonCerrar }}
        </v-btn>

        <template v-if="botonesPrincipales.length > 0">
          <template v-for="(boton, key) in botonesPrincipales">
            <v-btn
              v-if="!boton.hasOwnProperty('visible') || boton.visible(value)"
              :key="'botones-principales-' + key"
              :label="boton.label"
              :color="boton.color || 'primary'"
              :loading="boton.loading"
              :outlined="typeof boton.outlined === 'undefined' ? false : boton.outlined"
              :disabled="loading || (!!boton.disabled && boton.disabled(value))"
              @click.stop="boton.click(value)"
            >
              {{ boton.label }}
              <v-icon v-if="boton.icon" right v-text="boton.icon" />
            </v-btn>
          </template>
        </template>

        <!--
         Al hacer click en el botón principal
         @event boton-principal
       -->
        <v-btn
          v-if="botonPrincipal"
          :loading="loading"
          :disabled="botonPrincipalDisabled"
          color="primary"
          @click="$emit('boton-principal')"
        >
          {{ botonPrincipal }}
        </v-btn>
      </v-card-actions>

      <template v-else>
        <v-card-actions>
          <template v-if="botonesPrincipales.length > 0">
            <template v-for="(boton, key) in botonesPrincipales">
              <v-btn
                v-if="!boton.hasOwnProperty('visible') || boton.visible(value)"
                :key="'botones-principales-' + key"
                :label="boton.label"
                :color="boton.color || 'primary'"
                :outlined="typeof boton.outlined === 'undefined' ? false : boton.outlined"
                :disabled="loading || (!!boton.disabled && boton.disabled(value))"
                @click.stop="boton.click(value)"
              >
                {{ boton.label }}
                <v-icon v-if="boton.icon" right v-text="boton.icon" />
              </v-btn>
            </template>
          </template>

          <v-btn
            v-if="botonPrincipal"
            :loading="loading"
            color="primary"
            @click="$emit('boton-principal')"
          >
            {{ botonPrincipal }}
          </v-btn>
        </v-card-actions>

        <v-bottom-navigation v-if="botones.length > 0" color="primary">
          <template v-for="(boton, key) in botones">
            <v-btn
              v-if="!boton.hasOwnProperty('visible') || boton.visible(value)"
              :key="'botones-' + key"
              :disabled="loading || (!!boton.disabled && boton.disabled(value))"
              :title="boton.title"
              @click.stop="boton.click(value)"
            >
              <span>{{ boton.leyendaXs || boton.title }}</span>
              <v-icon :color="boton.color || 'grey darken-1'" v-text="boton.icon" />
            </v-btn>
          </template>
        </v-bottom-navigation>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiArrowLeft, mdiChevronLeft, mdiChevronRight, mdiClose } from '@mdi/js'

export default {
  props: {
    /**
     * Objeto con el item activo para desplegar
     */
    value: {
      type: Object,
    },

    /**
     * Arreglo de items disponibles
     */
    items: {
      type: Array,
      default: () => [],
      // required: true,
    },

    /**
     * Arreglode botones segundarios para agregar al diálogo.
     *
     * Por ejemplo:
     *
     * ```
     * [
     *   {
     *     title: 'Editar',
     *     icon: 'mdi-square-edit-outline',
     *     click: (item) => metodo('Editar', item)
     *   },
     *   {
     *     title: 'Eliminar',
     *     icon: 'mdi-trash-can-outline',
     *     click: (item) => metodo('Eliminar', item)
     *   },
     * ]
     * ```
     *
     * Es posible definir funciones para cada botón:
     *
     * - `visible(item)`: Muestra u oculta el botón
     * - `disabled(item)`: Deshabilita el botón
     *
     * Por ejemplo:
     *
     * ```
     * [
     *   {
     *     title: 'Editar',
     *     visible: item => item.editable,
     *   },
     *   {
     *     title: 'Ver historial',
     *     disabled: item => !item.tieneLogs,
     *   },
     * ]
     * ```
     */
    botones: {
      type: Array,
      default: () => [],
    },

    /**
     * Arreglode botones principales para agregar al diálogo.
     *
     * Es posible definir funciones para cada botón:
     *
     * - `visible(item)`: Muestra u oculta el botón
     * - `disabled(item)`: Deshabilita el botón
     * Por ejemplo:
     *
     * ```
     * [
     *   {
     *     label: 'Eliminar',
     *     click: (item) => metodo('Eliminar', item)
     *     // opcionales...
     *     color: 'primary',
     *     outlined: true,
     *     loading: false,
     *     icon: 'mdi-trash-can-outline',
     *   },
     * ]
     * ```
     *
     *
     */
    botonesPrincipales: {
      type: Array,
      default: () => [],
    },

    /**
     * Campo que contiene el titulo de la ventana. Es posible indicar un string arbitrario, ejemplo: "Crear usuario".
     * Se puede indicar una función.
     */
    campoTitulo: {
      type: [String, Function],
      default: undefined,
    },

    /**
     * Campo que contiene el subtitulo de la ventana.
     * Se puede indicar una función.
     */
    campoSubtitulo: {
      type: [String, Function],
      default: undefined,
    },

    /**
     * Botón con acción principal.
     * Al hacer click emitirá el evento `@boton-principal`.
     */
    botonPrincipal: {
      type: String,
      default: undefined,
    },

    /**
     * Permite deshabilitar el botón principal, por ejemplo cuando hay un formulario inválido.
     */
    botonPrincipalDisabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Texto del botón para cerrar el dialog
     * Al hacer click emitirá el evento `@boton-cerrar`.
     */
    botonCerrar: {
      type: String,
      default: 'Cerrar',
    },

    /**
     * Flag indicando que hay algo en proceso para así bloquear el botón de acción.
     */
    loading: {
      type: Boolean,
      default: false,
    },

    /**
     * Flag indicando si se permite navegar entre los items anterior y siguiente
     */
    navegacion: {
      type: Boolean,
      default: false,
    },

    /**
     * Ancho máximo del dialog
     */
    maxWidth: {
      type: [String, Number],
      default: undefined,
    },

    /**
     * Ancho del dialog
     */
    width: {
      type: [String, Number],
      default: undefined,
    },

    /**
     * Alto del dialog
     */
    height: {
      type: [String, Number],
      default: undefined,
    },

    /**
     * Diálogo en pantalla completa
     * (En tamaño XS será siempre en pantalla completa)
     */
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    visible: true,
    svg: {
      mdiArrowLeft,
      mdiChevronLeft,
      mdiChevronRight,
      mdiClose,
    },
  }),
  computed: {
    itemIndex() {
      return this.items.findIndex((item) => item.id === this.value.id)
    },
  },
  watch: {
    // value: function (value) {
    //   this.show = value
    // },
  },
  beforeMount() {
    // this.show = this.value
  },
  methods: {
    cerrar() {
      this.visible = false
      /** Diálogo cerrado */
      this.$emit('cerrar')
    },
    /**
     * Activa el item anterior
     */
    prevItem() {
      if (this.itemIndex > 0) {
        const itemAnterior = this.items[this.itemIndex]
        const siguienteItem = this.items[this.itemIndex - 1]
        /** Se activó otro item */
        this.$emit('input', siguienteItem)
        /**
         * Se activó otro item
         * @property {Object} siguienteItem Siguiente item activado
         * @property {Object} itemAnterior Siguiente item activado
         */
        this.$emit('item-cambiado', siguienteItem, itemAnterior)
      }
    },
    /**
     * Activa el item siguiente
     */
    nextItem() {
      if (this.itemIndex < this.items.length - 1) {
        const siguienteItem = this.items[this.itemIndex + 1]
        this.$emit('input', siguienteItem)
        this.$emit('item-cambiado', siguienteItem)
      }
    },

    getValoresItem(item, campos) {
      if (typeof campos === 'function') {
        return campos(item)
      }
      return item[campos] ?? campos
    },
  },
}
</script>
