<template>
  <v-footer class="mt-10" color="secundario" dark padless>
    <v-container class="pb-0 mb-0">
      <v-card class="flex mx-auto" color="secundario" elevation="0" tile>
        <v-card-text class="text-caption" style="line-height: 0.9rem">
          <v-row>
            <v-col cols="8">
              <p>
                Subsecretaría de Desarrollo<br />
                Regional y Administrativo.<br />
              </p>
              <p class="mb-0">
                Teatinos 92 - Pisos 2 y 3,<br />
                Santiago, Chile.<br />
                <!--Teléfono mesa central:<br>
                <a href="tel:2 2 636 3600}">(2) 2 636 3600</a>-->
              </p>
              <div
                class="text-caption grey--text mb-n2"
                :class="{ 'd-none': ambiente === 'production' }"
                style="opacity: 0.4"
              >
                <small class="version">{{ version }}</small>
              </div>
            </v-col>
            <v-col>
              <router-link to="/ayuda"> Preguntas frecuentes </router-link>
              <br />
              <br />
              <a href="/SEM2_Manual_Municipal.pdf" target="_blank"> Manual de usuario </a>
              <br />
              <br />
              <div class="mb-0">
                Mesa de ayuda:
                <ul>
                  <li>
                    Correo:
                    <a href="mailto:ugel@subdere.gov.cl">
                      ugel@subdere.gov.cl
                    </a>
                  </li>
                </ul>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <barrita-gob-inferior class="mb-n1" style="margin-bottom: -7px !important" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-footer>
</template>
<script>
import BarritaGobInferior from '@/components/Icons/BarritaGobInferior'

export default {
  components: { BarritaGobInferior },
  computed: {
    version() {
      return process.env.VUE_APP_VERSION
    },
    ambiente() {
      return process.env.VUE_APP_AMBIENTE
    },
  },
}
</script>

<style>
footer a {
  text-decoration-color: rgba(255, 255, 255, 0.2) !important;
  color: rgba(255, 255, 255, 0.7) !important;
  transition: 200ms;
}
footer a:hover {
  color: rgba(255, 255, 255, 0.8) !important;
  text-decoration-color: rgba(255, 255, 255, 0.6) !important;
}
</style>
