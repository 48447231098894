// import router from '@/admin/router'
import { getError } from '@/utils/errors'
import { notificar } from '@/admin/utils/notificar'
import AuthService from '@/admin/services/AuthService'
import dayjs from '@/plugins/dayjs'

export const namespaced = true

export const state = {
  loadingRefresh: false,
  user: null,
  error: null,
}

export const mutations = {
  SET_USER(state, user) {
    state.user = user
    if (user) {
      window.localStorage.setItem('user', JSON.stringify(user))
    } else {
      window.localStorage.removeItem('user')
      window.localStorage.removeItem('jwt')
      window.localStorage.removeItem('jwt_expires_at')
    }
  },
  UPDATE_USER(state, user) {
    for (const attribute in user) {
      // noinspection JSUnfilteredForInLoop
      if (typeof state.user[attribute] !== 'undefined') {
        // noinspection JSUnfilteredForInLoop
        state.user[attribute] = user[attribute]
      }
      window.localStorage.setItem('user', JSON.stringify(state.user))
    }
  },
  SET_INSTITUCION(state, institucion) {
    state.user.institucion = institucion
  },
  SET_LOADING_REFRESH(state, loading) {
    state.loadingRefresh = loading
  },
}

/**
 * Guarda el token JWT en el localStorage.
 */
function saveTokenInLocalStorage(data) {
  window.localStorage.setItem('jwt', data.access_token)
  if (data.expires_in) {
    window.localStorage.setItem('jwt_expires_at', dayjs().add(data.expires_in, 'seconds').format())
  }
}

// eslint-disable-next-line no-unused-vars
function log(...mensaje) {
  console.log(`%c ${mensaje} `, 'background: #151; color: #caea75; padding: 2px; border-radius:2px')
}

export const actions = {
  /**
   * Inicia sesión con las credenciales de autenticación del usuario (login y password).
   * En caso de éxito, guarda el usuario y token en el localStorage.
   */
  login({ dispatch, commit }, payload) {
    dispatch('app/setLoading', true, { root: true })
    return new Promise((resolve, reject) => {
      AuthService.login(payload)
        .then((response) => {
          dispatch('app/setLoading', false, { root: true })
          response.data.user.permissions = response.data.permissions
          saveTokenInLocalStorage(response.data)
          dispatch('setAuthUser', response.data.user)
          dispatch('app/setPayload', response.data.payload, { root: true })
          dispatch('app/fetchPayload', 'pagos', { root: true })
          dispatch('app/fetchPayload', 'logs', { root: true })
          dispatch('app/fetchPayload', 'documentacion', { root: true })

          if (response.data.notificar) {
            notificar(response.data.notificar)
          }
          resolve()
        })
        .catch((error) => {
          dispatch('app/setLoading', false, { root: true })
          dispatch('setAuthUser', null)
          error = getError(error)
          // commit('SET_ERROR', error)
          reject(error)
        })
    })
  },

  /**
   * Refresca la sesión con el token aún vigente JWT.
   * En caso de éxito, actualiza el usuario y token en el localStorage.
   */
  refresh({ dispatch, commit }) {
    if (state.loadingRefresh) {
      log('Aborto solicitud de refresh de token JWT: Ya hay otro en curso')
      return
    }
    commit('SET_LOADING_REFRESH', true)
    log('Solicitando refresh de token JWT')
    return new Promise((resolve, reject) => {
      AuthService.refresh()
        .then((response) => {
          response.data.user.permissions = response.data.permissions
          saveTokenInLocalStorage(response.data)
          dispatch('setAuthUser', response.data.user)
          // dispatch('app/setPayload', response.data.payload, { root: true })
          dispatch('app/mergePayload', response.data.payload, { root: true })
          // dispatch('app/fetchPayload', 'pagos', { root: true })
          dispatch('app/fetchPayload', 'logs', { root: true })
          resolve()
        })
        .catch((error) => {
          dispatch('setAuthUser', null)
          error = getError(error)
          console.log(error)
          // commit('SET_ERROR', error)
          reject(error)
        })
        .finally(() => {
          // Prevengo solicitar otro refresh por algunos segundos/minutos
          setTimeout(() => commit('SET_LOADING_REFRESH', false), 30 * 1000)
        })
    })
  },

  /**
   * Finaliza la sesión.
   * No realiza ninguna operación de autenticación con la API, simplemente borra los
   * datos de la sesión desde Vuex y localStorage.
   */
  async logout({ dispatch }) {
    await AuthService.logout()
    dispatch('setAuthUser', null).then(() => {
      if (window.location.hostname === 'localhost') {
        // router.push({ path: '/' })
        // window.location.assign('/admin')
        window.location.href = process.env.BASE_URL
      } else {
        // window.location.href = 'https://accounts.claveunica.gob.cl/api/v1/accounts/app/logout?redirect=' + encodeURIComponent(window.location.origin + '/admin')
        window.location.href =
          'https://accounts.claveunica.gob.cl/api/v1/accounts/app/logout?redirect=' +
          encodeURIComponent(window.location.origin)
        // window.location.href = process.env.BASE_URL
      }
    })
  },

  /**
   * Refresca la información del usuario actualmente logueado.
   */
  fetchAuthUser({ commit }) {
    return AuthService.getAuthUser()
      .then((response) => {
        commit('SET_USER', response.data.data)
      })
      .catch((error) => {
        commit('SET_USER', null)
        console.error(error)
        // commit('SET_ERROR', getError(error))
      })
  },

  /**
   * Guarda el usuario autenticado en Vuex y una copia en localStorage.
   * Oculta el menú principal en caso de que esté abierto.
   */
  setAuthUser({ commit, dispatch }, user, userAnterior = []) {
    commit('SET_USER', user)
    dispatch('app/setMenuPrincipalVisible', false, { root: true })
  },

  /**
   * Actualiza algunos atributos del User
   */
  updateAuthUser({ commit, dispatch }, user) {
    commit('UPDATE_USER', user)
  },

  setAuthInstitucion({ commit }, institucion) {
    commit('SET_INSTITUCION', institucion)
  },

  /**
   * Guarda el usuario autenticado en base a la información desde el localStorage
   * verificando que el token no haya expirado. Hace la renovación de token en caso de ser necesario.
   */
  setAuthUserFromLocalStorage({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      const jwt = localStorage.getItem('jwt')
      const userString = localStorage.getItem('user')
      if (!jwt || !userString) {
        return reject(new Error('No autenticado'))
      }

      // Verifico si el token ya expiró
      const jwtExpiresAt = window.localStorage.getItem('jwt_expires_at')
      const segundosParaExpirar = dayjs(jwtExpiresAt).diff(dayjs(), 'second')
      if (segundosParaExpirar <= 3) {
        return reject(new Error('Sesión expirada'))
      }

      // Restauro datos actuales del usuario
      commit('SET_USER', JSON.parse(userString))

      // Si el token está próximo a expirar, solicito un token actualizado desde el servidor
      console.log('Token JWT expira en', jwtExpiresAt, segundosParaExpirar)
      if (segundosParaExpirar <= 300) {
        dispatch('refresh')
      }

      // Obtengo información necesaria desde microservicios
      dispatch('app/fetchPayload', 'usuarios', { root: true })
      dispatch('app/fetchPayload', 'pagos', { root: true })
      dispatch('app/fetchPayload', 'logs', { root: true })
      dispatch('app/fetchPayload', 'documentacion', { root: true })

      return resolve()
    })
  },
}

export const getters = {
  authUser: (state) => {
    return state.user
  },
  can: (state) => (permission) => {
    if (typeof permission === 'undefined') {
      return false
    }
    if (state.user.permissions.includes('super-admin')) {
      return true
    }

    // TODO: Mejorar esto. usar algo como array interseccion
    const permissions = typeof permission === 'string' ? [permission] : permission
    for (const id in permissions) {
      permission = permissions[id]
      if (state.user.permissions.includes(permission)) {
        return true
      }
    }
    return false
  },
  error: (state) => {
    return state.error
  },
  loggedIn: (state) => {
    return !!state.user && !!window.localStorage.getItem('jwt')
  },
}
