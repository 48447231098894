import dayjs from '@/plugins/dayjs'

export const getError = (error) => {
  if (typeof error === 'string') {
    return error
  }

  // Respuestas BLOB son convertidas a pseudo-response
  if (
    error.request.responseType === 'blob' &&
    error.response.data instanceof Blob &&
    error.response.data.type &&
    error.response.data.type.toLowerCase().indexOf('json') !== -1
  ) {
    const status = error.response.status
    let data = {}
    error.response.data.text().then((json) => (data = JSON.parse(json)))
    error = {
      response: {
        status,
        data,
        headers: {},
      },
    }
  }

  const defaultErrorMessage = 'Error de comunicación, reintente más tarde.'

  if (!error.response) {
    if (error?.config?.url) {
      console.error(`API ${error.config.url} no encontrada`)
      return defaultErrorMessage
    }
    console.error(error)
    return error
  }
  if (process.env.NODE_ENV === 'development') {
    console.error(error.response.data)
    console.error(error.response.status)
    console.error(error.response.headers)
  }
  if (error.response.status === 401) {
    return error.response?.data.message || 'Debes iniciar sesión.'
  }
  if (error.response.status === 403) {
    return error.response.data?.message === 'This action is unauthorized.'
      ? 'Acceso no autorizado.'
      : error.response.data?.message
  }
  if (error.response.status === 404) {
    return 'Página no encontrada.'
  }
  if (error.response.status === 405) {
    return 'Acción no permitida.'
  }
  if (error.response.status === 413) {
    return 'Archivo demasiado grande.'
  }
  if (error.response.status === 429) {
    const tiempo = error.response.headers['x-ratelimit-reset']
      ? dayjs.unix(error.response.headers['x-ratelimit-reset']).fromNow(true)
      : 'un momento'

    console.log(tiempo)
    return `Demasiados intentos seguidos, espere ${tiempo} antes de reintentar.`
  }

  if (error.response.status === 504) {
    return 'Error de conexión. Espera un momento antes de reintentar.'
  }
  if (error.response.status >= 500) {
    return 'Hubo un error interno.'
  }

  let message = error.response.data?.message || ''
  if (message === '' || message === 'The given data was invalid.') {
    message = 'Hay datos ingresados que no son válidos.'
  }

  if (error.response.status === 422 && message.match(/\(and \d+ more error\)/)) {
    message = 'Los datos enviados contienen errores.'
  }

  if (error.response.data?.errors) {
    return { ...error.response.data.errors, _message: message }
  }
  if (error.response.data?.message) {
    return message
  }

  return defaultErrorMessage
}
