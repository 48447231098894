import Vue from 'vue'
import formatoBase from '@/plugins/formato'

const formato = {
  ...formatoBase,
}

Vue.use({
  install(Vue) {
    Vue.prototype.$formato = formato
  },
})

export default formato
