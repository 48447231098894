<template>
  <v-card
    v-bind="$props"
    :class="show ? 'z-card-visible' : 'z-card-hidden'"
    :elevation="flat || $vuetify.breakpoint.xsOnly ? 0 : 1"
    :flat="flat"
    class="mt-1 mb-3 z-card"
  >
    <v-card-title v-if="title" :style="colapsable ? 'cursor: pointer' : ''" @click.stop="toggle">
      {{ title }}
      <v-spacer v-if="colapsable && !noSpacer" />
      <v-btn v-if="colapsable" class="mr-2" color="grey darken-1" icon small @click.stop="toggle">
        <v-icon :class="show ? 'on' : 'off'"> mdi-chevron-right </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle v-if="subtitle">
      {{ subtitle }}
    </v-card-subtitle>
    <v-expand-transition>
      <div v-show="show" class="z-card-colapsable">
        <!--
        @slot Contenido equivalente a `v-card`, por lo que
        pueden usar elementos como `v-card-text` o `v-card-actions`.
      -->
        <slot />
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: 'ZCard',
  props: {
    /**
     * Quita la elevación
     */
    flat: {
      type: Boolean,
      default: false,
    },

    /**
     * Título
     */
    title: {
      type: String,
      default: null,
    },

    /**
     * Subtítulo
     */
    subtitle: {
      type: String,
      default: null,
    },

    /**
     * Habilita el componente y agrega un ícono para permitir colapsar el contenido.
     */
    colapsable: {
      type: Boolean,
      default: false,
    },

    /**
     * Quita el espaciado entre el título y el ícono para colapsar.
     * Útil cuando se utiliza `flat`.
     */
    noSpacer: {
      type: Boolean,
      default: false,
    },

    /**
     * Usado en conjunto con `colapsable`.
     * Controla el estado de visible/colapsado del componente.
     */
    value: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    show: true,
  }),
  watch: {
    value: function (value) {
      this.show = value
    },
  },
  beforeMount() {
    this.show = this.value
  },
  methods: {
    toggle() {
      if (!this.colapsable) {
        return
      }
      this.show = !this.show
      this.$emit('input', this.show)
    },
  },
}
</script>

<style>
/** Animación de la transición */
.z-card .v-card__title .v-icon {
  transition: transform 280ms ease;
}

.z-card .v-card__title .v-icon.on {
  transform: rotate(-90deg);
}

.z-card .v-card__title .v-icon.off {
  transform: rotate(90deg);
}

/** Acerca título con subtítulo y contenido */
.z-card .v-card__title {
  padding-top: 12px;
  padding-bottom: 10px;
}

.z-card .v-card__subtitle + .z-card-colapsable .v-card__text,
.z-card .v-card__title + .z-card-colapsable .v-card__subtitle,
.v-card__title + .z-card-colapsable .v-card__text {
  padding-top: 6px;
}
</style>
