import * as API from '@/admin/services/API'
import { getError } from '@/utils/errors'

export const namespaced = true

const payloadDefault = {
  aviso: {
    resumen: '',
    titulo: '',
    mensaje: '',
    fecha_desde: '2020-01-01T00:00:00',
    fecha_hasta: '2020-01-01T00:00:00',
  },
  alertas_sin_leer: 0,
  roles: [],
  instituciones: [],
}

export const state = {
  loading: false,
  error: '',
  menuPrincipalVisible: false,
  titulo: '',
  tramite: '',
  payload: payloadDefault,
  snackbar: {
    visible: false,
    message: '',
    severity: 'info',
    timeout: 6000,
  },
}

export const mutations = {
  SET_MENU_PRINCIPAL_VISIBLE(state, visible) {
    state.menuPrincipalVisible = visible
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  },
  SET_TITULO(state, titulo) {
    state.titulo = titulo
  },
  SET_TRAMITE(state, tramite) {
    state.tramite = tramite
  },
  SET_SNACKBAR(state, snackbar) {
    state.snackbar = { ...snackbar, visible: true }
  },
  HIDE_SNACKBAR(state) {
    state.snackbar = { ...state.snackbar, message: '', visible: false }
  },
  SET_PAYLOAD(state, payload) {
    state.payload = { ...payloadDefault, ...payload }
  },
}

export const actions = {
  setLoading({ commit }, loading) {
    commit('SET_LOADING', loading)
  },
  setMenuPrincipalVisible({ commit }, visible) {
    commit('SET_MENU_PRINCIPAL_VISIBLE', visible)
  },
  setTitulo({ commit }, titulo) {
    commit('SET_TITULO', titulo)
  },
  setTramite({ commit }, tramite) {
    commit('SET_TRAMITE', tramite)
    window.localStorage.setItem('tramite', tramite)
  },
  hideSnackbar({ commit }) {
    commit('HIDE_SNACKBAR')
  },
  showSnackbar({ commit }, snackbar) {
    snackbar = typeof snackbar === 'string' ? { message: snackbar } : snackbar
    snackbar.severity ??= 'info'
    snackbar.timeout ??= 6000
    commit('SET_SNACKBAR', snackbar)
  },

  /**
   * Inicializa el payload enviado desde el microservicio
   */
  setPayload({ commit }, payload = []) {
    commit('SET_PAYLOAD', payload)
  },

  /**
   * Actualiza el payload enviado desde el microservicio
   */
  mergePayload({ commit, state }, payload = []) {
    commit('SET_PAYLOAD', { ...state.payload, ...payload })
  },

  /**
   * Descarga infomración requerida desde los microservicios
   * Por ejemplo: Listado de instituciones, permissions, etc.
   */
  fetchPayload({ dispatch, commit }, microservicio) {
    return new Promise((resolve, reject) => {
      API.apiClient
        .get(`/${microservicio}/payload/${microservicio}`)
        .then((response) => {
          dispatch('mergePayload', response.data)
          resolve()
        })
        .catch((error) => {
          error = getError(error)
          commit('SET_ERROR', error)
          reject(error)
        })
    })
  },
}

export const getters = {
  menuPrincipalVisible: (state) => {
    return state.menuPrincipalVisible
  },
  loading: (state) => {
    return state.loading
  },
  titulo: (state) => {
    return state.titulo
  },
  snackbar: (state) => {
    return state.snackbar
  },
  payload: (state) => {
    return state.payload
  },
  tramite: (state) => {
    return state.tramite || window.localStorage.getItem('tramite') || ''
  },
}
