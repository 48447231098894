import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import es from 'vuetify/lib/locale/es'

Vue.use(Vuetify)

export const opts = {
  icons: {
    iconfont: 'mdiSvg',
  },
  lang: {
    locales: { es },
    current: 'es',
  },
  theme: {
    themes: {
      light: {
        primary: '#0F69C4',
        accent: '#A8B7C7',
        /* Colores sucess, error, etc. */
        success: '#04A398',
        info: '#0F69C4', // Distinto a Kit Digital
        warning: '#F56B0F',
        error: '#E22C2C',
        /* Colores para otros levels de error RFC 5424 */
        notice: '#4D11C4',
        critical: '#E22C2C',
        alert: '#E22C2C',
        emergency: '#E22C2C',
        /* Colores Kit Digital */
        primario: '#0F69C4',
        secundario: '#001C41',
        terciario: '#E22C2C',
        /* Colores de fondo */
        fondo: '#F8F8F8',
        fondo2: '#F5F5F5', // grey lighten-4
        fondo3: '#ECEFF1', // blue-grey lighten-5
        /* Otros */
        elevation: 1,
      },
      dark: {
        primary: '#0F69C4',
        accent: '#A8B7C7',
        /* Colores success, error, etc. */
        info: '#0F69C4', // Distinto a Kit Digital
        success: '#04A398',
        warning: '#F56B0F',
        error: '#E22C2C',
        /* Colores Kit Digital */
        primario: '#0F69C4',
        secundario: '#001C41',
        terciario: '#E22C2C',
        /* Colores de fondo */
        fondo: '#121212',
        fondo2: '#212121', // grey darken-4
        fondo3: '#263238', // blue-grey darken-4
        /* Otros */
        elevation: 1,
      },
    },
  },
}

export default new Vuetify(opts)
