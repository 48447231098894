import axios from 'axios'
import store from '@/admin/store'
import NProgress from 'nprogress'
import '@/sass/nprogress.css'
import '@/sass/fonts.css'
import dayjs from '@/plugins/dayjs'
import(/* webpackChunkName: "mdi", webpackPrefetch: true */ '@mdi/font/css/materialdesignicons.css')

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
export const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30 * 1000, // Timeout
  withCredentials: true, // required to handle the CSRF token
  headers: {
    Accept: 'application/json',
  },
})

/*
 * Agrega interceptos para NProgress
 */
const options = {
  minimum: 0.15,
  showSpinner: false,
}
NProgress.configure(options)

apiClient.interceptors.request.use(
  function (config) {
    NProgress.start()
    return config
  },
  function (error) {
    NProgress.done()
    return Promise.reject(error)
  }
)
apiClient.interceptors.response.use(
  function (response) {
    NProgress.done()
    return response
  },
  function (error) {
    NProgress.done()
    return Promise.reject(error)
  }
)

/*
 * Agrega el header Authorization con las credenciales JWT
 */
apiClient.interceptors.request.use(
  function (config) {
    const jwt = window.localStorage.getItem('jwt')
    if (jwt) {
      config.headers.Authorization = `Bearer ${jwt}`
    }

    // Si el token está próximo a expirar, solicito un token actualizado desde el servidor
    const tokenExpiresAt = window.localStorage.getItem('jwt_expires_at')
    const segundosParaExpirar = dayjs(tokenExpiresAt).diff(dayjs(), 'second')
    if (
      segundosParaExpirar <= process.env.VUE_APP_TTL_RENEW_JWT &&
      !config.url.startsWith('/usuarios/auth')
    ) {
      console.log('Token próximo a expirar: %s s.', segundosParaExpirar)
      store.dispatch('auth/refresh')
    }

    return config
  },
  function (error) {
    // return Promise.reject(error)
    return Promise.reject(error.response)
  }
)

/*
 * Add a response interceptor
 */
apiClient.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (
      error.response &&
      [401, 419].includes(error.response.status) &&
      error.response.config.url !== '/usuarios/auth/login'
    ) {
      // TODO: Hacerlo una sola vez
      store.dispatch('auth/logout')
    }
    return Promise.reject(error)
  }
)
