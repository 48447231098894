import Vue from 'vue'
import dayjs from '@/plugins/dayjs'

// const mesCorto = ['', 'ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic']

/*
const util = {
  cloneObject: (o) => JSON.parse(JSON.stringify(o)),
  arrayToItems (array) {
    return Object.entries(array).map(([k, v]) => ({ text: v, value: k }))
  },
}
*/

export const formato = {
  capitalizar(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  },

  dv(rut) {
    rut = parseInt(('' + rut).replaceAll('.', ''), 10)
    let m = 0
    let s = 1
    for (; rut; rut = Math.floor(rut / 10)) {
      s = (s + (rut % 10) * (9 - (m++ % 6))) % 11
    }
    return s ? s - 1 : 'K'
  },

  /**
   * Muestra una fecha relativa (ej: Hace 1 día) o una fecha absoluta (ej: 14/09/2019)
   *
   * @param date Fecha
   * @param umbral Number umbral de tiempo para mostrar fecha relativa o fecha absoluta
   * @param unidad String Unidad de medida del umbral, ej: 'hour', 'day', 'minute'
   * @param format String formato de fechas que sobrepasan el umbral
   * @returns {string|*}
   */
  fechaFromNow(date, umbral = 1, unidad = 'day', format = 'L') {
    if (!date) {
      return ''
    }
    const day = dayjs(date)
    return dayjs().tz().diff(day, unidad) <= umbral ? day.fromNow() : day.tz().format(format)
  },

  fecha(date, format = 'DD/MM/YYYY', emptyValue = '') {
    return date ? dayjs(date).tz().format(format) : emptyValue
  },

  fechaEnStgo(date, format = 'DD/MM/YYYY', emptyValue = '') {
    return date ? dayjs.tz(date).format(format) : emptyValue
  },

  fechaHora(date, emptyValue = '') {
    console.log(date);
    return date ? dayjs(date).tz().format('DD/MM/YYYY HH:mm') : emptyValue
  },

  fechaHoraSegundos(date, emptyValue = '') {
    return date ? dayjs(date).tz().format('DD/MM/YYYY HH:mm:ss') : emptyValue
  },

  /**
   * Da formato de moneda aplicando redondeo ej: $12.345
   *
   * @param value
   * @param emptyValue
   * @returns {string}
   */
  moneda(value, emptyValue = '') {
    return this.numero(Math.round(0 + value), '$', emptyValue)
  },
  /**
   * Da formato a moneda UTM
   *
   * @param value
   * @param emptyValue
   * @returns {string}
   */
  utm(value, emptyValue = '') {
    return value ? value + ' UTM' : emptyValue
  },
  /**
   * Normaliza el string para poder hacer comparaciones.
   * Quita acentos, pasa a minúsculas, elimina otros caracteres especiales.
   *
   * @param value
   * @returns {string}
   */
  normalizar(value) {
    return !value
      ? ''
      : value
          .toString()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/[.,-]/g, '')
          .toLocaleLowerCase()
  },

  /**
   * Da formato de número
   *
   * @param value
   * @param prefix
   * @param emptyValue
   * @returns {string|string}
   */
  numero(value, prefix = '', emptyValue = '') {
    return value || value === 0 ? prefix + new Intl.NumberFormat('de').format(value) : emptyValue
  },

  /**
   * Da formato de porcentaje a un decimal. Ej: 0.12 => 12 %
   *
   * @param value
   */
  porcentaje(value) {
    return Math.round(parseFloat(value) * 100) + ' %'
  },

  /**
   * Formatea un numero y agrega un texto a continuación en singular o plural
   *
   * @param value
   * @param singular String Texto para value == 1
   * @param plural String Texto para value > 1
   * @param vacio String Texto para value == 0
   * @returns {string}
   */
  textoSingularPlural(value, singular, plural, vacio = '') {
    return this.numero(value) + (value ? (value === 1 ? ` ${singular}` : ` ${plural}`) : vacio)
  },

  /**
   * Da formato de RUT, ej: 10.128.660-6
   *
   * @param value
   * @param emptyValue
   * @returns {string|string}
   */
  rut(value, emptyValue = '') {
    if (!value) {
      return emptyValue
    }

    // Obtiene parte numérica del RUT
    value = value
      .toString()
      .replaceAll(/[^0-9-]/g, '')
      .replace(/-.*$/, '')

    return value ? this.numero(value) + '-' + this.dv(value) : emptyValue
  },

  /**
   * Formatea el login.
   *
   * En la mayoría de los casos se trata de un RUT. Pero para
   * las API, el login será un string largo
   */
  login(value, emptyValue = '') {
    if (!value) {
      return emptyValue
    }
    return value.toString().length <= 15 ? this.rut(value) : value
  },

  placaPatente(value) {
    return value
      .toString()
      .toUpperCase()
      .replace(/(\w)(\d)/, '$1-$2')
  },
}

Vue.use({
  install(Vue) {
    Vue.prototype.$formato = formato
  },
})

export default formato
