<template>
  <div>
    <v-row class="text-center">
      <v-col class="mb-4">
        <p class="text-h2">Bienvenido a</p>
        <logo-sem-color style="max-width: 250px" class="mx-auto" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LogoSemColor from '@/components/Icons/LogoSemColor'
export default {
  components: {
    LogoSemColor,
  },
}
</script>
