<template>
  <div>
    <v-row>
      <v-col>
        <z-input
          v-model="form.tipo"
          :error-messages="errors.tipo"
          label="Tipo"
          tipo="select"
          :items="payloadItemsTiposInstitucion"
        />
      </v-col>
      <v-col v-if="form.tipo > 2" cols="6">
        <z-input
          v-model="form.municipalidad_id"
          :error-messages="errors.municipalidad_id"
          label="Comuna"
          tipo="autocomplete"
          :items="payloadItemsMunicipalidades"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <z-input
          v-model="form.nombre"
          :error-messages="errors.nombre"
          label="Nombre"
          minlength="3"
        />
      </v-col>

      <v-col v-if="form.tipo === 3" cols="3">
        <z-input
          v-model="form.numero_jpl"
          :error-messages="errors.numero_jpl"
          tipo="select"
          :items="[
            { text: '1º', value: 1 },
            { text: '2º', value: 2 },
            { text: '3º', value: 3 },
            { text: '4º', value: 4 },
            { text: '5º', value: 5 },
          ]"
          label="Número JPL"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <z-input v-model="form.id" :error-messages="errors.id" label="Rut institución o id JPL (código territorial + nro. JPL)" required />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <z-input v-model="form.direccion" :error-messages="errors.direccion" label="Dirección" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <z-input
          v-model="form.codigo_subdere"
          :error-messages="errors.codigo_subdere"
          label="Código Subdere"
        />
      </v-col>
      <v-col>
        <z-input
          v-model="form.codigo_esign"
          :error-messages="errors.codigo_esign"
          label="Código E-sign"
        />
      </v-col>
      <v-col>
        <z-input v-model="form.codigo_tgr" :error-messages="errors.codigo_tgr" label="Código TGR" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <z-input v-model="form.email" :error-messages="errors.email" label="Email" />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="form.tipo === 1" cols="6">
        <z-input v-model="form.telefono" :error-messages="errors.telefono" label="Teléfono" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ZInput from '@/components/Input/ZInput'

export default {
  components: { ZInput },
  props: {
    accion: {
      type: String,
    },
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    form: {
      get: function () {
        console.log(this.value)
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
