<template>
  <div
    style="height: calc(100vh - 200px); min-height: 360px"
    class="d-flex align-center justify-center"
  >
    <v-card
      :elevation="$vuetify.breakpoint.xsOnly ? 0 : $vuetify.theme.currentTheme.elevation"
      class="mx-auto pb-6"
      :class="{
        'mt-16': $vuetify.breakpoint.mdAndUp,
        'mt-8': $vuetify.breakpoint.sm,
        'mt-2': $vuetify.breakpoint.xs,
      }"
      outlined
      width="400"
    >
      <barra-superior-gobierno-de-chile class="ml-4" />

      <v-card-text>
        <div class="text-center">
          <logo-sem-color :style="{ width: $vuetify.breakpoint.xsOnly ? '150px' : '250px' }" />
        </div>
      </v-card-text>

      <v-form
        ref="form"
        v-model="valid"
        class="mx-auto"
        style="max-width: 330px"
        @submit.prevent="login"
      >
        <v-card-text class="px-12 py-0">
          <v-text-field
            v-model="form.login"
            :error-messages="error && error.login"
            :rules="[
              (v) => !!v || 'Debes ingresar tu RUT',
              (v) => (v && v.length >= 3) || 'Debes ingresar un RUT válido',
            ]"
            autocomplete="username"
            autocorrect="off"
            name="username"
            label="Ingresa tu RUT"
            maxlength="50"
            minlength="3"
            outlined
            required
            class="autofillWhite"
            data-testid="login"
          />
          <v-text-field
            v-model="form.password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :error-messages="error && error.password"
            :rules="[
              (v) => !!v || 'Debes ingresar tu clave',
              (v) => (v && v.length >= 3) || 'Clave demasiada corta',
            ]"
            :type="showPassword ? 'text' : 'password'"
            autocomplete="current-password"
            label="Ingresa tu clave"
            maxlength="50"
            minlength="3"
            outlined
            required
            data-testid="password"
            @click:append="showPassword = !showPassword"
          />
        </v-card-text>
        <v-card-text v-if="typeof error === 'string'" class="px-12 my-n4">
          <flash-message :error="error" />
        </v-card-text>
        <v-card-actions class="d-flex flex-column justify-space-between">
          <v-btn large type="submit" color="primary" :loading="loading" width="160">
            iniciar sesión
          </v-btn>
        </v-card-actions>
      </v-form>
      <!--
      <v-card-actions class="px-12 mr-n2">
        <v-spacer />
        <v-btn
          color="secondary"
          small
          text
        >
          Olvidé mi contraseña
        </v-btn>
      </v-card-actions>
      -->
    </v-card>
  </div>
</template>

<script>
import BarraSuperiorGobiernoDeChile from '@/components/Icons/BarraSuperiorGobiernoDeChile'
import FlashMessage from '@/components/FlashMessage'
import { mapGetters } from 'vuex'
import LogoSemColor from '@/components/Icons/LogoSemColor'

export default {
  name: 'Login',
  components: { LogoSemColor, FlashMessage, BarraSuperiorGobiernoDeChile },
  data() {
    return {
      error: null,
      valid: true,
      showPassword: false,
      form: {
        login: '',
        password: '',
        admin:true
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['loggedIn']),
    ...mapGetters('app', ['loading']),
  },
  beforeCreate() {
    if (this.loggedIn) {
      this.$router.push({ path: '/dashboard' })
    }
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('auth/login', this.form)
          .then(() => this.$router.push('/dashboard'))
          .catch((error) => {
            this.error = error
          })
      }
    },
  },
}
</script>

<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}
</style>
