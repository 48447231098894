<template>
  <div>
    <v-row>
      <v-col v-if="accion === 'create'" cols="12">
        <z-input
          v-model="form.institucion_id"
          :error-messages="errors.institucion_id"
          :items="institucionesDisponibles"
          tipo="autocomplete"
          label="Institución"
        />
      </v-col>
      <v-col>
        <z-input
          v-model="form.nombre"
          :error-messages="errors.nombre"
          label="Departamento o unidad"
          hint="Ejemplo: Dirección de tránsito"
        />
      </v-col>
      <v-col v-if="form.institucion_id">
        <input-firmador
          v-model="form.firmador"
          :institucion-id="form.institucion_id"
          :error-messages="errors.firmador"
          clearable
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <z-input
          v-model="form.direccion"
          :error-messages="errors.direccion"
          label="Dirección"
          minlength="3"
          hint="Ejemplo: Las Perdices 456"
        />
      </v-col>
      <v-col>
        <z-input
          v-model="form.telefono"
          :error-messages="errors.telefono"
          label="Teléfono"
          hint="Ejemplo: (2) 5551234"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <z-input-fecha-hora
          v-model="form.fecha_desde"
          :error-messages="errors.fecha_desde"
          label="Pago disponible desde"
          clearable
          dense
          outlined
        />
      </v-col>
      <v-col cols="6">
        <z-input-fecha-hora
          v-model="form.fecha_hasta"
          :error-messages="errors.fecha_hasta"
          label="Pago disponible hasta"
          clearable
          dense
          outlined
        />
      </v-col>
    </v-row>

    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header> Notificaciones E-MAIL </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col>
              <z-input
                v-model="form.notificacion_email"
                :error-messages="errors.notificacion_email"
                label="Correos electrónicos"
                hint="Puedes separar varias casillas usando punto y coma (;)."
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header> Notificaciones REST </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col>
              <z-input
                v-model="form.notificacion_rest_url"
                :error-messages="errors.notificacion_rest_url"
                label="URL"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <z-input
                v-model="form.notificacion_rest_password"
                :error-messages="errors.notificacion_rest_password"
                label="Contraseña"
                hint="Contraseña que será enviada en las notificaciones REST"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header> Notificaciones SOAP (antiguo) </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col>
              <z-input
                v-model="form.notificacion_soap_url"
                :error-messages="errors.notificacion_soap_url"
                label="URL"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <z-input
                v-model="form.notificacion_soap_password"
                :error-messages="errors.notificacion_soap_password"
                label="Contraseña"
                hint="Contraseña que será enviada en las notificaciones SOAP"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import ZInput from '@/components/Input/ZInput'
import ZInputFechaHora from '@/components/Input/ZInputFechaHora'
import InputFirmador from '@/admin/components/Input/InputFirmador'

export default {
  components: { InputFirmador, ZInputFechaHora, ZInput },
  props: {
    accion: {
      type: String,
    },
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    instituciones: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    form: {
      get: function () {
        console.log(this.value)
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },
    institucionesDisponibles() {
      return this.accion === 'edit'
        ? this.payloadItemsInstituciones
        : this.payloadItemsInstituciones?.filter((i) => !this.instituciones.includes(i.value))
    },
  },
}
</script>
