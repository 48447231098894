<template>
  <v-dialog-transition>
    <v-alert v-if="message" type="info" class="text-body-2" outlined text>
      {{ message }}
    </v-alert>

    <v-alert
      v-if="error && getType(error) === 'string'"
      type="error"
      class="text-body-2"
      outlined
      text
    >
      {{ error }}
    </v-alert>

    <v-alert
      v-if="error && getType(error) === 'object'"
      transition="scale-transition"
      type="error"
      class="text-body-2"
      outlined
      text
    >
      <ul>
        <li v-for="key in errorKeys" :key="key">
          <b class="font-bold capitalize">{{ key | titleCase }}</b>
          <ul>
            <li v-for="(item, index) in getErrors(key)" :key="`${index}-error`">
              {{ item }}
            </li>
          </ul>
        </li>
      </ul>
    </v-alert>
  </v-dialog-transition>
</template>

<script>
export default {
  name: 'FlashMessage',
  filters: {
    titleCase(value) {
      return value.replace('_', ' ')
    },
  },
  props: {
    message: {
      type: String,
      default: null,
    },
    error: {
      type: [Object, String],
      default: null,
    },
  },
  computed: {
    errorKeys() {
      if (!this.error || this.getType(this.error) === 'string') {
        return null
      }
      return Object.keys(this.error)
    },
  },
  methods: {
    getErrors(key) {
      return this.error[key]
    },
    getType(obj) {
      return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase()
    },
  },
}
</script>
