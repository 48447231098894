<template>
  <div>
    <z-form-search
      v-model="items"
      v-bind="formSearchProps"
      :buscador-visible="buscadorVisible"
      :custom-filter="customFilter"
      :data-table-props="dataTablePropsComputed"
      :headers="headersComputed"
      :ocultar-textos-hits="ocultarTextosHits || !$scopedSlots.buscador"
      :on-after-search="afterSearch"
      :on-before-search="beforeSearch"
      :search-inicial="searchInicial"
      :texto-buscando="textoBuscando"
      :texto-filtrar="textoFiltrar"
      :texto-hits="textoHits"
      :texto-no-hit="textoNoHit"
      :texto-un-hit="textoUnHit"
      :titulo="titulo"
      :url="searchUrl || url"
      :query="query"
      :method="searchMethod"
      :timeout="timeout"
      buscador-colapsable
      @buscadorVisible="buscadorVisible = $event"
    >
      <template v-if="!!$scopedSlots.buscador" #buscador="{ loading: loadingSlot, search }">
        <!--
       @slot Formulario de búsqueda. Debe invocar la función `search` para realizar una búsqueda.
         @binding {Boolean} loading Indica que se está realizando una búsqueda. Sirve por ejemplo para bloquear
         el botón de búsqueda.
         @binding {Function} search Función para ejecutar la búsqueda.
         @binding {Function} create Función para abrir el formulario de creación. Recibe como parámetro opcional un object con valores por defecto
       -->
        <slot :create="createItem" :loading="loadingSlot" :search="search" name="buscador" />
      </template>

      <template
        v-if="!!$scopedSlots.cabecera"
        #cabecera="{ loading: loadingSlot, search, items: itemsSlot }"
      >
        <!--
       @slot Contenido anterior a resultados de búsqueda y después de formulario
          @binding {Boolean} loading Indica que se está haciendo un request
          @binding {Function} search Función que gatilla el request
          @binding {Array} Arreglo con los resultados de búsqueda
       -->
        <slot name="cabecera" :loading="loadingSlot" :search="search" :items="itemsSlot" />
      </template>

      <template
        v-if="!!$scopedSlots.cabeceraInterior"
        #cabeceraInterior="{ search, items: itemsSlot }"
      >
        <!--
         @slot Contenido superior izquierdo. Usualmente usado para un botón "crear"
            @binding {Function} search Función que gatilla el request
            @binding {Array} Arreglo con los resultados de búsqueda
         -->
        <slot name="cabeceraInterior" :search="search" :items="itemsSlot" />
      </template>

      <template v-if="!!$scopedSlots.item" #item="{ item }">
        <!--
         @slot Definición de toda la fila del datatable.
           @binding {Object} headers Definicicón de los headers
           @binding {Object} item Objeto de la fila actual
           @binding {Function} viewItem Función para visualizar, recibe como argument el item, ej: `viewItem(item)`
           @binding {Function} editItem Función para editar, recibe como argument el item, ej: `editItem(item)`
           @binding {Function} deleteItem Función para eliminar, recibe como argument el item, ej: `editItem(item)`
           @binding value Valor de la celda
         -->
        <slot
          :delete-item="deleteItem"
          :edit-item="editItem"
          :headers="headers"
          :item="item"
          :view-item="viewItem"
          name="item"
        />
      </template>

      <template v-for="header in headers" #[`item.${header.value}`]="{ item, value: val }">
        <!--
        @slot Definición de celda del datatable, en el formato de nombre `item.campo`.
          @binding {Object} header Definicicón del header
          @binding {Object} item Objeto de la fila actual
          @binding {Function} viewItem Función para visualizar, recibe como argument el item, ej: `viewItem(item)`
          @binding {Function} editItem Función para editar, recibe como argument el item, ej: `editItem(item)`
          @binding {Function} deleteItem Función para eliminar, recibe como argument el item, ej: `editItem(item)`
          @binding value Valor de la celda
        -->
        <slot
          :delete-item="deleteItem"
          :edit-item="editItem"
          :header="header"
          :item="item"
          :name="`item.${header.value}`"
          :value="val"
          :view-item="viewItem"
        />
      </template>

      <template #item._acciones_mantenedor="{ item }">
        <div class="text-no-wrap">
          <template v-for="boton in botonesListar.filter((b) => posicionBoton(b) === 'izquierda')">
            <z-btn
              v-if="!boton.hasOwnProperty('visible') || boton.visible(item)"
              :key="boton.title"
              :color="boton.color || 'grey darken-1'"
              :disabled="!!boton.disabled && boton.disabled(item)"
              :icon="boton.icon"
              :title="boton.title"
              @click="boton.click(item)"
            />
          </template>
          <z-btn
            v-if="!disableView"
            color="grey darken-1"
            icon="mdi-eye-outline"
            title="Ver"
            @click="viewItem(item)"
          />
          <z-btn
            v-if="!disableEdit"
            v-show="visibleEdit(item)"
            :disabled="disabledEdit(item)"
            color="grey darken-1"
            icon="mdi-square-edit-outline"
            title="Editar"
            @click="editItem(item)"
          />
          <z-btn
            v-if="!disableDelete"
            v-show="visibleDelete(item)"
            :disabled="disabledDelete(item)"
            color="grey darken-1"
            icon="mdi-trash-can-outline"
            title="Eliminar"
            @click="deleteItem(item)"
          />
          <template v-for="boton in botonesListar.filter((b) => posicionBoton(b) === 'derecha')">
            <z-btn
              v-if="!boton.hasOwnProperty('visible') || boton.visible(item)"
              :key="boton.title"
              :color="boton.color || 'grey darken-1'"
              :disabled="!!boton.disabled && boton.disabled(item)"
              :icon="boton.icon"
              :title="boton.title"
              @click="boton.click(item)"
            />
          </template>
          <v-menu
            v-if="botonesListar.filter((b) => posicionBoton(b) === 'menu').length > 0"
            offset-y
          >
            <template #activator="{ on, attrs }">
              <v-btn v-bind="attrs" color="grey darken-1" icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <template v-for="boton in botonesListar.filter((b) => posicionBoton(b) === 'menu')">
                <v-list-item
                  v-if="!boton.hasOwnProperty('visible') || boton.visible(item)"
                  :key="boton.title"
                  :disabled="!!boton.disabled && boton.disabled(item)"
                  @click="boton.click(item)"
                >
                  <v-list-item-title>{{ boton.title }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </div>
      </template>
    </z-form-search>

    <!--

        Dialog con detalles del documento

    -->
    <z-dialog
      v-if="dialogVisible"
      v-model="itemActivo"
      :boton-cerrar="{ create: 'Cancelar' }[accion]"
      :boton-principal="{ create: 'Crear', edit: 'Guardar' }[accion]"
      :boton-principal-disabled="zMantenedorBotonPrincipalDisabled || !formValid"
      :botones="zMantenedorBotones"
      :botones-principales="zMantenedorBotonesPrincipales"
      :campo-subtitulo="zMantenedorCampoSubtitulo"
      :campo-titulo="zMantenedorCampoTitulo"
      :fullscreen="fullscreen"
      :height="height"
      :items="items"
      :navegacion="navegacion"
      :loading="loading || loadingDialog"
      :max-width="maxWidth"
      :width="width"
      @cerrar="ocultarDialog"
      @boton-principal="submit"
      @item-cambiado="actualizarItemEditable"
    >
      <template #default="{ item }">
        <!--

        @slot Formulario de edición de item.
        Por ejemplo, para un campo `nombre`, el formulario sería así:

        <z-input
          v-model="form.nombre"
          :error-messages="errors.nombre"
          label="Nombre"
        />

        @binding {String} accion Indica si el formulario es para creación o edición: `create` o `edit`.
        @binding {Object} errors Objeto con arreglo de errores por cada campo. En caso de un error general (ej: una Exception) se incluirá el texto en el atributo `_message`.
        @binding {Object} form Item Objeto con atributos para editar.

        -->
        <v-form
          v-if="accion === 'edit' || accion === 'create'"
          ref="zMantenedorForm"
          v-model="formValid"
          lazy-validation
        >
          <slot :accion="accion" :errors="errors" :form="form" name="form">
            <div v-for="(val, campo) in item" :key="campo">
              <z-input
                v-if="
                  typeof val !== 'object' && !['id', 'created_at', 'updated_at'].includes(campo)
                "
                v-model="form[campo]"
                :error-messages="errors[campo]"
                :label="campoALabel(campo)"
                :tipo="typeof val == 'boolean' ? 'checkbox' : 'text'"
              />
            </div>
          </slot>
        </v-form>

        <!--

        @slot Visualización de item
        Por ejemplo, para un campo `nombre`, la visualización sería así:

        Nombre: {{ item.nombre }}

        @binding {Object} item Item

        -->
        <slot v-else :item="item" name="view">
          <v-simple-table>
            <tbody>
              <tr v-for="(val, campo) in item" :key="campo">
                <template v-if="val !== '' && val !== null">
                  <th style="max-width: 130px">
                    {{ campoALabel(campo) }}
                  </th>
                  <td v-if="campo === 'payload'" class="text-caption text-pre">
                    {{ formatValue(val, campo) }}
                  </td>
                  <td v-else>
                    {{ formatValue(val, campo) }}
                  </td>
                </template>
              </tr>
            </tbody>
          </v-simple-table>
        </slot>
      </template>
    </z-dialog>
  </div>
</template>

<script>
import ZFormSearch from '@/components/ZFormSearch'
import ZBtn from '@/components/ZBtn'
import ZDialog from '@/components/ZDialog'
import CrudService from '@/admin/services/CrudService'
import { getError } from '@/utils/errors'
import ZInput from '@/components/Input/ZInput'

/**
 * Componente para generar mantenedores básicos en base a operaciones CRUD REST.
 *
 * Internamente utiliza [`z-form-search`](/#zformsearch) y
 * [`z-dialog`](/#zdialog) por lo que se recomienda leer
 * su documentación para tener una visión más completa del comportamiento del componente.
 *
 * Además, para la construcción de los formularios de edición, se recomienda
 * ver la documentación de [`z-input`](/#input).
 *
 */
export default {
  components: { ZInput, ZDialog, ZBtn, ZFormSearch },
  props: {
    value: {
      type: Array,
      required: true,
    },

    /**
     * URL de la API a consultar.
     *
     * Por ejemplo:
     *
     * ```
     * http://example.com/api/v1/usuarios
     * ```
     */
    url: {
      type: String,
      required: true,
    },

    /**
     * URL alternativa para la ejecución de búsquedas
     *
     * Por ejemplo:
     *
     * ```
     * http://example.com/api/v1/usuarios/search
     * ```
     */
    searchUrl: {
      type: String,
      default: null,
    },

    /**
     * Argumentos que se agregarán al query string de la URL al buscar.
     */
    query: {
      type: Object,
      default: () => ({}),
    },

    /**
     * Indica si la búsqueda se realizará por GET o POST.
     */
    searchMethod: {
      type: String,
      default: 'get',
    },

    /**
     * Indica segundos antes de dar timeout de la busqueda.
     */
    timeout: {
      type: [Number, String],
      default: 30,
    },

    /**
     * Arreglo de objetos con definición de columnas para `<v-data-table>`.
     * Se debe definir al menos `text` y `value` por cada columna, ej:
     *
     * ```
     * [
     *   { text: 'Nombre', value: 'name' },
     *   { text: 'Correo electrónico', value: 'email' }
     * ]
     * ```
     *
     * #### Parámetros adicionales
     *
     * Puedes revisar la documentación de `z-form-search` para ver los
     * parámetros de configuración adicionales. Por ejemplo:
     *
     * `formatter` permite definir una función
     * de formateo de los datos, por ejemplo:
     *
     * ```
     * formatter: (value, item) => this.$formato.rut(value)
     * ```
     *
     * `visible` Define si la columna será visible. `true` por defecto.
     *
     * ```
     * visible: false
     * ```
     *
     */
    headers: {
      type: Array,
      required: true,
    },

    /**
     * Título para columna de acciones
     */
    textHeaderAcciones: {
      type: String,
      default: '',
    },

    /**
     * Deshabilita crear. Aún no implementado.
     */
    disableCreate: {
      type: Boolean,
      default: false,
    },

    /**
     * Deshabilita ver
     */
    disableView: {
      type: Boolean,
      default: false,
    },

    /**
     * Deshabilita editar
     */
    disableEdit: {
      type: Boolean,
      default: false,
    },

    /**
     * Deshabilita el icono editar por cada fila
     */
    disabledEdit: {
      type: Function,
      default: () => false,
    },

    /**
     * Oculta el icono editar por cada fila
     */
    visibleEdit: {
      type: Function,
      default: () => true,
    },

    /**
     * Deshabilita eliminar
     */
    disableDelete: {
      type: Boolean,
      default: false,
    },

    /**
     * Deshabilita el icono eliminar por cada fila
     */
    disabledDelete: {
      type: Function,
      default: () => false,
    },

    /**
     * Oculta el icono eliminar por cada fila
     */
    visibleDelete: {
      type: Function,
      default: () => true,
    },

    /**
     * Título del buscador.
     */
    titulo: {
      type: String,
      default: undefined,
    },

    /**
     * Realiza una búsqueda inicial antes de montar el componente
     */
    searchInicial: {
      type: Boolean,
      default: false,
    },

    /**
     * Flag indicando si se permite navegar entre los items anterior y siguiente.
     * Limitaciones:
     * - En caso que el datatable permita reordenar, la navegación no
     * reconocerá dicho cambio de orden.
     * - Se recomienda no habilitarlo para edición  y permitirlo sólo para visualización,
     * ya que puede confundir al usuario tener una edición y poder cambiar de datos sin guardar.
     */
    navegacion: {
      type: Boolean,
      default: false,
    },

    /**
     * Texto cuando se realiza la búsqueda
     */
    textoBuscando: {
      type: String,
      default: 'Buscando...',
    },
    /**
     * Texto que habilita el filtrado de los elementos del data-table
     */
    textoFiltrar: {
      type: String,
      default: undefined,
    },
    /**
     * Texto cuando la búsqueda no arrojó resultados.
     */
    textoNoHit: {
      type: String,
      default: 'No se encontraron resultados.',
    },
    /**
     * Texto cuando hay un resultado encontrado.
     */
    textoUnHit: {
      type: String,
      default: 'Un resultado.',
    },
    /**
     * Texto indicando la cantidad de resultados encontrados.
     */
    textoHits: {
      type: String,
      default: '%s resultados.',
    },

    /**
     * Deshabilita los textos de resultados de búsqueda.
     */
    ocultarTextosHits: {
      type: Boolean,
      default: false,
    },

    /**
     * Campo a utilizar como título en los dialog.
     * Se puede indicar una función.
     */
    campoTituloDialog: {
      type: [String, Function],
      default: 'nombre',
    },

    /**
     * Campo a utilizar como subtítulo en los dialog.
     * Se puede indicar una función.
     */
    campoSubtituloDialog: {
      type: [String, Function],
      required: false,
    },

    /**
     * Campo a utilizar como título en los dialog al crear.
     */
    campoTituloDialogCrear: {
      type: [String, Function],
      default: 'Crear',
    },

    /**
     * Campo a utilizar como subtítulo en los dialog al crear.
     */
    campoSubtituloDialogCrear: {
      type: [String, Function],
      required: false,
    },

    /**
     * Botones adicionales al visualizar un registro
     */
    botonesVer: {
      type: Array,
      default: () => [],
    },

    /**
     * Deshabilita los botones autogenerados en el diálogo
     */
    disableDefaultBotonesDialog: {
      type: Boolean,
      default: false,
    },

    /**
     * Botones adicionales al crear un registro
     *
     * Es posible definir funciones para:
     *
     * - `visible(item)`: Boolean indicando si el botón será visible
     * - `disabled(item)`: Boolean indicando si el botón será disabled
     *
     */
    botonesCrear: {
      type: Array,
      default: () => [],
    },

    /**
     * Botones adicionales al editar un registro
     *
     * Es posible definir funciones para:
     *
     * - `visible(item)`: Boolean indicando si el botón será visible
     * - `disabled(item)`: Boolean indicando si el botón será disabled
     *
     */
    botonesEditar: {
      type: Array,
      default: () => [],
    },

    /**
     * Botones adicionales al listado que se pondrán junto a los botones por defecto.
     * Los botones pueden usar ell atributo `posicion` para indicar dónde posicionar el botón:
     *
     * - `izquierda` Por defecto
     * - `derecha`
     * - `menu`
     *
     * Además es posible definir funciones para:
     *
     * - `visible(item)`: Boolean indicando si el botón será visible
     * - `disabled(item)`: Boolean indicando si el botón será disabled
     *
     */
    botonesListar: {
      type: Array,
      default: () => [],
    },

    /**
     * Permite deshabilitar el botón principal al crear, por ejemplo cuando hay un formulario inválido.
     */
    botonPrincipalCrearDisabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Permite deshabilitar el botón principal al editar, por ejemplo cuando hay un formulario inválido.
     */
    botonPrincipalEditarDisabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Botones principales al crear
     */
    botonesPrincipalesCrear: {
      type: Array,
      default: () => [],
    },
    /**
     * Botones principales al editar
     */
    botonesPrincipalesEditar: {
      type: Array,
      default: () => [],
    },

    /**
     * Botones principales al ver
     */
    botonesPrincipalesVer: {
      type: Array,
      default: () => [],
    },

    /**
     * Parámetros que se pasarán a `v-data-table`, por ejemplo:
     *
     * @example
     * ```js
     * {
     *   itemsPerPage: 20,
     *   itemKey: 'id',
     *   showSelect: true,
     *   disableSort: true,
     * }
     * ```
     *
     * @see https://vuetifyjs.com/en/components/data-tables/
     */
    dataTableProps: {
      type: Object,
      default: () => ({}),
    },

    /**
     * Filtrado adicional de filas, será llamado por cada campo y registro,
     * retornando `true` para incluir el item. Recibe los parámetros:
     *
     * - value
     * - search
     * - item
     */
    customFilter: {
      type: Function,
    },

    /**
     * Parámetros adicionales que se pasarán a `z-form-search`.
     *
     */
    formSearchProps: {
      type: Object,
      default: () => ({}),
    },

    /**
     * Función invocada antes de una búsqueda.
     * Recibe el objeto con los parámetros de búsqueda.
     * Si retorna un Boolean `false`, se abortará el request.
     */
    onBeforeSearch: {
      type: Function,
      default: null,
    },

    /**
     * Función invocada después de una búsqueda.
     * Recibe el objeto response.
     * Debe retornar un array de items o `null` para no modificar el response.
     */
    onAfterSearch: {
      type: Function,
      default: null,
    },

    /**
     * Función llamada antes de crear/editar un registro
     * Recibe:
     *  - objeto a guardar
     *  - string indicando 'store' o 'update'.
     *  - objeto de campos del formulario (permite modificar algo del form, en caso de cancelar el submit)
     *
     * Si retorna `false` el submit será cancelado.
     *
     */
    onBeforeSave: {
      type: Function,
      default: (v) => v,
    },

    /**
     * Función llamada antes de edilinar un registro
     * Recibe el objeto a eliminar.
     *
     * Debe retornar `true` para eliminar o `false` para abortar la eliminación.
     *
     */
    onBeforeDelete: {
      type: Function,
      default: (v) => true,
    },

    /**
     * Función llamada después de crear/editar un registro
     * Recibe el objeto retornado por el servidor y un string indicando 'store' o 'update'.
     * Es posible modificar el contenido del objeto antes de ser insertado en el data-table.
     */
    onAfterSave: {
      type: Function,
      default: (v) => v,
    },

    /**
     * Función llamada después de eliminar un registro
     * Recibe el objeto retornado por el servidor.
     */
    onAfterDelete: {
      type: Function,
      default: (v) => v,
    },

    /**
     * Promesa que se ejecutará antes de ver un registro.
     * Permite por ejemplo hacer un request HTTP con datos requeridos
     */
    onBeforeDialogView: {
      type: Function,
      default: (item) => new Promise((resolve, reject) => resolve(item)),
    },

    /**
     * Promesa que se ejecutará antes de crear un registro.
     * Permite por ejemplo hacer un request HTTP con datos requeridos
     */
    onBeforeDialogCreate: {
      type: Function,
      default: (item) => new Promise((resolve, reject) => resolve(item)),
    },

    /**
     * Promesa que se ejecutará antes de editar un registro.
     * Permite por ejemplo hacer un request HTTP con datos requeridos
     */
    onBeforeDialogEdit: {
      type: Function,
      default: (item) => new Promise((resolve, reject) => resolve(item)),
    },

    /**
     * Ancho máximo del dialog
     */
    maxWidth: {
      type: [String, Number],
      default: 600,
    },

    /**
     * Ancho del dialog
     */
    width: {
      type: [String, Number],
      default: undefined,
    },

    /**
     * Alto del dialog
     */
    height: {
      type: [String, Number],
      default: undefined,
    },

    /**
     * Diálogo en pantalla completa
     * (En tamaño XS será siempre en pantalla completa)
     */
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 'create', 'edit', 'view'
      accion: '',
      loading: false,
      loadingDialog: false,
      formValid: true,
      form: {},
      buscadorVisible: true,

      /** Diálogo */
      dialogVisible: false,

      // items: [],
      itemActivo: {},
      errors: {},
    }
  },
  computed: {
    items: {
      get: function () {
        return this.value
      },
      set: function (value) {
        /**
         * Resultado de búsqueda actualizado
         * @property {Array} value Nuevos valores
         */
        this.$emit('input', value)
      },
    },

    headersComputed() {
      return this.headers.concat({
        text: this.textHeaderAcciones,
        value: '_acciones_mantenedor',
        align: 'end',
        filterable: false,
        sortable: false,
      })
    },
    botonesDialogoVer() {
      const botones = [...this.botonesVer.filter((b) => this.posicionBoton(b) === 'izquierda')]
      if (!this.disableDefaultBotonesDialog) {
        if (!this.disableEdit) {
          botones.push({
            title: 'Editar',
            icon: 'mdi-square-edit-outline',
            click: (item) => this.editItem(item),
            visible: this.visibleEdit,
            disabled: this.disabledEdit,
          })
        }
        if (!this.disableDelete) {
          botones.push({
            title: 'Eliminar',
            icon: 'mdi-trash-can-outline',
            click: (item) => this.deleteItem(item),
            visible: this.visibleDelete,
            disabled: this.disabledDelete,
          })
        }
      }
      return [...botones, ...this.botonesVer.filter((b) => this.posicionBoton(b) === 'derecha')]
    },
    botonesDialogoCrear() {
      return [...this.botonesCrear]
    },
    botonesDialogoEditar() {
      const botones = [...this.botonesEditar.filter((b) => this.posicionBoton(b) === 'izquierda')]
      if (!this.disableDefaultBotonesDialog) {
        if (!this.disableView) {
          botones.push({
            title: 'Ver',
            icon: 'mdi-eye-outline',
            click: (item) => this.viewItem(item),
          })
        }
        if (!this.disableDelete) {
          botones.push({
            title: 'Eliminar',
            icon: 'mdi-trash-can-outline',
            click: (item) => this.deleteItem(item),
            visible: this.visibleDelete,
            disabled: this.disabledDelete,
          })
        }
      }
      return [...botones, ...this.botonesEditar.filter((b) => this.posicionBoton(b) === 'derecha')]
    },
    dataTablePropsComputed() {
      const defaults = {
        itemsPerPage: 15,
        disableSort: this.$vuetify.breakpoint.xsOnly,
        loading: this.loadingDialog,
      }
      return Object.assign(defaults, this.dataTableProps)
    },
    zMantenedorBotones() {
      return {
        create: this.botonesDialogoCrear,
        edit: this.botonesDialogoEditar,
        view: this.botonesDialogoVer,
      }[this.accion]
    },
    zMantenedorBotonPrincipalDisabled() {
      return (
        {
          create: this.botonPrincipalCrearDisabled,
          edit: this.botonPrincipalEditarDisabled,
        }[this.accion] || false
      )
    },
    zMantenedorBotonesPrincipales() {
      const botones = {
        create: this.botonesPrincipalesCrear,
        edit: this.botonesPrincipalesEditar,
        view: this.botonesPrincipalesVer,
      }[this.accion]

      botones.forEach((boton, i) => {
        botones[i] = {
          ...boton,
          click: (item) => {
            boton.click({
              item: this.itemActivo,
              form: this.form,
              accion: this.accion,
              loading: this.loading || this.loadingDialog,
              submit: this.submit,
              cerrar: this.ocultarDialog,
              formDom: this.$refs.zMantenedorForm,
              formValid: this.formValid,
              actualizarForm: this.actualizarItemEditable,
              actualizarErrores: this.actualizarErrores,
            })
          },
        }
      })
      console.log(botones)

      return botones
    },
    zMantenedorCampoSubtitulo() {
      return {
        create: this.campoSubtituloDialogCrear,
        edit: this.campoSubtituloDialog,
        view: this.campoSubtituloDialog,
      }[this.accion]
    },
    zMantenedorCampoTitulo() {
      return {
        create: this.campoTituloDialogCrear,
        edit: this.campoTituloDialog,
        view: this.campoTituloDialog,
      }[this.accion]
    },
  },
  methods: {
    posicionBoton(button) {
      return !button || !button.posicion ? 'izquierda' : button.posicion
    },
    beforeSearch(query) {
      if (this.onBeforeSearch) {
        return this.onBeforeSearch(query)
      }
      return true
    },
    afterSearch(response) {
      if (this.onAfterSearch) {
        return this.onAfterSearch(response)
      }
      return null
    },
    /**
     * Oculta el diálogo
     */
    ocultarDialog() {
      this.dialogVisible = false
    },
    showDialog(accion, item) {
      this.accion = accion
      this.itemActivo = item
      // this.loading = false
      this.dialogVisible = true
    },
    submit() {
      this.accion === 'create' ? this.callPost() : this.callPatch()
    },
    actualizarItemEditable(item) {
      this.errors = {}
      this.form = JSON.parse(JSON.stringify(item || {}))
      this.$emit('item-cambiado', item)
    },
    viewItem(item) {
      this.loadingDialog = true
      this.onBeforeDialogView(item)
        .then((item) => {
          this.showDialog('view', item)
        })
        .finally(() => {
          this.loadingDialog = false
        })
    },
    createItem(item = {}) {
      this.loadingDialog = true
      this.onBeforeDialogCreate(item)
        .then((item) => {
          this.actualizarItemEditable(item)
          this.showDialog('create', item)
        })
        .finally(() => {
          this.loadingDialog = false
        })
    },
    editItem(item) {
      this.loadingDialog = true
      this.onBeforeDialogEdit(item)
        .then((item) => {
          this.actualizarItemEditable(item)
          this.showDialog('edit', item)
        })
        .finally(() => {
          this.loadingDialog = false
        })
    },
    actualizarErrores(error) {
      return (this.errors = this.parseErrors(error))
    },
    callPost() {
      console.log(this.$refs.zMantenedorForm.validate())
      if (!this.$refs.zMantenedorForm.validate()) {
        return
      }
      this.loading = true
      const form = { ...this.form }
      if (this.onBeforeSave(form, 'store', this.form) === false) {
        this.loading = false
        return
      }
      CrudService.post(this.url, form)
        .then((response) => {
          this.loading = false
          this.dialogVisible = false
          const newItem = response.data.data || response.data || form
          this.onAfterSave(newItem, 'stored')
          this.items.unshift(newItem)
          /** Registro creado */
          this.$emit('created', newItem)
        })
        .catch((error) => {
          this.loading = false
          this.formValid = true
          this.$refs.zMantenedorForm.resetValidation()
          this.errors = this.parseErrors(error)
          /** Error al intentar crear el registro */
          this.$emit('created:error', this.errors)
        })
    },
    callPatch() {
      console.log(this.$refs.zMantenedorForm.validate())
      if (!this.$refs.zMantenedorForm.validate()) {
        return
      }
      this.loading = true
      const form = { ...this.form }
      if (this.onBeforeSave(form, 'update', this.form) === false) {
        this.loading = false
        return
      }
      CrudService.patch(this.url, form)
        .then((response) => {
          this.loading = false
          this.dialogVisible = false
          const newItem = response.data.data || response.data || form
          this.onAfterSave(newItem, 'updated')
          this.$set(
            this.items,
            this.items.findIndex((item) => item.id === form.id),
            newItem
          )
          this.items = [...this.items]
          /** Registro guardado */
          this.$emit('updated', newItem)
        })
        .catch((error) => {
          this.loading = false
          this.formValid = true
          this.$refs.zMantenedorForm.resetValidation()
          this.errors = this.parseErrors(error)
          /** Error al intentar guardar el registro */
          this.$emit('update:error', this.errors)
        })
    },
    parseErrors(error) {
      let errores = getError(error)
      if (typeof errores !== 'object') {
        errores = {
          _message: errores,
        }
      }
      return errores
    },
    deleteItem(item) {
      const nombre = item.nombre || item.name || 'el registro'
      if (confirm(`¿Deseas eliminar ${nombre}?`)) {
        this.itemActivo = item
        this.callDelete()
      }
    },
    callDelete() {
      this.loading = true
      if (!this.onBeforeDelete({ ...this.itemActivo })) {
        this.loading = false
        return
      }
      CrudService.delete(this.url, this.itemActivo)
        .then((response) => {
          this.loading = false
          this.dialogVisible = false
          const item = response.data.data ?? response.data
          this.items = this.items.filter((item) => item.id !== this.itemActivo.id)
          /** Registro eliminado */
          this.onAfterDelete(item)
          this.$emit('deleted', item)
        })
        .catch((error) => {
          this.loading = false
          this.errors = this.parseErrors(error)
          /** Error al intentar eliminar el registro */
          this.$emit('deleted:error', this.errors)
        })
    },
    campoALabel(campo) {
      const label = campo.replaceAll('_', ' ')

      return label[0].toUpperCase() + label.slice(1)
    },
    formatValue(value, campo) {
      if (this.$dayjs) {
        if (value.toString().match(/\d{4}-\d\d-\d\dT00:00:00\.\d{3,6}Z/)) {
          return this.$dayjs(value).format('L')
        }
        if (value.toString().match(/\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d\.\d{3,6}Z/)) {
          return this.$dayjs(value).format('DD-MM-YYYY HH:mm:ss')
        }
      }
      return value
    },
  },
}
</script>
