<template>
  <v-menu v-if="$auth.personificando()" offset-y>
    <template #activator="{ on, attrs }">
      <v-chip
        class="text-caption"
        color="error"
        v-bind="attrs"
        close
        @click:close="loginAs($auth.userOriginal())"
        v-on="on"
      >
        <v-icon left>
          {{ svg.mdiAccountCircle }}
        </v-icon>
        <span class="hidden-xs-only">
          {{ $auth.institucion().nombre }}
        </span>
      </v-chip>
    </template>

    <v-list color="primary darken-1" dark dense nav>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title> Usuario: </v-list-item-title>
          <v-list-item-subtitle>
            {{ $auth.user().name }} {{ $auth.user().apellido }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title> Institución: </v-list-item-title>
          <v-list-item-subtitle>{{ $auth.institucion().nombre }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title> Rol: </v-list-item-title>
          <v-list-item-subtitle>
            {{
              $auth
                .user()
                .roles.map((r) => r.name)
                .join(', ')
            }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import { mdiAccountCircle } from '@mdi/js'
import { getError } from '@/utils/errors'

export default {
  components: {},
  props: {},
  data: () => ({
    svg: {
      mdiAccountCircle,
    },
  }),
  computed: {
    ...mapGetters('app', ['payload']),
  },
  methods: {
    loginAs(user) {
      this.$store
        .dispatch('auth/login', { id: user.id })
        .then(() => {
          if (this.$route.path !== '/dashboard') {
            this.$router.push('/dashboard')
          }
        })
        .catch((error) => {
          console.log(getError(error))
          this.logout()
        })
    },
  },
}
</script>
