var render = function render(){var _vm=this,_c=_vm._self._c;return _c('z-dialog',{attrs:{"campo-titulo":() => _vm.titulo,"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"value":{},"width":"700"},on:{"cerrar":function($event){return _vm.$emit('cerrar')}}},[_c('historial-edicion',{attrs:{"query":{
      filter: {
        model_id: _vm.id,
        microservicio: _vm.microservicio,
        clase: _vm.clase,
        tipo: 'model',
        tramite_id: _vm.tramiteId,
      },
      sort: {
        0: { field: 'fecha', direction: 'desc' },
      },
    },"dense":"","table-dense":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }