export default [
  {
    id: 5,
    nombre: 'Error en los datos',
  },
  {
    id: 3,
    nombre: 'No encuentro mi cuenta',
  },
  {
    id: 2,
    nombre: 'No he recibido mi comprobante de pago',
  },
  {
    id: 1,
    nombre: 'Problemas con mi pago',
  },
  {
    id: 4,
    nombre: 'Quiero saber el estado de mi solicitud',
  },
  {
    id: 6,
    nombre: 'Otros',
  },
]
