<template>
  <div>
    <z-form-search
      v-model="alertas"
      :headers="headers"
      :query="query"
      url="/logs/alertas/lista"
      search-method="get"
      search-inicial
      :on-after-search="updateAlertasSinLeer"
    >
      <template #buscador="{ loading, search }">
        <v-card class="d-flex justify-space-between" flat>
          <v-spacer />
          <z-btn :loading="loading" color="primary" outlined @click="search"> Actualizar </z-btn>
        </v-card>
      </template>
      <template #item.mensaje="{ value, item }">
        <div
          :class="item.fecha_leido ? 'font-weight-light' : 'font-weight-bold'"
          class="text--secondary text-body-2"
        >
          {{ value }}
        </div>
      </template>
      <template #item.id="{ item }">
        <div class="text-no-wrap">
          <z-btn
            v-if="!item.fecha_leido"
            color="primary"
            icon="mdi-email-check-outline"
            title="Marcar como leído"
            @click="marcarLeido(item)"
          />
        </div>
      </template>
    </z-form-search>
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZFormSearch from '@/components/ZFormSearch'
import { mapGetters } from 'vuex'
import CrudService from '@/admin/services/CrudService'
import { getError } from '@/utils/errors'

export default {
  components: { ZFormSearch, ZBtn },
  data() {
    return {
      alertas: [],
      headers: [
        {
          text: 'Fecha',
          value: 'fecha',
          cellClass: 'text-no-wrap',
          formatter: (v) => this.$formato.fechaFromNow(v, 23, 'hour', 'LLL'),
        },
        {
          text: 'Mensaje',
          value: 'mensaje',
        },
        {
          text: '',
          value: 'id',
        },
      ],
      query: {
        filter: {
          nivel: null,
        },
        sort: {
          0: { field: 'fecha', direction: 'desc' },
        },
      },
    }
  },
  computed: {
    ...mapGetters('app', ['payload']),
  },
  methods: {
    marcarLeido(alerta) {
      CrudService.post(`logs/alertas/${alerta.id}/marcar-leido`)
        .then((response) => {
          const data = response.data.data || response.data
          alerta.fecha_leido = data.fecha_leido
          this.updateAlertasSinLeer({ data: this.alertas })
        })
        .catch((error) => {
          const errors = getError(error)
          console.log(errors)
          this.$emit('notificar:error', 'No se pudo marcar la alerta como leída.')
        })
    },
    updateAlertasSinLeer(response) {
      const data = response.data.data ?? response.data
      const sinLeer = data.filter((a) => a.fecha_leido === null).length
      this.$store.dispatch('app/mergePayload', { alertas_sin_leer: sinLeer })
    },
  },
}
</script>
