<template>
  <div>
    <v-simple-table>
      <tbody>
        <tr>
          <th style="width: 160px">Institución</th>
          <td>{{ item.institucion_nombre }}</td>
        </tr>
        <tr>
          <th>Departamento o unidad</th>
          <td>{{ item.nombre }}</td>
        </tr>
        <tr>
          <th>Teléfono</th>
          <td>{{ item.telefono }}</td>
        </tr>
        <tr>
          <th>Dirección</th>
          <td>{{ item.direccion }}</td>
        </tr>
        <tr>
          <th>Fecha disponible desde</th>
          <td>
            {{
              item.fecha_desde === null ? 'Sin restricción' : $formato.fechaHora(item.fecha_desde)
            }}
          </td>
        </tr>
        <tr>
          <th>Fecha disponible hasta</th>
          <td>
            {{
              item.fecha_hasta === null ? 'Sin restricción' : $formato.fechaHora(item.fecha_hasta)
            }}
          </td>
        </tr>
        <tr v-if="item.notificacion_email">
          <th>Notificación EMAIL</th>
          <td>{{ item.notificacion_email }}</td>
        </tr>
        <tr v-if="item.notificacion_rest_url">
          <th>Notificación REST</th>
          <td>{{ item.notificacion_rest_url }}</td>
        </tr>
        <tr v-if="item.notificacion_soap">
          <th>Notificación SOAP</th>
          <td>{{ item.notificacion_soap }}</td>
        </tr>
        <tr>
          <td :colspan="2">
            <div class="text-subtitle-1 my-4">Pendientes</div>

            <v-card class="my-4">
              <v-simple-table dense>
                <tbody>
                  <tr v-for="pendiente in item.pendientes" :key="pendiente">
                    <td>{{ pendiente.mensaje }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
}
</script>
