<template>
  <v-text-field v-model="local" v-bind="$attrs" type="datetime-local" />
</template>

<script>
export default {
  props: {
    value: String,
    /**
     * Para fechas vacías, en vez de string vacío retornará `null`
     */
    returnNull: {
      type: Boolean,
      default: false,
    },

    /**
     * Permite ingresar segundos
     */
    segundos: {
      type: Boolean,
      default: false,
    },
  },
  data: (vm) => ({}),

  computed: {
    local: {
      get: function () {
        if (!this.value || !this.value.toString()) {
          return ''
        }
        return this.$dayjs(this.value.toString()).format(
          'YYYY-MM-DDTHH:mm' + (this.segundos ? ':ss' : '')
        )
        // console.log('get: %s ::: date: %s', this.value, date)
      },
      set: function (value) {
        if (!value || !value.toString()) {
          return this.$emit('input', this.returnNull ? null : '')
        }
        const date = this.$dayjs(value).toISOString()
        // console.log('set: %s', date)
        this.$emit('input', date)
      },
    },
  },

  methods: {},
}
</script>
