import * as API from '@/admin/services/API'

export default {
  async login(payload) {
    // Autenticación con ClaveÚnica
    if (payload.provider) {
      console.log('login con: ' + payload.provider)
      return API.apiClient.post('/usuarios/auth/login', {
        code: payload.code,
        state: payload.state,
        provider: payload.provider,
        payload: true,
        admin: true,
      })
    }
    // Autenticación indicando id del User
    if (payload.id) {
      return API.apiClient.post('/usuarios/auth/login-as', {
        id: payload.id,
        userOriginal: payload.userOriginal ?? false,
        payload: true,
        admin: true,
      })
    }
    // Autenticación con login y password
    //
    // Agrega parámetro payoload=true para que el backend incluya
    // los datos adicionales requeridos por el frontend.
    // Ej: estados, instituciones, etc.
    payload.payload = true
    return API.apiClient.post('/usuarios/auth/login', payload)
  },
  async refresh() {
    return API.apiClient.post('/usuarios/auth/refresh')
  },
  async logout() {
    return API.apiClient.post('/usuarios/auth/logout')
  },
  async getAuthUser() {
    return API.apiClient.get('/usuarios/auth/user')
  },
  /*
  async forgotPassword (payload) {
    await API.apiClient.get('/web/usuarios/sanctum/csrf-cookie')
    return API.apiClient.post('/forgot-password', payload)
  },
  async resetPassword (payload) {
    await API.apiClient.get('/web/usuarios/sanctum/csrf-cookie')
    return API.apiClient.post('/reset-password', payload)
  },
  async updatePassword (payload) {
    return API.apiClient.put('/user/password', payload)
  },
  async registerUser (payload) {
    await API.apiClient.get('/web/usuarios/sanctum/csrf-cookie')
    return API.apiClient.post('/register', payload)
  },
  async sendVerification (payload) {
    return API.apiClient.post('/email/verification-notification', payload)
  },
  async updateUser (payload) {
    return API.apiClient.put('/user/profile-information', payload)
  },
  */
}
