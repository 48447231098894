<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="250px"
    min-width="250px"
    offset-y
    transition="scale-transition"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-bind="attrs"
        :clearable="clearable"
        :dense="dense"
        :error-messages="errorMessages"
        :hint="hint"
        :label="label"
        :outlined="outlined"
        :persistent-hint="persistentHint"
        :rules="rules"
        :solo="solo"
        :value="format(local)"
        class="z-input-mes"
        readonly
        v-on="on"
        @click:clear="local = null"
      />
    </template>
    <v-date-picker
      v-model="local"
      width="250"
      type="month"
      :range="range"
      :max="max"
      :min="min"
      no-title
      @input="ocultarMenu()"
    />
  </v-menu>
</template>

<script>
export default {
  props: {
    clearable: Boolean,
    dense: Boolean,
    errorMessages: [String, Array],
    hint: String,
    label: {
      type: String,
      default: 'Mes',
    },
    outlined: Boolean,
    persistentHint: Boolean,
    range: Boolean,
    rules: Array,
    solo: Boolean,
    value: [String, Array],
    max: {
      type: [Number, String],
      default: undefined,
    },
    min: {
      type: [Number, String],
      default: undefined,
    },
  },
  data: (vm) => ({
    date: new Date().toLocaleDateString('en-CA'),
    dates: [],
    menu: false,
  }),

  computed: {
    local: {
      get: function () {
        console.log(this.value)
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },
  },

  methods: {
    format(value) {
      if (!value) {
        return value
      }
      if (typeof value === 'object') {
        const formateado = value.map((value) => this.formatDate(value))
        if (formateado.length === 2 && formateado[0] === formateado[1]) {
          return formateado[0]
        } else {
          return formateado.join(this.range ? ' — ' : ', ')
        }
      }
      return this.formatDate(value)
    },
    formatDate(date) {
      return date ? date.split('-').reverse().join('-') : ''
    },
    ocultarMenu() {
      this.$nextTick(() => {
        this.menu = !this.value || (this.range && this.value.length < 2)
      })
    },
  },
}
</script>

<style>
.z-input-mes input {
  text-align: center;
}
</style>
