export default function auth({ to, next, store }) {
  const loginQuery = { path: '/', query: { redirect: to.fullPath } }

  // En caso de no estar autenticado, intento loguear el usuario con los datos de localStorage
  if (!store.getters['auth/authUser']) {
    store.dispatch('auth/setAuthUserFromLocalStorage')
  }

  // Valido si está autenticado
  if (!store.getters['auth/authUser']) {
    // notificar({ message: 'Acceso no autorizado.', severity: 'error' })
    next(loginQuery)
    return
  }

  // Valido permissions
  if (to.meta.permissions && !store.getters['auth/can'](to.meta.permissions)) {
    // notificar({ message: 'Acceso no autorizado.', severity: 'error' })
    next(loginQuery)
    return
  }

  // Valido tipo de institución
  if (
    to.meta.tipoInstitucion &&
    store.getters['auth/authUser']?.institucion?.tipo !== to.meta.tipoInstitucion
  ) {
    // notificar({ message: 'Acceso no autorizado.', severity: 'error' })
    next(loginQuery)
    return
  }

  // Redirecciona desde Dashboard a página principal según los permisos del User
  if (to.name === 'dashboard') {
    if (store.getters['auth/can'](['operador'])) {
      next({ path: '/admin/habilitaciones' })
      return
    }

    if (store.getters['auth/can'](['confirmar-infraccion-jpl'])) {
      next({ path: '/infracciones/seguimiento' })
      return
    }
  }

  next()
}
