<template>
  <v-app>
    <!--

    Barra principal con eventual mensaje de alerta global

    -->
    <the-system-bar />

    <!--

    Menú de navegación

    -->

    <the-menu-principal v-if="loggedIn" />

    <!--

      Barra de menú superior

    -->

    <the-app-bar v-if="loggedIn" />

    <!--

    Contenido

    -->

    <v-main>
      <the-barra-superior v-if="!loggedIn" class="hidden-xs-only" />
      <v-container>
        <router-view
          :key="$route.fullPath"
          @notificar="showSnackbar"
          @notificar:error="showSnackbarError"
        />
      </v-container>
    </v-main>

    <!--

    Mensaje al usuario

    -->

    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbar.severity === 'error' ? 'red' : 'success'"
      :timeout="snackbar.timeout || 6000"
    >
      <div v-html="snackbar.message" />
      <template #action>
        <v-btn icon @click="hideSnackbar">
          <v-icon>{{ svg.mdiClose }}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!--

    Pie de página

    -->

    <the-footer />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mdiClose } from '@mdi/js'
import TheAppBar from '@/admin/components/App/TheAppBar'
import TheFooter from '@/admin/components/App/TheFooter'
import TheMenuPrincipal from '@/admin/components/App/TheMenuPrincipal'
import TheBarraSuperior from '@/admin/components/App/TheBarraSuperior'
import TheSystemBar from '@/admin/components/App/TheSystemBar'

export default {
  components: { TheSystemBar, TheBarraSuperior, TheFooter, TheAppBar, TheMenuPrincipal },
  data: () => ({
    svg: {
      mdiClose,
    },
  }),
  computed: {
    ...mapGetters('auth', ['loggedIn']),
    ...mapGetters('app', ['snackbar', 'payload']),
    snackbarVisible: {
      get: function () {
        return this.snackbar.visible
      },
      set: function () {
        this.hideSnackbar()
      },
    },
  },
  beforeMount() {},
  methods: {
    ...mapActions('app', ['setMenuPrincipalVisible', 'showSnackbar', 'hideSnackbar']),
    showSnackbarError(message) {
      this.showSnackbar({ message, severity: 'error' })
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/components/VStepper/_variables.scss';

#app.theme--dark {
}

#app.theme--light {
  background-color: $fondo-light !important;
}

/** Quita indentación de menú del usuario */
.v-navigation-drawer__prepend .v-list-group__items > .v-list-item {
  padding-left: 8px !important;
}

/** Previene titulos y flecha de ordenamiento desalineados en v-data-table */
.v-data-table-header th {
  white-space: nowrap;
}
</style>
