import store from '../store'
import { getError } from '@/utils/errors'

export const notificarErrorResponse = (
  errorResponse,
  defaultMessage = 'Ocurrió un error de comunicación.'
) => {
  const error = getError(errorResponse)
  const message = typeof error === 'string' ? error : error._message || defaultMessage
  notificar({ message: message, severity: 'error' })
  return error
}

export const notificar = (snackbar) => {
  store.dispatch('app/showSnackbar', snackbar)
}
