<template>
  <v-select
    v-model="local"
    v-bind="$attrs"
    :items="items"
    :label="label"
    :dense="dense"
    outlined
    v-on="$listeners"
  />
</template>

<script>
/**
 * Campo para selección de código de firma para la institución.
 */
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    institucionId: {
      type: Number,
      required: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: 'Firmante',
    },
  },
  computed: {
    local: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },
    items() {
      const items = this.itemsOriginal()
      if (!items.find((f) => f.value === this.value)) {
        items.push({ value: this.value, text: this.value })
      }
      return items
    },
  },
  methods: {
    itemsOriginal() {
      if (!this.institucionId) {
        return []
      }
      if (this.institucionId === this.$auth.institucion().id) {
        return this.payloadItemsFirmantes
      }
      return this.$payload
        .institucion(this.institucionId, {}, 'firmantes')
        .filter((f) => f.activo)
        .map((f) => ({ value: `${f.proveedor}:${f.codigo}`, text: f.nombre + ` (${f.proveedor}:${f.codigo})`  }))
    },
  },
}
</script>
