<template>
  <div>
    <div v-if="items === null" class="text--secondary text-caption">
      <v-progress-circular indeterminate color="primary" size="16" width="2" class="mt-n1" />
      Cargando...
    </div>
    <div v-else-if="error || items.length === 0" class="text--secondary text-caption">
      (No hay datos para mostrar)
    </div>
    <v-timeline v-else :dense="dense">
      <v-timeline-item color="green" right>
        <div slot="opposite">Valores actuales</div>
        <v-card class="elevation-2">
          <v-card-title v-if="dense"> Valores actuales </v-card-title>
          <v-card-text>
            <tabla-campos-editados
              v-if="items[0] && items[0].payload.attributes"
              :attributes="{ ...items[0].payload.attributes }"
              :dense="tableDense"
              mostrar-todo
            />
          </v-card-text>
        </v-card>
      </v-timeline-item>
      <v-timeline-item v-for="item in items" :key="item.id" right>
        <div slot="opposite">
          {{ $dayjs(item.updated_at).format('LLLL') }}
          <div class="text--secondary text-caption">
            {{ $dayjs(item.updated_at).fromNow() }}
          </div>
        </div>
        <v-card>
          <v-card-title v-if="dense">
            {{ $dayjs(item.updated_at).format('LLLL') }}
          </v-card-title>
          <v-card-subtitle v-if="dense" class="text--secondary text-caption">
            {{ $dayjs(item.updated_at).fromNow() }}
            <span v-if="item.user_nombre"> - {{ item.user_nombre }} </span>
          </v-card-subtitle>
          <v-card-text>
            <tabla-campos-editados
              v-if="item.payload.attributes"
              :attributes="{ ...item.payload.attributes }"
              :dense="tableDense"
              :mostrar-todo="!item.payload.old"
              :old="{ ...item.payload.old, updated_at: undefined }"
              :model="item.clase"
            />
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import * as API from '@/admin/services/API'
import TablaCamposEditados from '@/admin/components/TablaCamposEditados'
import { getError } from '@/utils/errors'

/**
 * Componente que muestra un timeline con el
 * historial de modificaciones del registro.
 *
 * Normalmente este componente es usado desde el componente `dialog-historial`
 * para así abrirlo en una ventana de diálogo.
 *
 */
export default {
  components: { TablaCamposEditados },
  props: {
    /**
     * URL para el request
     */
    url: {
      type: String,
      default: '/logs/logs/search',
    },

    /**
     * Objeto con parámetros para el request
     */
    query: {
      type: Object,
      default: () => {},
    },

    /**
     * Maximiza el área de despliege de información del timeline.
     * Útil para ventanas pequeñas.
     */
    dense: {
      type: Boolean,
      deafult: false,
    },
    /**
     * Maximiza el área de despliege de información de la tabla con valores.
     * Útil para ventanas pequeñas.
     */
    tableDense: {
      type: Boolean,
      deafult: false,
    },
  },
  data() {
    return {
      items: null,
      error: null,
    }
  },
  created() {
    API.apiClient
      .post(this.url, this.query)
      .then((response) => {
        this.items = response.data.data || response.data
      })
      .catch((error) => {
        this.items = null
        this.error = getError(error)
      })
  },
}
</script>
