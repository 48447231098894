export default function guest({ next, store }) {
  if (!store.getters['auth/loggedIn']) {
    store
      .dispatch('auth/setAuthUserFromLocalStorage')
      .then(() => next({ name: 'dashboard' }))
      .catch(() => next())
  } else {
    next({ name: 'dashboard' })
  }
}
