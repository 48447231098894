<template>
  <v-text-field
    v-model="valueLocal"
    v-bind="$props"
    :rules="rules.concat([(v) => !v || parseRut(v).valido || 'RUT inválido'])"
    maxlength="13"
    style="max-width: 200px; min-width: 100px"
    @change="change"
    @keypress="allowKey($event)"
  />
</template>

<script>
/**
 * Campo para ingreso de RUT.
 * El valor retornado será sólo la parte numérica del RUT (sin DV)
 *
 */
export default {
  props: {
    clearable: Boolean,
    dense: Boolean,
    disabled: Boolean,
    errorMessages: [String, Array],
    hint: String,
    label: {
      type: String,
      default: 'RUT',
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    persistentHint: Boolean,
    readonly: Boolean,
    required: Boolean,
    rules: {
      type: Array,
      default: () => [],
    },
    solo: Boolean,
    value: {
      type: [String, Number],
    },
  },
  data() {
    return {
      lastValueLocal: '',
      lastValue: '',
    }
  },
  computed: {
    valueLocal: {
      get: function () {
        if (this.value === this.lastValue) {
          return this.lastValueLocal
        }
        return this.parseRut(this.value).completoCalculado
      },
      set: function (value) {
        this.lastValueLocal = value
        this.lastValue = this.parseRut(value).rut
        this.$emit('input', this.lastValue)
      },
    },
  },
  beforeMount() {
    const parsed = this.parseRut(this.value)
    if (parsed.completo !== this.value && this.value !== null) {
      this.valueLocal = this.value.toString().trim() + '-' + this.dv(this.value)
    }
  },
  methods: {
    allowKey: function (event) {
      const key = event.keyCode
      this.valueLocal ??= ''

      // Carácteres de control
      if (key < 32 || typeof this.valueLocal === 'undefined') {
        return true
      }
      // Sólo un caracter después de "-"
      if (
        this.valueLocal.length > 2 &&
        this.valueLocal.charAt(this.valueLocal.length - 2) === '-'
      ) {
        // event.preventDefault()
      }
      // Números
      if (key >= 48 && key <= 57) {
        return true
      }
      // "-" y "." permitidos antes de "-"
      if ((key === 45 || key === 46) && !this.valueLocal.includes('-')) {
        return true
      }
      // "k" y "K"
      if ((key === 75 || key === 107) && this.valueLocal.includes('-')) {
        return true
      }
      event.preventDefault()
    },
    parseRut(value) {
      let tieneSeparador = false
      let rut = ''
      let dv = ''
      let info = {
        rut: '',
        dv: '',
        dvCalculado: '',
        tieneSeparador: false,
        valido: true,
        completo: '',
        completoCalculado: '',
        formateado: '',
      }
      if (value === null) {
        return info
      }
      const partes = value
        .toString()
        .toUpperCase()
        .replace(/[^0-9K\\-]/g, '')
        .split('-', 2)
      if (partes.length > 1) {
        rut = partes[0]
        dv = partes[1].charAt(0)
        tieneSeparador = true
      } else if (partes[0].length === 1) {
        rut = partes[0]
      } else {
        rut = partes[0]
        // rut = partes[0].substr(0, partes[0].length - 1)
        // dv = partes[0].charAt(partes[0].length - 1)
      }
      const separador = dv === '' ? '' : '-'
      const dvCalculado = this.dv(rut)

      info = {
        ...info,
        // Sólo parte numérica del RUT. Ej: 12345678
        rut: rut,
        // El dígito verificador del RUT proporcionado
        dv: dv,
        // El dígito verificador calculado de la parte numérica del RUT
        dvCalculado: dvCalculado,
        tieneSeparador: tieneSeparador,
        // Indica si el RUT-DV proporcionado tiene un DV válido
        valido: dvCalculado === dv,
        // Ej: 12345678-9
        completo: rut + separador + dv,
        // Genera el RUT con el DV correcto. Ej: 12345678-9
        completoCalculado: rut + '-' + dvCalculado,
        // 12.345.678-9
        formateado: Intl.NumberFormat('de').format(parseInt(rut)) + separador + dv,
      }
      // console.log(info)
      return info
    },
    dv(rut) {
      rut = parseInt(('' + rut).replaceAll('.', ''), 10)
      let m = 0
      let s = 1
      for (; rut; rut = Math.floor(rut / 10)) {
        s = (s + (rut % 10) * (9 - (m++ % 6))) % 11
      }
      return s ? (s - 1).toString() : 'K'
    },
    change() {
      this.valueLocal = this.parseRut(this.valueLocal).completo
    },
  },
}
</script>
