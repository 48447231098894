<template>
  <div>
    <z-mantenedor
      v-model="itemsFiltrados"
      :botones-listar="[
        {
          title: 'Institución',
          click: (v) => $router.push(`/admin/instituciones?institucion_id=${v.institucion_id}`),
          posicion: 'menu',
        },
        {
          title: 'Usuarios',
          click: (v) => $router.push(`/admin/users?institucion_id=${v.institucion_id}`),
          posicion: 'menu',
        },
        {
          title: 'Firmantes',
          click: (v) => $router.push(`/admin/firmantes?institucion_id=${v.institucion_id}`),
          posicion: 'menu',
        },
        { title: 'Ver historial', icon: 'mdi-history', click: mostrarHistorial, posicion: 'menu' },
      ]"
      :botones-ver="[{ title: 'Ver historial', icon: 'mdi-history', click: mostrarHistorial }]"
      :headers="headers"
      :query="query"
      :url="`tramites/${tramite}/habilitaciones?${extra}`"
      :search-url="`tramites/${tramite}/habilitaciones/search?${extra}`"
      search-method="post"
      timeout="60"
      :on-after-search="afterSearch"
      :on-before-save="beforeSave"
      :on-after-save="afterSave"
      :campo-titulo-dialog="(i) => $payload.institucion(i.institucion_id)"
      :campo-titulo-dialog-crear="() => 'Agregar institución'"
      :search-inicial="!!tramite"
      :data-table-props="{
        // disableSort: true,
        options: { sortBy: ['institucion_nombre'] },
      }"
      disable-delete
      texto-filtrar="Filtrar"
      texto-hits="%s instituciones."
      texto-no-hit="No se encontraron instituciones."
      texto-un-hit="Una institución."
      @created="$emit('notificar', 'Institución agregada.')"
      @deleted="$emit('notificar', 'Institución eliminada.')"
      @updated="$emit('notificar', 'Institución actualizada.')"
      @created:error="$emit('notificar:error', 'Error al intentar crear.')"
      @update:error="$emit('notificar:error', 'Error al intentar guardar.')"
      @deleted:error="$emit('notificar:error', 'Error al intentar eliminar.')"
    >
      <template #buscador="{ loading, search, create }">
        <v-form @submit.prevent="search">
          <v-row dense>
            <v-col cols="12" sm="8" md="7">
              <input-tramite v-model="tramite" :filter="(t) => !['multas'].includes(t.slug)" />
            </v-col>
            <v-spacer />
            <v-col class="d-flex">
              <v-spacer />
              <z-btn :disabled="!tramite" :loading="loading" color="primary" outlined type="submit">
                Actualizar
              </z-btn>
              <z-btn
                :disabled="!tramite"
                class="ml-1"
                color="primary"
                outlined
                @click="create({ firmador: '' })"
              >
                Agregar
              </z-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template #cabeceraInterior>
        <v-simple-table dense style="max-width: 240px">
          <tbody>
            <tr>
              <td>
                <v-switch
                  v-model="mostrarHabilitadas"
                  :readonly="!mostrarDeshabilitadas"
                  label="Habilitadas"
                  dense
                  hide-details
                  class="mt-n2"
                />
              </td>
              <td class="text-right">
                {{ institucionesActivas }}
              </td>
            </tr>
            <tr>
              <td>
                <v-switch
                  v-model="mostrarDeshabilitadas"
                  :readonly="!mostrarHabilitadas"
                  label="Deshabilitadas"
                  dense
                  hide-details
                  class="mt-n2"
                />
              </td>
              <td class="text-right">
                {{ institucionesInactivas }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </template>

      <template #item.institucion_nombre="{ item }">
        {{ item.institucion_nombre }}
        <div class="text--secondary text-caption">
          {{ $formato.rut(item.institucion_id) }}
        </div>
      </template>

      <template #item.cuentas_count="{ item }">
        <v-chip
          small
          :to="`/cuentas?institucion_id=${item.institucion_id}&tramite=${tramite}&buscar=true`"
        >
          {{ $formato.numero(item.cuentas_count) }}
        </v-chip>
      </template>

      <template #item.nominas_count="{ item }">
        <v-chip
          small
          :to="`/nominas?tramite=${tramite}&institucion_id=${item.institucion_id}&estado=importada&buscar=true`"
        >
          {{ $formato.numero(item.nominas_count) }}
        </v-chip>
      </template>

      <template #item.activa="{ item }">
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-chip
              :color="
                item.activa
                  ? 'success'
                  : item._severidadPendientes === 'error'
                  ? 'error'
                  : 'success'
              "
              dark
              v-bind="attrs"
              small
              :outlined="item.activa !== true"
              v-on="on"
            >
              {{ item.activa ? 'Habilitada' : 'Deshabilitada' }}
              <v-icon v-if="item.activa && !item._vigente" right small>
                mdi-minus-circle-outline
              </v-icon>
            </v-chip>
          </template>
          <v-list dense>
            <v-list-item :disabled="!habilitable(item)" @click="activarHabilitacion(item, true)">
              <v-list-item-icon>
                <v-icon :disabled="!habilitable(item)"> mdi-thumb-up </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Habilitar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :disabled="!item.activa" @click="activarHabilitacion(item, false)">
              <v-list-item-icon>
                <v-icon :disabled="!deshabilitable(item)"> mdi-thumb-down </v-icon>
              </v-list-item-icon>
              <v-list-item-title>Deshabilitar</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-tooltip v-if="item.pendientes.length" top>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              v-bind="attrs"
              :color="colorSeveridadPendientes(item._severidadPendientes)"
              class="ml-1"
              v-on="on"
            >
              mdi-numeric-{{
                item.pendientes.length > 9 ? '9-plus' : item.pendientes.length
              }}-circle-outline
            </v-icon>
          </template>
          <span class="text-subtitle-2">Pendientes:</span>
          <div v-for="pendiente in item.pendientes" :key="pendiente.mensaje">
            <v-icon left x-small :color="colorSeveridadPendientes(pendiente.severidad)">
              mdi-checkbox-blank-circle
            </v-icon>
            {{ pendiente.mensaje }}
          </div>
        </v-tooltip>
      </template>

      <template #form="{ accion, form, errors }">
        <habilitaciones-form
          :accion="accion"
          :errors="errors"
          :value="form"
          :instituciones="institucionesConHabilitacion"
          @input="form = $event"
        />
      </template>

      <template #view="{ item }">
        <habilitaciones-view :item="item" />
      </template>
    </z-mantenedor>

    <dialog-historial
      v-if="historialVisible"
      :id="itemActivo.id"
      :titulo="itemActivo.nombre"
      :microservicio="$payload.tramite(itemActivo.tramite_id, 'microservicio', 'id')"
      clase="habilitacion"
      @cerrar="historialVisible = false"
    />
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import HabilitacionesForm from '@/admin/views/Admin/HabilitacionesForm'
import HabilitacionesView from '@/admin/views/Admin/HabilitacionesView'
import ZMantenedor from '@/components/ZMantenedor'
import InputTramite from '@/components/Input/InputTramite'
import DialogHistorial from '@/admin/components/DialogHistorial'
import { getError } from '@/utils/errors'
import CrudService from '@/admin/services/CrudService'

export default {
  components: {
    DialogHistorial,
    InputTramite,
    ZMantenedor,
    HabilitacionesForm,
    HabilitacionesView,
    ZBtn,
  },
  data() {
    return {
      tramite: this.$store.getters['app/tramite'],
      extra: 'nominas_count=true&cuentas_count=false&pendientes=true',
      items: [],
      itemActivo: {},
      historialVisible: false,
      mostrarDeshabilitadas: true,
      mostrarHabilitadas: true,
      headers: [
        {
          text: 'Institución',
          value: 'institucion_nombre',
        },
        { text: 'Cuentas', value: 'cuentas_count' },
        { text: 'Nóminas importadas', value: 'nominas_count' },
        { text: 'Estado', value: 'activa', cellClass: 'text-no-wrap', width: '155px' },
      ],
      query: {
        paginationDisabled: true,
        filter: {
          tipo: null,
        },
        sort: {
          0: { field: 'nombre', direction: 'asc' },
        },
      },
      itemsHabilitacion: [
        { item: 'h', value: true },
        { item: 'd', value: false },
        { item: 'Habilitable', value: null },
      ],
      institucionesConHabilitacion: [],
    }
  },
  computed: {
    institucionesActivas() {
      return this.items.filter((h) => h.activa).length
    },
    institucionesInactivas() {
      return this.items.filter((h) => !h.activa).length
    },
    itemsFiltrados: {
      get: function () {
        return this.items.filter(
          (h) => (this.mostrarHabilitadas && h.activa) || (this.mostrarDeshabilitadas && !h.activa)
        )
      },
      set: function (value) {
        this.items = [...value]
      },
    },
  },
  methods: {
    beforeSave(habilitacion, accion, form) {
      if (accion === 'store') {
        habilitacion.institucion_nombre = this.$payload.institucion(habilitacion.institucion_id)
        habilitacion.municipalidad_id = this.$payload.institucion(
          habilitacion.institucion_id,
          null,
          'municipalidad_id'
        )
      }
      // Quita parámetros que no se guardan
      delete habilitacion.nominas
      delete habilitacion.pendientes
    },
    afterSave(habilitacion, accion) {
      this.completarhabilitacion(habilitacion)
      if (accion === 'stored') {
        this.$set(this.items, this.items.length, habilitacion)
      }
    },
    afterSearch(response) {
      const items = response.data.data || response.data
      this.institucionesConHabilitacion = items.map((i) => i.institucion_id)
      items.forEach((habilitacion) => {
        this.completarhabilitacion(habilitacion)
      })

      return items
    },
    completarhabilitacion(habilitacion) {
      // Ya no es necesario agregar el nombre, porque debiera venir siempre llenado desde el backend, incluso con dato de faker
      // habilitacion.institucion_nombre = this.$payload.institucion(habilitacion.institucion_id)
      // habilitacion.nominas_count = habilitacion.nominas && habilitacion.nominas.filter(n => n.estado === 15).length

      habilitacion._vigente = this.habilitacionVigente(habilitacion)
      if (!habilitacion._vigente) {
        habilitacion.pendientes.push({
          mensaje:
            'La fecha de habilitación no está vigente (' +
            this.$formato.fecha(habilitacion.fecha_desde, 'L', '∞') +
            ' – ' +
            this.$formato.fecha(habilitacion.fecha_hasta, 'L', '∞') +
            ')',
          severidad: 'info',
        })
      }
      habilitacion._severidadPendientes = this.severidadPendientes(habilitacion.pendientes)

      return habilitacion
    },
    habilitacionVigente(item) {
      const desde = !item.fecha_desde || this.$dayjs().diff(item.fecha_desde, 'seconds', true) > 0
      const hasta = !item.fecha_hasta || this.$dayjs().diff(item.fecha_hasta, 'seconds', true) < 0
      return desde && hasta
    },
    activarHabilitacion(habilitacion, activa) {
      CrudService.patch(`tramites/${this.tramite}/habilitaciones?${this.extra}`, {
        id: habilitacion.id,
        activa: activa,
      })
        .then((response) => {
          const newItem = this.completarhabilitacion(response.data.data || response.data)
          this.$set(
            this.items,
            this.items.findIndex((item) => item.id === habilitacion.id),
            newItem
          )
        })
        .catch((error) => {
          error = getError(error)
          console.log(error)
        })
    },
    mostrarHistorial(item) {
      this.itemActivo = item
      this.historialVisible = true
    },
    habilitable(habilitacion) {
      return !habilitacion.activa && habilitacion._severidadPendientes !== 'error'
    },
    deshabilitable(habilitacion) {
      return habilitacion.activa
    },
    severidadPendientes(pendientes) {
      const severidades = pendientes.map((p) => p.severidad)

      for (const severidad of ['error', 'warning', 'notice', 'info']) {
        if (severidades.includes(severidad)) {
          return severidad
        }
      }
      return 'info'
    },
    colorSeveridadPendientes(pendiente) {
      if (pendiente === 'info') {
        return 'grey'
      }
      if (pendiente === 'notice') {
        return 'info'
      }
      return pendiente
    },
  },
}
</script>
